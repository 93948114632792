import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import MainHeader from "./header/MainHeader";
import MainSidebar from "./sidebar/MainSidebar";
import { survivorClient } from "../../service/backend";
import { PrivateRoutes, PublicRoutes } from "../../config/routes";
import PrivacyPolicyModal from "../PrivacyPolicyModal";
import { Box, ModalOverlay } from "@chakra-ui/react";
import DeleteAccountRequestModal from "../DeleteAccountRequestModal";
import DeleteModal from "../DeleteModal";
import RecoveryEmailModal from "../RecoveryEmailModal";

const MainLayout: React.FC = () => {
	const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] =
		useState<boolean>(false);
	const [showDeleteAccountRequestModal, setShowDeleteAccountRequestModal] =
		useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const navigate = useNavigate();
	const [showRecoveryEmailModal, setShowRecoveryEmailModal] =
		useState<boolean>(false);

	const [isSidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);

	useEffect(() => {
		if (!survivorClient.acceptedPrivacyPolicy) {
			setShowPrivacyPolicyModal(true);
		} else if (!survivorClient.demographicsComplete) {
			navigate(PrivateRoutes.DEMOGRAPHICS);
		} else if (
			!survivorClient.userData.callistoContactEmail ||
			!survivorClient.userData.phoneNumber ||
			!survivorClient.userData.securityQuestions ||
			!survivorClient.userData.securityAnswers
		) {
			setShowRecoveryEmailModal(true);
		}
	});

	const closePrivacyPolicyModal = () => {
		setShowPrivacyPolicyModal(false);
		if (!survivorClient.acceptedPrivacyPolicy) {
			setShowDeleteAccountRequestModal(true);
		} else if (!survivorClient.demographicsComplete) {
			navigate(PrivateRoutes.DEMOGRAPHICS);
		}
	};

	const closeDeleteAccountRequestModal = () => {
		setShowDeleteAccountRequestModal(false);
		setShowDeleteModal(true);
	};
	return (
		<>
			<MainHeader />
			<MainSidebar
				isSidebarCollapsed={isSidebarCollapsed}
				setSidebarCollapsed={setSidebarCollapsed}
			/>
			<Box
				width={{
					base: "100%",
					md: isSidebarCollapsed ? "calc(100% - 84px)" : "calc(100% - 300px)",
				}}
				marginLeft={{
					base: "0px",
					md: isSidebarCollapsed ? "84px" : "300px",
				}}
				height="100%"
				backgroundColor="brand.appBackground"
				padding="100px 20px 20px 20px"
				as="main"
				overflowY="auto"
				transition="all 0.5s"
			>
				<Box maxWidth="970px" margin="0 auto">
					<Outlet />
				</Box>
			</Box>

			<PrivacyPolicyModal
				onClose={closePrivacyPolicyModal}
				isOpen={showPrivacyPolicyModal}
			>
				<ModalOverlay />
			</PrivacyPolicyModal>
			<DeleteAccountRequestModal
				onClose={closeDeleteAccountRequestModal}
				isOpen={showDeleteAccountRequestModal}
			>
				<ModalOverlay />
			</DeleteAccountRequestModal>
			<DeleteModal
				isOpen={showDeleteModal}
				onClose={async () => {
					await survivorClient.logout();
					navigate(PublicRoutes.LOGIN);
				}}
				deletePath="Did not accept PP or TOU"
			>
				<ModalOverlay />
			</DeleteModal>
			<RecoveryEmailModal
				isOpen={showRecoveryEmailModal}
				onClose={async () => {
					await survivorClient.logout();
					navigate(PublicRoutes.LOGIN);
				}}
			>
				<ModalOverlay />
			</RecoveryEmailModal>
		</>
	);
};

export default MainLayout;
