import React, { forwardRef } from "react";
import { Textarea as ChakraTextarea, TextareaProps } from "@chakra-ui/react";

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
	(props: TextareaProps, ref) => (
		<ChakraTextarea
			border="1px solid"
			borderColor="brand.primary"
			borderRadius="0px"
			padding="10px 15px"
			backgroundColor="brand.white"
			fontSize="sm"
			height="50px"
			_focus={{ borderColor: "brand.primary" }}
			ref={ref}
			{...props}
		/>
	),
);

Textarea.displayName = "Textarea";

export default Textarea;
