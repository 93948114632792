import React, { SyntheticEvent, useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import AnimatedBox from "../../components/layout/AnimatedBox";
import { PrivateRoutes } from "../../config/routes";
import OrfActionButtons from "../../components/OrfActionButtons";
import { OrfContext } from "../../contexts/OrfContext";
import MainCard from "../../components/MainCard";
import { wait } from "../../service/backend";
import { IFormProps, usePersistData } from "../../service/hooks/usePersistData";
import { handleORFSubmit } from "../../components/layout/sidebar/CreateEditSidebar";
import { OrfWherePageContent } from "./OrfWherePageContent";
import { useNavigate } from "react-router-dom";

export interface IWhereFormProps {
	place: string;
	exactPlace: string;
	memory: string;
}

const OrfWhere: React.FC = () => {
	const navigate = useNavigate();
	const {
		state: { steps, mode, currentRecordId, changesMade },
		completeAction,
		setSavingScreenVisibility,
		updateORF,
	} = useContext(OrfContext);
	const { where } = steps;
	const formRef = useRef<HTMLFormElement>(null);

	const { handleSubmit, register, watch, setValue } = useForm<IWhereFormProps>({
		defaultValues: {
			place: where.place.key,
			exactPlace: (where.place.key === "Other" && where.place.value) || "",
			memory: where.memory,
		},
	});

	useEffect(() => {
		register("place");

		const keypressListener = (e: KeyboardEvent) => {
			if (
				document.activeElement &&
				Array.from(document.getElementsByTagName("textarea")).includes(
					document.activeElement as HTMLTextAreaElement,
				)
			) {
				return;
			}

			if (e.key === "Enter") {
				e.preventDefault();
			}
		};

		if (formRef.current) {
			formRef.current.addEventListener("keypress", keypressListener);
		}

		return () => {
			if (formRef.current) {
				formRef.current.removeEventListener("keypress", keypressListener);
			}
		};
	}, []);

	const generatePayload = ({ memory, place, exactPlace }: IWhereFormProps) => ({
		place: {
			key: place,
			value: exactPlace || place,
		},
		memory,
	});

	const saveForm = (formProps: IWhereFormProps) => {
		if (changesMade) {
			completeAction("where", generatePayload(formProps));
		}
	};

	const setWatchValuesRef = usePersistData(saveForm);

	useEffect(() => {
		setWatchValuesRef(watch() as IFormProps);
	});

	const onContinue = () => {
		navigate(where.nextStepRoute);
	};

	const handleSaveAndExit = async (e: SyntheticEvent) => {
		e.preventDefault();

		setSavingScreenVisibility(true);
		await wait(1000);
		const updatedWhere = generatePayload(watch());
		const updatedSteps = {
			...steps,
			where: {
				...where,
				...updatedWhere,
				completed: !!updatedWhere.memory || !!updatedWhere.place.value,
			},
		};

		await handleORFSubmit(mode, updatedSteps, updateORF, currentRecordId);
		setSavingScreenVisibility(false);
	};

	return (
		<AnimatedBox>
			<MainCard>
				<Box as="form" onSubmit={handleSaveAndExit} id="orf_form" ref={formRef}>
					<OrfWherePageContent
						register={register}
						values={watch()}
						setValue={setValue}
					/>
					<OrfActionButtons
						backRoute={PrivateRoutes.ORF_WHEN}
						handleClick={handleSubmit(onContinue)}
					/>
				</Box>
			</MainCard>
		</AnimatedBox>
	);
};

export default OrfWhere;
