import React from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

const ButtonLink: React.FC<ButtonProps> = ({
	children = "",
	...props
}: ButtonProps) => (
	<Button
		variant="link"
		color="brand.link"
		fontSize="sm"
		fontFamily="Avenir"
		fontWeight="bold"
		_hover={{ boxShadow: "none" }}
		{...props}
	>
		{children}
	</Button>
);

export default ButtonLink;
