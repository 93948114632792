import React, { useContext, useEffect, useRef } from "react";
import { IncidentLogContext } from "../../contexts/IncidentLogContext";
import { callistoLogoBase64 } from "../../../assets/Callisto_logo";
import jsPDF from "jspdf";
import LogoSvg from "../../../assets/Callisto_logo.svg";
import LogoPng from "../../../assets/Callisto_logo.png";
import { PrivateRoutes } from "../../config/routes";
import { Paragraph } from "../../components/Paragraph";
import { IncidentLogIntroPageContent } from "./IncidentLogPdfIntroPageContent";
import { POSTSTEPS } from "../IncidentLog/incidentLogQuestions";
import { IncidentLogPageContent } from "../IncidentLog/IncidentLogPageContent";

import "./styles.scss";
import { IncidentLogPdfContext } from "./IncidentLogPdfContext";
import { useNavigate } from "react-router-dom";

export const IncidentLogPdf: React.FC = () => {
	const navigate = useNavigate();
	const docRef = useRef<HTMLDivElement>(null);
	const {
		state: { steps, results, sectionUpdatedDates, updated, created },
	} = useContext(IncidentLogContext);
	const {
		state: { reason },
	} = useContext(IncidentLogPdfContext);

	const textWidth = "7in";

	useEffect(() => {
		const savePdf = async () => {
			const jspdf: jsPDF = new jsPDF({
				unit: "pt",
				format: "letter",
			});

			await jspdf.html(docRef.current as unknown as string, {
				margin: [45, 0, 45, 45], // top, right, bottom, left
				callback: (doc: jsPDF) => {
					const pageCount = doc.getNumberOfPages();
					for (let i = 1; i <= pageCount; i++) {
						doc.setPage(i);
						if (reason === "share with attorney") {
							doc.text("CONFIDENTIAL - ATTORNEY CLIENT PRIVILEGE", 175, 35);
						}
						const pageCurrent = doc.getCurrentPageInfo().pageNumber;
						if (pageCurrent === 1) {
							const img = new Image(175, 121);
							img.src = LogoSvg as string;
							try {
								doc.addImage(callistoLogoBase64, "png", 380, 50, 175, 121);
							} catch (error) {
								try {
									// When running localhost, you need to use an actual PNG file--doesn't work on heroku because of security settings
									doc.addImage(LogoPng as string, "png", 380, 50, 175, 121);
								} catch {
									// Do nothing
								}
							}
						}

						doc.setFontSize(10);
						doc.text(
							String(pageCurrent),
							doc.internal.pageSize.width / 2,
							doc.internal.pageSize.height - 15,
						);
					}

					doc.save(`incident_log-${new Date().toISOString()}.pdf`);
				},
				html2canvas: {
					scale: 0.8,
				},
				autoPaging: "text",
			});
		};

		void savePdf();
		navigate(PrivateRoutes.ILOG_SUMMARY);
	}, []);

	return (
		<div ref={docRef} className="pdf">
			<Paragraph>
				Incident Log created (UTC/GMT):{" "}
				{created ? created.toISOString() : new Date().toISOString()}
			</Paragraph>
			<Paragraph>
				Incident Log last updated (UTC/GMT):{" "}
				{updated ? updated.toISOString() : new Date().toISOString()}
			</Paragraph>
			<IncidentLogIntroPageContent textWidth={textWidth} />
			{Object.keys(steps).map((stepName) => {
				if (POSTSTEPS.includes(stepName)) {
					return <div key={stepName}></div>;
				}

				return (
					<IncidentLogPageContent
						key={stepName}
						textWidth={textWidth}
						step={steps[stepName]}
						stepName={stepName}
						results={results}
						questions={steps[stepName].questions}
						setResults={() => {}}
						pdf={true}
						updated={sectionUpdatedDates[stepName]}
					/>
				);
			})}
		</div>
	);
};
