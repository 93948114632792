import React from "react";
import Card from "./Card";

interface Props {
	children?: React.ReactNode | React.ReactNode[];
}

const MainCard: React.FC<Props> = ({ children }: Props) => (
	<Card p={["20px 20px", "20px 20px", "40px 70px"]} flexDir="column">
		{children}
	</Card>
);

export default MainCard;
