import { useState, useEffect } from "react";

const initBeforeUnload = (showExitPrompt: boolean) => {
	window.onbeforeunload = (event: BeforeUnloadEvent) => {
		if (showExitPrompt) {
			const e = event || window.event;
			e.preventDefault();
			if (e) {
				e.returnValue = "";
			}
			return "";
		}
	};
};

// Hook
const useExitPrompt = (
	bool: boolean,
): [boolean, (showPrompt: boolean) => void] => {
	const [showExitPrompt, setShowExitPrompt] = useState<boolean>(bool);

	window.onload = () => {
		initBeforeUnload(showExitPrompt);
	};

	useEffect(() => {
		initBeforeUnload(showExitPrompt);
	}, [showExitPrompt]);

	useEffect(
		() => () => {
			initBeforeUnload(false);
		},
		[],
	);

	return [showExitPrompt, setShowExitPrompt];
};

export default useExitPrompt;
