import React from "react";
import HeaderText from "../../components/HeaderText";
import SubHeader from "../../components/SubHeader";

interface OrfPdfIntroPageContentProps {
	textWidth?: string;
}

export const OrfPdfIntroPageContent: React.FC<OrfPdfIntroPageContentProps> = ({
	textWidth,
}) => (
	<div style={{ width: textWidth ?? "8in" }}>
		<HeaderText pdf={true}>Callisto Vault Record Form</HeaderText>
		<SubHeader pdf={true} mb={0}>
			Thank you for trusting Callisto on your journey towards healing and
			justice.
		</SubHeader>
		<SubHeader pdf={true} mb={0}>
			For your own protection, we recommend that you password protect the PDF
			file and store it in a place that is safe and that only you have access
			to. Once the record form is downloaded as PDF or is printed, Callisto can
			no longer guarantee the security of the document.
		</SubHeader>
		<SubHeader pdf={true} mb={0}>
			Prior to sharing this document with others, determine what level of
			confidentiality you will receive. Not all people will be required to keep
			the information you share confidential.
		</SubHeader>
		<SubHeader
			style={{ fontWeight: "bold", lineHeight: 1, letterSpacing: "0.05px" }}
		>
			Nothing in the form is legal advice. Contact an attorney for legal advice
			and to learn more about your options.
		</SubHeader>
	</div>
);
