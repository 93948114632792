import React, { createContext, useReducer } from "react";
import { ActionMap } from "../../contexts/type";

export type possibleReasons = "record preservation" | "share with attorney";

interface PdfContextState {
	reason: possibleReasons | undefined;
}

interface PdfContextProps {
	children?: React.ReactNode | React.ReactNode[];
}

export const initialPdfState: PdfContextState = {
	reason: undefined,
};

type ActionPayload = {
	SET_REASON: { reason: possibleReasons | undefined };
};

type OrfPdfActions = ActionMap<ActionPayload>[keyof ActionMap<ActionPayload>];

export const IncidentLogPdfContext = createContext<{
	state: PdfContextState;
	setReason: (newReason: possibleReasons | undefined) => void;
}>({
	state: initialPdfState,
	setReason: () => {},
});

const reducer = (state: PdfContextState, action: OrfPdfActions) => {
	switch (action.type) {
		case "SET_REASON":
			return {
				...state,
				reason: action.payload.reason,
			};
		default:
			return { ...state };
	}
};

export const IncidentLogPdfContextProvider: React.FC<PdfContextProps> = ({
	children,
}: PdfContextProps) => {
	const [state, dispatch] = useReducer(reducer, initialPdfState);
	const setReason = (newReason: possibleReasons | undefined) => {
		dispatch({
			type: "SET_REASON",
			payload: {
				reason: newReason,
			},
		});
	};

	return (
		<IncidentLogPdfContext.Provider
			value={{
				state,
				setReason,
			}}
		>
			{children}
		</IncidentLogPdfContext.Provider>
	);
};
