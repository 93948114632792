import React, { useContext } from "react";
import { IConsentFormProps } from "./index";
import HeaderText from "../../components/HeaderText";
import SubHeader from "../../components/SubHeader";
import Checkbox from "../../components/CheckBox";
import { Box, CheckboxGroup, VStack } from "@chakra-ui/react";
import { OrfQuestion } from "../../components/OrfQuestion";
import { onlineCheckboxProps } from "../Orfs";
import { OrfContext } from "../../contexts/OrfContext";

import "../OrfPdf/styles.scss";

const REASON_LIST = [
	"I was not asked whether I wanted to do it",
	"I did not say “yes” (or a word that means “yes”) verbally to the sexual interaction at all",
	"I did not say “yes” (or a word that means “yes”) verbally to the particular type of sexual contact",
	"I was not able to react when it started happening (because it happened quickly, or I was in shock, " +
		"or I was confused about what was going on)",
	"I gave non verbal cues that I didn’t want it (this could include a distressed look, a push, a shaking of the head, etc.)",
	"I said no (or a word that means “no”) to a part of it",
	"I didn’t want it (or at least some part of it)",
	"I was not able to consent (examples: under 18, asleep, intoxicated)",
	"I don’t really remember what happened, potentially because I was too intoxicated or experienced head " +
		"trauma, which is a sign that I couldn’t or didn’t consent",
	"I was coerced or pressured (examples: repeatedly pestered over and over/or, asked by someone who is in a position of power over me)",
	"I was afraid of other consequences if I didn’t say yes (getting a bad grade, being shunned, being shamed, being taunted, etc.)",
	"Other",
];

interface ConsentPageContentProps {
	values: IConsentFormProps;
	pdf?: boolean;
	handleReasonChange?: (values: string[]) => void;
	textWidth?: string;
}

export const OrfConsentPageContent: React.FC<ConsentPageContentProps> = ({
	values,
	handleReasonChange = () => {},
	pdf = false,
	textWidth,
}: ConsentPageContentProps) => {
	const { setModified } = useContext(OrfContext);
	const checkboxProps = onlineCheckboxProps;

	return (
		<div style={{ width: textWidth }}>
			<HeaderText pdf={pdf}>Consent</HeaderText>

			<SubHeader pdf={pdf}>
				Nonconsensual sexual contact is never okay. Just because you didn’t say
				no, doesn’t mean you gave consent. You can consent to one thing without
				consenting to another thing, and you can withdraw consent at any time.
			</SubHeader>

			<SubHeader mb="5px" pdf={pdf}>
				<OrfQuestion pdf={pdf}>
					What happened to me was not okay because:
				</OrfQuestion>
			</SubHeader>
			<SubHeader my={pdf ? "15px" : "5px"} pdf={pdf}>
				Check any and all that apply. Like all questions, it is okay to leave
				this question blank, especially if you are still processing what
				happened. Leaving this question blank does not mean that what happened
				to you was not wrong.
			</SubHeader>
			<CheckboxGroup value={values.reasons} onChange={handleReasonChange}>
				<VStack alignItems="flex-start" mt={pdf ? "15px" : ""}>
					{REASON_LIST.map((reason) => {
						if (pdf) {
							return (
								<Box as="div" fontSize="sm" key={reason}>
									<label>
										<input
											type="checkbox"
											checked={values.reasons.includes(reason)}
											readOnly={true}
											className="pdf"
										/>{" "}
										{reason}
									</label>
								</Box>
							);
						}

						return (
							<Checkbox
								value={reason}
								key={reason}
								onChange={setModified}
								{...checkboxProps}
							>
								{reason}
							</Checkbox>
						);
					})}
				</VStack>
			</CheckboxGroup>
		</div>
	);
};
