import React, { useEffect, useRef } from "react";
import { Flex, Box, IconButton, Text, FlexProps } from "@chakra-ui/react";
import ShieldIcon from "../icons/ShieldIcon";
import CloseIcon from "../icons/CloseIcon";

type NotificationType = "error" | "success";

interface Props extends FlexProps {
	notification: {
		type: NotificationType;
		message: string;
	};
}

const BG_COLOR = {
	success: "brand.primary",
	error: "brand.error",
};

const CLOSE_ICON_COLOR = {
	success: "brand.primary",
	error: "brand.error",
};

const Notification: React.FC<Props> = ({ notification, ...rest }: Props) => {
	const { type, message } = notification;
	const notificationRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		notificationRef.current?.removeAttribute("hidden");
	}, [notification]);

	const handleCloseClick = () => {
		notificationRef.current?.setAttribute("hidden", "true");
	};

	if (!message) {
		return <></>;
	}

	return (
		<Flex
			width="100%"
			backgroundColor={BG_COLOR[type]}
			py="9px"
			px={["5px", "5px", "10px"]}
			position="relative"
			justifyContent="space-between"
			alignItems="center"
			ref={notificationRef}
			mb="28px"
			{...rest}
		>
			<Box width="25px" height="25px">
				{type === "success" && <ShieldIcon />}
			</Box>
			<Text
				as="p"
				textAlign="center"
				fontSize="sm"
				color="white"
				fontWeight="800"
			>
				{message}
			</Text>

			<IconButton
				aria-label="Close toast"
				icon={<CloseIcon />}
				minWidth="auto"
				width="25px"
				color={CLOSE_ICON_COLOR[type]}
				height="25px"
				onClick={handleCloseClick}
				backgroundColor="transparent !important"
			/>
		</Flex>
	);
};

export default Notification;
