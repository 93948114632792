import React, { SyntheticEvent, useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import AnimatedBox from "../../components/layout/AnimatedBox";
import OrfActionButtons from "../../components/OrfActionButtons";
import { PrivateRoutes } from "../../config/routes";
import { OrfContext, YesNoSure } from "../../contexts/OrfContext";
import MainCard from "../../components/MainCard";
import { wait } from "../../service/backend";
import { IFormProps, usePersistData } from "../../service/hooks/usePersistData";
import { handleORFSubmit } from "../../components/layout/sidebar/CreateEditSidebar";
import { OrfOffenderPageContent } from "./OrfOffendersPageContent";
import { useNavigate } from "react-router-dom";

export interface IOffendersFormProps {
	numberOfOffenders: string;
	name: string;
	offenderDetails: string;
	anyoneElse: YesNoSure;
	companionDetails: string;
}

const OrfOffenders: React.FC = () => {
	const {
		state: { steps, mode, currentRecordId, changesMade },
		completeAction,
		setSavingScreenVisibility,
		updateORF,
	} = useContext(OrfContext);
	const navigate = useNavigate();
	const { offenders } = steps;
	const formRef = useRef<HTMLFormElement>(null);

	const { register, handleSubmit, watch, setValue } =
		useForm<IOffendersFormProps>({
			defaultValues: {
				numberOfOffenders: offenders.numberOfOffenders,
				name: offenders.name,
				offenderDetails: offenders.offenderDetails,
				anyoneElse: offenders.anyoneElse,
				companionDetails: offenders.companionDetails,
			},
		});

	useEffect(() => {
		register("anyoneElse");

		const keypressListener = (e: KeyboardEvent) => {
			if (
				document.activeElement &&
				Array.from(document.getElementsByTagName("textarea")).includes(
					document.activeElement as HTMLTextAreaElement,
				)
			) {
				return;
			}

			if (e.key === "Enter") {
				e.preventDefault();
			}
		};

		if (formRef.current) {
			formRef.current.addEventListener("keypress", keypressListener);
		}

		return () => {
			if (formRef.current) {
				formRef.current.removeEventListener("keypress", keypressListener);
			}
		};
	}, []);

	const generatePayload = ({
		numberOfOffenders,
		name,
		anyoneElse,
		offenderDetails,
		companionDetails,
	}: IOffendersFormProps) => ({
		numberOfOffenders,
		name,
		anyoneElse,
		offenderDetails,
		companionDetails,
	});

	const saveForm = (formProps: IOffendersFormProps) => {
		if (changesMade) {
			completeAction("offenders", generatePayload(formProps));
		}
	};

	const setWatchValuesRef = usePersistData(saveForm);

	useEffect(() => {
		setWatchValuesRef(watch() as IFormProps);
	});

	const onContinue = () => {
		navigate(offenders.nextStepRoute);
	};

	const handleSaveAndExit = async (e: SyntheticEvent) => {
		e.preventDefault();

		setSavingScreenVisibility(true);
		await wait(1000);
		const updatedOffenders = generatePayload(watch());
		const updatedSteps = {
			...steps,
			offenders: {
				...offenders,
				...updatedOffenders,
				completed:
					!!updatedOffenders.numberOfOffenders ||
					!!updatedOffenders.name ||
					!!updatedOffenders.anyoneElse ||
					!!updatedOffenders.offenderDetails ||
					!!updatedOffenders.companionDetails,
			},
		};

		await handleORFSubmit(mode, updatedSteps, updateORF, currentRecordId);
		setSavingScreenVisibility(false);
	};

	return (
		<AnimatedBox>
			<MainCard>
				<Box as="form" onSubmit={handleSaveAndExit} id="orf_form" ref={formRef}>
					<OrfOffenderPageContent
						values={watch()}
						register={register}
						setValue={setValue}
					/>
					<OrfActionButtons
						backRoute={PrivateRoutes.ORF_INCIDENTS}
						handleClick={handleSubmit(onContinue)}
					/>
				</Box>
			</MainCard>
		</AnimatedBox>
	);
};

export default OrfOffenders;
