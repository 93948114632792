import React from "react";
import { Flex, FlexProps, Spinner } from "@chakra-ui/react";

const LoadingIndicator: React.FC<FlexProps> = (props: FlexProps) => (
	<Flex
		width="100%"
		height="100%"
		justifyContent="center"
		alignItems="center"
		{...props}
	>
		<Spinner
			thickness="4px"
			speed="0.65s"
			emptyColor="gray.200"
			color="brand.primary"
			size="xl"
		/>
	</Flex>
);

export default LoadingIndicator;
