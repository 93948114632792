import React, { useContext, useState } from "react";
import {
	Box,
	Flex,
	FlexProps,
	Text,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";

import { userEntry } from "../../../../../../lib/data";
import Card from "../../components/Card";
import { PrivateRoutes } from "../../config/routes";
import {
	EntryContext,
	EntryGlobalActionTypes,
} from "../../contexts/EntryContext";
import { StepWhereActionTypes } from "../../contexts/EntryContext/stepWhere";
import {
	IIdentifiers,
	StepWhoActionTypes,
} from "../../contexts/EntryContext/stepWho";
import WhiteButton from "../../components/layout/WhiteButton";
import { usStates } from "../CreateWhere/constant";
import ConfirmModal from "../../components/ConfirmModal";
import { survivorClient, wait } from "../../service/backend";
import Button from "../../components/Button";
import { checkIdentifiersEmpty } from "../CreateWho/validation";
import { useNavigate } from "react-router-dom";

type MatchingEntryItemProps = FlexProps & userEntry & { isDraft: boolean };

const MatchingEntryItem: React.FC<MatchingEntryItemProps> = ({
	id,
	created,
	edited,
	status,
	data,
	isDraft,
	...restProps
}: MatchingEntryItemProps) => {
	const navigate = useNavigate();
	const { dispatch } = useContext(EntryContext);
	const {
		isOpen: showDeleteConfirmModal,
		onOpen: setShowDeleteConfirmModal,
		onClose: closeDeleteModal,
	} = useDisclosure();
	const [entryDeleting, setEntryDeleting] = useState<boolean>(false);
	const toast = useToast();

	const handleEditClick = () => {
		survivorClient
			.submitEvent("Button Click", {
				dashboard: "Entry",
				button: "View/Edit",
			})
			.finally(() => {
				const { perpIDs, incidentState } = data;
				dispatch({
					type: EntryGlobalActionTypes.INITIALIZE_ENTRY,
				});
				dispatch({
					type: StepWhereActionTypes.SET_SELECTED_STATE,
					payload:
						Object.keys(usStates).find(
							(key) => usStates[key] === incidentState,
						) || "",
				});
				dispatch({
					type: StepWhoActionTypes.SET_IDENTIFIERS,
					payload: perpIDs as IIdentifiers,
				});
				dispatch({
					type: EntryGlobalActionTypes.SET_ALL_STEP_COMPLETED,
				});
				dispatch({
					type: EntryGlobalActionTypes.SET_EDIT_ENTRY_ID,
					payload: id,
				});
				dispatch({
					type: EntryGlobalActionTypes.SET_CACHED_ENTRY,
					payload: {
						incidentState: data.incidentState,
						perpIDs: perpIDs as IIdentifiers,
					},
				});
				navigate(PrivateRoutes.ENTRIES_EDIT);
			});
	};

	const handleContinueClick = () => {
		survivorClient
			.submitEvent("Button Click", {
				dashboard: "Entry",
				button: "Continue Entry",
			})
			.finally(() => {
				const { perpIDs, incidentState } = data;
				dispatch({
					type: EntryGlobalActionTypes.INITIALIZE_ENTRY,
				});
				if (incidentState) {
					dispatch({
						type: StepWhereActionTypes.SET_SELECTED_STATE,
						payload:
							Object.keys(usStates).find(
								(key) => usStates[key] === incidentState,
							) || "",
					});
				}
				if (checkIdentifiersEmpty(perpIDs as Record<string, string[]>)) {
					dispatch({
						type: StepWhoActionTypes.SET_IDENTIFIERS,
						payload: perpIDs as IIdentifiers,
					});
					dispatch({
						type: StepWhoActionTypes.MARK_AS_COMPLETED,
					});
				}
				dispatch({
					type: EntryGlobalActionTypes.SET_EDIT_ENTRY_ID,
					payload: id,
				});
				dispatch({
					type: EntryGlobalActionTypes.SET_CACHED_ENTRY,
					payload: {
						incidentState: data.incidentState,
						perpIDs: perpIDs as IIdentifiers,
					},
				});
				dispatch({
					type: EntryGlobalActionTypes.SET_CONTINUING_DRAFT,
				});
				navigate(PrivateRoutes.CREATE_WHERE);
			});
	};

	const handleDeleteClick = () => {
		survivorClient
			.submitEvent("Button Click", {
				dashboard: "Entry",
				button: isDraft ? "Delete Draft Entry" : "Delete Entry",
			})
			.finally(() => {
				setShowDeleteConfirmModal();
			});
	};

	const handleDeleteAction = async () => {
		setEntryDeleting(true);
		try {
			await wait(100);

			if (isDraft) {
				await survivorClient.deleteDraftEntry(id);
			} else {
				await survivorClient.deleteMatchingEntry(id);
			}

			if (isDraft) {
				toast({
					title: "Draft entry deleted.",
					status: "success",
					position: "top",
				});
			} else {
				toast({
					title:
						"Entry deleted. If you have an open case with an LOC, please contact them directly to close it.",
					status: "success",
					position: "top",
				});
			}
			closeDeleteModal();
			dispatch({
				type: EntryGlobalActionTypes.INITIALIZE_ENTRY,
			});
			navigate(PrivateRoutes.MATCHING_SYSTEM);
		} catch (e) {
			toast({
				title: "There was a problem deleting the entry.",
				status: "error",
				position: "top",
			});
		}
	};

	return (
		<Card
			flexDir={{ base: "column", md: "row" }}
			justifyContent="space-between"
			p={{ base: "20px", md: "40px" }}
			fontSize="sm"
			{...restProps}
		>
			<Box>
				<Text mb="16px" fontSize="md">
					{isDraft && <b>Draft Created on: </b>}
					{!isDraft && <b>Submitted on: </b>}
					{created.toDateString()}&nbsp;{created.toLocaleTimeString()}
				</Text>
				<Text mb="16px">
					<b>Last Edited:</b>&nbsp;
					{edited.toDateString()}&nbsp;{edited.toLocaleTimeString()}
				</Text>
				<Text mb="16px">
					<b>Status:</b>&nbsp;
					{status}
				</Text>
			</Box>
			<Flex flexDir="column">
				<Button
					buttonColor="brand.primary"
					m="3px"
					onClick={isDraft ? handleContinueClick : handleEditClick}
				>
					{isDraft ? "Continue Entry" : "View/Edit"}
				</Button>
				<WhiteButton m="3px" onClick={handleDeleteClick}>
					Delete entry
				</WhiteButton>
			</Flex>
			<ConfirmModal
				headerText={
					isDraft
						? "<b>Are you sure?</b>"
						: "<b>Are you sure you want to delete your matching entry?<br/>This action cannot be undone</b>"
				}
				submitText="Yes, Delete My Entry"
				cancelText="Cancel"
				onSubmit={handleDeleteAction}
				isConfirming={entryDeleting}
				isOpen={showDeleteConfirmModal}
				onClose={closeDeleteModal}
				onCancel={closeDeleteModal}
			/>
		</Card>
	);
};

export default MatchingEntryItem;
