import { useEffect, useState } from "react";
import { survivorClient } from "../backend";

export function useReopened() {
	const [reopened, setReopened] = useState<boolean>(false);

	useEffect(() => {
		void (async () => {
			setReopened(await survivorClient.isReopened());
		})();
	}, []);

	return reopened;
}
