import React from "react";
import { Text, TextProps } from "@chakra-ui/react";

export const PdfTextarea: React.FC<TextProps> = ({
	children,
	...props
}: TextProps) => (
	<Text padding="5px" paddingTop="1px" fontSize="sm" {...props}>
		{children}
	</Text>
);
