import React, { ChangeEvent, useContext } from "react";
import { IKnowFormProps } from "./index";
import HeaderText from "../../components/HeaderText";
import SubHeader from "../../components/SubHeader";
import CheckboxRadio from "../../components/CheckboxRadio";
import Checkbox from "../../components/CheckBox";
import Textarea from "../../components/Textarea";
import { OrfContext, YesNoSure } from "../../contexts/OrfContext";
import { OrfQuestion } from "../../components/OrfQuestion";
import { PdfTextarea } from "../../components/PdfTextarea";
import { onlineCheckboxProps } from "../Orfs";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Box, VStack } from "@chakra-ui/react";

const YES_NO_SURES: YesNoSure[] = ["Yes", "No", "I'm not sure"];
const YES_NOS: YesNoSure[] = ["Yes", "No"];

interface KnowPageContentProps {
	values: IKnowFormProps;
	pdf?: boolean;
	setValue: UseFormSetValue<IKnowFormProps>;
	register: UseFormRegister<IKnowFormProps>;
	textWidth?: string;
}

export const OrfKnowPageContent: React.FC<KnowPageContentProps> = ({
	values,
	setValue = () => {},
	register,
	pdf = false,
	textWidth,
}: KnowPageContentProps) => {
	const { setModified } = useContext(OrfContext);
	const checkboxProps = onlineCheckboxProps;
	const { onChange: onChangeDescribed } = register("informationYouDescribed");
	const { onChange: onChangeTold } = register("informationYouTold");

	const examples =
		"Examples: how many people, what they saw or heard, their relationship to you, " +
		"where they may have been standing or sitting, how physically close to you they were.";

	const infoTold =
		"Who you told, what you told them, when you told them, how you told them (on the " +
		"phone, in person, over text, etc), their relationship to you or the offender, etc.";

	return (
		<div style={{ width: textWidth }}>
			<HeaderText pdf={pdf}>People who know or might know</HeaderText>

			<SubHeader mb="5px" pdf={pdf}>
				<OrfQuestion pdf={pdf}>
					Did anyone else see or hear either all or any part of what happened?
				</OrfQuestion>
			</SubHeader>
			<CheckboxRadio
				value={values.anyoneSaw}
				setValue={(v: string) => setValue("anyoneSaw", v as YesNoSure)}
			>
				<VStack alignItems="flex-start">
					{!pdf && (
						<>
							{YES_NO_SURES.map((v) => (
								<Checkbox
									value={v as string}
									key={v}
									onChange={setModified}
									{...checkboxProps}
								>
									{v as string}
								</Checkbox>
							))}
						</>
					)}
					{pdf && (
						<>
							{YES_NO_SURES.map((v) => (
								<Box key={`${v}-div`} as="div" fontSize="sm">
									<label key={`${v}-label`}>
										<input
											key={v}
											type="checkbox"
											checked={values.anyoneSaw === v}
											readOnly={true}
											className="pdf"
										/>{" "}
										{v}
									</label>
								</Box>
							))}
						</>
					)}
				</VStack>
			</CheckboxRadio>

			<SubHeader mb="5px" mt="30px" pdf={pdf}>
				<OrfQuestion pdf={pdf}>
					Did you interact with anyone right before or after the incident?
					<br />
					This could be people who were at the scene of the incident or nearby,
					who saw you or the offender leave, or who assisted you in calling for
					help.
				</OrfQuestion>
			</SubHeader>
			<CheckboxRadio
				value={values.anyoneInteract}
				setValue={(v: string) => setValue("anyoneInteract", v as YesNoSure)}
			>
				<VStack alignItems="flex-start">
					{!pdf &&
						YES_NO_SURES.map((v) => (
							<Checkbox
								value={v as string}
								key={v}
								onChange={setModified}
								{...checkboxProps}
							>
								{v as string}
							</Checkbox>
						))}
					{pdf &&
						YES_NO_SURES.map((v) => (
							<Box key={`${v}-div`} as="div" fontSize="sm">
								<label key={`${v}-label`}>
									<input
										key={v}
										type="checkbox"
										checked={values.anyoneInteract === v}
										readOnly={true}
										className="pdf"
									/>{" "}
									{v}
								</label>
							</Box>
						))}
				</VStack>
			</CheckboxRadio>

			<SubHeader mb="5px" mt="30px" pdf={pdf}>
				<OrfQuestion pdf={pdf}>
					What information do you know about any of the people described above?
				</OrfQuestion>
			</SubHeader>
			{(!pdf || !values.informationYouDescribed) && (
				<Textarea
					{...register("informationYouDescribed")}
					placeholder={examples}
					onChange={async (e: ChangeEvent<HTMLTextAreaElement>) => {
						await onChangeDescribed(e);
						setModified();
					}}
					mt={pdf ? "15px" : ""}
				/>
			)}
			{pdf && values.informationYouDescribed && (
				<PdfTextarea>{values.informationYouDescribed}</PdfTextarea>
			)}

			<SubHeader mb="5px" mt="30px">
				<OrfQuestion pdf={pdf}>
					Did you tell anyone about the incident?
				</OrfQuestion>
			</SubHeader>
			<CheckboxRadio
				value={values.toldAnyone}
				setValue={(v: string) =>
					setValue("toldAnyone", v as "Yes" | "No" | null)
				}
			>
				<VStack alignItems="flex-start">
					{!pdf &&
						YES_NOS.map((v) => (
							<Checkbox
								value={v as string}
								key={v}
								onChange={setModified}
								{...checkboxProps}
							>
								{v as string}
							</Checkbox>
						))}
					{pdf &&
						YES_NOS.map((v) => (
							<Box key={`${v}-div`} as="div" fontSize="sm">
								<label key={`${v}-label`}>
									<input
										key={v}
										type="checkbox"
										checked={values.toldAnyone === v}
										readOnly={true}
										className="pdf"
									/>{" "}
									{v}
								</label>
							</Box>
						))}
				</VStack>
			</CheckboxRadio>

			<SubHeader mb="5px" mt="30px">
				<OrfQuestion pdf={pdf}>
					What information do you know about the people you told?
				</OrfQuestion>
			</SubHeader>
			{(!pdf || !values.informationYouTold) && (
				<Textarea
					{...register("informationYouTold")}
					placeholder={infoTold}
					onChange={async (e: ChangeEvent<HTMLTextAreaElement>) => {
						await onChangeTold(e);
						setModified();
					}}
				/>
			)}
			{pdf && values.informationYouTold && (
				<PdfTextarea>{values.informationYouTold}</PdfTextarea>
			)}

			<SubHeader mb="5px" mt="30px">
				<OrfQuestion pdf={pdf}>
					Do you know if the offender told anyone about the incident?
				</OrfQuestion>
			</SubHeader>
			<CheckboxRadio
				value={values.offenderTold}
				setValue={(v: string) => setValue("offenderTold", v as YesNoSure)}
			>
				<VStack alignItems="flex-start">
					{!pdf &&
						YES_NO_SURES.map((v) => (
							<Checkbox
								value={v as string}
								key={v}
								onChange={setModified}
								{...checkboxProps}
							>
								{v as string}
							</Checkbox>
						))}
					{pdf &&
						YES_NO_SURES.map((v) => (
							<Box key={`${v}-div`} as="div" fontSize="sm">
								<label key={`${v}-label`}>
									<input
										key={v}
										type="checkbox"
										checked={values.offenderTold === v}
										readOnly={true}
										className="pdf"
									/>{" "}
									{v}
								</label>
							</Box>
						))}
				</VStack>
			</CheckboxRadio>
		</div>
	);
};
