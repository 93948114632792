import React from "react";
import { Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import HeaderText from "../../components/HeaderText";
import SubHeader from "../../components/SubHeader";
import { PrivateRoutes } from "../../config/routes";
import MainCard from "../../components/MainCard";
import Button from "../../components/Button";
import { survivorClient } from "../../service/backend";

const SocialAccounts: React.FC = () => {
	const handleClick = async (action: string) => {
		await survivorClient.submitEvent(action, {});
	};

	return (
		<>
			<Button buttonColor="brand.primary" mb="30px">
				<Link to={PrivateRoutes.CREATE_WHO}>&lt;&nbsp;Go back</Link>
			</Button>
			<MainCard>
				<HeaderText>
					Currently, we can only detect serial offenders through social
					accounts.
				</HeaderText>
				<SubHeader>
					If you do not know any social identifiers of your offender, we suggest
					creating an Incident Log of what happened for your own reference. You
					can&nbsp;
					<Text as="span" color="brand.link" textDecor="underline">
						<Link
							to={PrivateRoutes.ILOG}
							onClick={() =>
								handleClick("Redirect to Incident Log from create entry")
							}
						>
							create an Incident Log
						</Link>
					</Text>
					&nbsp;within your Callisto Vault account.
				</SubHeader>
			</MainCard>
		</>
	);
};

export default SocialAccounts;
