import React, { FormEvent } from "react";
import {
	Box,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
} from "@chakra-ui/react";
import WhiteButton from "./layout/WhiteButton";
import Button from "./Button";

interface Props {
	submitText: string;
	cancelText: string;
	returnText?: string;
	headerText: string;
	isConfirming: boolean;
	isOpen: boolean;
	onReturn?: () => void;
	onClose(): void;
	onSubmit(): void | Promise<void>;
	onCancel(): void;
}

const ConfirmModal: React.FC<Props> = ({
	submitText,
	cancelText,
	headerText,
	isConfirming,
	isOpen,
	onClose,
	onSubmit,
	onCancel,
}: Props) => {
	const handleSubmit = async (e: FormEvent<HTMLElement>) => {
		e.preventDefault();
		await onSubmit();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent
				maxW="650px"
				minHeight="215px"
				padding="40px 40px 36px 40px"
				as="form"
				borderRadius="0px"
				onSubmit={handleSubmit}
				position="relative"
				display="flex"
				flexDir="column"
			>
				<ModalCloseButton color="brand.primary" />
				<Text
					as="h3"
					fontSize={["xs", "xs", "sm"]}
					my="18px"
					textAlign="center"
					dangerouslySetInnerHTML={{ __html: headerText }}
					flex="1 1"
					color="brand.primary"
				/>
				<Box textAlign="center" mt="20px" pt="20px">
					<WhiteButton
						mx="10px"
						padding="15px 10px"
						onClick={onCancel}
						fontSize={["xs", "xs", "sm"]}
						width={["100%", "100%", "auto"]}
						mt="5px"
					>
						{cancelText}
					</WhiteButton>
					<Button
						buttonColor="brand.primary"
						mx="10px"
						padding="15px 10px"
						type="submit"
						fontSize={["xs", "xs", "sm"]}
						isLoading={isConfirming}
						width={["100%", "100%", "auto"]}
						mt="5px"
					>
						{submitText}
					</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default ConfirmModal;
