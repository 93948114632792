import { Box, useDisclosure } from "@chakra-ui/react";
import React, { useContext } from "react";
import { PrivateRoutes } from "../../config/routes";
import {
	EntryContext,
	EntryGlobalActionTypes,
} from "../../contexts/EntryContext";

import CreateEditHeader from "./header/CreateEditHeader";
import CreateEditSidebar from "./sidebar/CreateEditSidebar";
import SubmitCancelReturnModal from "../SubmitCancelReturnModal";
import { Outlet, useNavigate } from "react-router-dom";

const CreateEntryLayout: React.FC = () => {
	const navigate = useNavigate();
	const {
		state: { steps },
		dispatch,
	} = useContext(EntryContext);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleBackClick = () => {
		if (steps.stepWhere.changesMade || steps.stepWho.changesMade) {
			onOpen();
		} else {
			handleCancelClick();
		}
	};

	const handleCancelClick = () => {
		dispatch({
			type: EntryGlobalActionTypes.INITIALIZE_ENTRY,
		});
		navigate(PrivateRoutes.MATCHING_SYSTEM);
	};

	return (
		<>
			<CreateEditHeader
				key="createOrfEntryHeader"
				handleBackClick={handleBackClick}
			/>
			<CreateEditSidebar
				key="createOrfEntrySidebar"
				steps={steps}
				title="Create Matching Entry"
				type="create_entry"
				handleBackClick={handleBackClick}
			/>
			<Box
				width={["100%", "100%", "calc(100% - 300px)"]}
				marginLeft={["0px", "0px", "300px"]}
				minHeight="100%"
				backgroundColor="brand.appBackground"
				padding={{ base: "200px 0px 20px 0px", md: "100px 20px 20px 20px" }}
				as="main"
				display="flex"
				flexDir="column"
				alignItems="center"
				position="relative"
				backgroundRepeat="none"
				backgroundSize="cover"
			>
				<Box maxWidth="900px" width="100%">
					<Outlet />
				</Box>
			</Box>

			<SubmitCancelReturnModal
				key="submitCancelReturnModal"
				submitText="Save Draft and Exit"
				formId="entry_form"
				cancelText={"Discard Changes"}
				headerText="If you have made changes and return to the dashboard without saving them, you will lose them.
        Would you like to save a draft?"
				isOpen={isOpen}
				onClose={onClose}
				onCancel={handleCancelClick}
				isConfirming={false}
				returnText="Return to Entry"
				onReturn={onClose}
			/>
		</>
	);
};

export default CreateEntryLayout;
