import * as React from "react";
import { useState } from "react";
import { Paragraph } from "./Paragraph";
import ChevronsDownIcon from "../../assets/icons/chevrons-down-solid.svg";
import ChevronsRightIcon from "../../assets/icons/chevrons-right-solid.svg";
import { Image } from "@chakra-ui/react";

interface CollapsibleParagraphProps {
	open?: boolean;
	mb?: string;
	prompt: string;
}

export const CollapsibleSection: React.FC<CollapsibleParagraphProps> = ({
	open = false,
	mb,
	children,
	prompt,
}) => {
	const [isOpen, setOpen] = useState<boolean>(open);

	const handleOpening = () => {
		setOpen((prev) => !prev);
	};

	const iconSize = "12px";

	return (
		<>
			<Paragraph
				mb={mb ? mb : ""}
				props={{ onClick: handleOpening, className: "clickable" }}
			>
				<button type="button">
					{!isOpen ? (
						<Image
							width={iconSize}
							height={iconSize}
							src={ChevronsRightIcon as string}
						/>
					) : (
						<Image
							width={iconSize}
							height={iconSize}
							src={ChevronsDownIcon as string}
						/>
					)}
				</button>
				<em>
					&nbsp;
					<span dangerouslySetInnerHTML={{ __html: prompt }} />
				</em>
			</Paragraph>
			{isOpen && children}
		</>
	);
};
