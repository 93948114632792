import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ResourcesIcon from "../../../assets/icons/resources.svg";
import { Paragraph } from "../../components/Paragraph";
import { CollapsibleSection } from "../../components/CollapsibleSection";

import "./styles.scss";
import { Hyperlink } from "../../components/Hyperlink";
import Button from "../../components/Button";
import { survivorClient } from "../../service/backend";
import { CollapsibleSectionWithLink } from "../../components/CollapsibleSectionWithLink";
import { useLocation, useNavigate } from "react-router-dom";
import { PrivateRoutes } from "../../config/routes";

export const Resources: React.FC = () => {
	const {
		state,
	}: {
		state: {
			target: string;
			text: string;
		};
	} = useLocation() as unknown as {
		state: {
			target: string;
			text: string;
		};
	};
	const navigate = useNavigate();

	const target = state?.target ?? PrivateRoutes.DASHBOARD;
	const buttonText = state?.text ?? "Dashboard";

	const handleClickLink = (action: string) => {
		survivorClient
			.submitEvent(`Click resource link: ${action}`, {})
			.catch(() => {});
	};

	return (
		<Flex overflowY="visible" flexDir="column" id="resourcesPage">
			<Text as="h1" fontSize="xl" my="16px">
				<Image src={ResourcesIcon as string} width="100px" />
				Resources
			</Text>

			<Paragraph mb="0">
				Thank you for trusting and using Callisto Vault. As an organization that
				is committed to survivor agency, empowerment, and options, we wanted to
				share some important resources to support you on your healing journey.
			</Paragraph>

			<Paragraph mb="0">
				Engaging in the healing process can be restorative for your mental,
				physical, and spiritual well-being. A big piece of the healing process
				is establishing a renewed relationship with yourself to release trauma
				and redefine balance and wholeness.
			</Paragraph>

			<Paragraph mb="0">
				To support your healing, we have partnered with Rewire Therapy, an
				online company offering trauma survivors and practitioners self-paced
				somatic and body-based therapeutic programs to address trauma and
				symptoms of PTSD. Rewire’s programs utilize multiple modalities for
				survivors to find the right fit (Polyvagal Theory inspired vagal toning
				exercises, dance and movement therapies, trauma-informed yoga, bilateral
				stimulation therapies, somatic therapies, qigong, sound and music
				therapies, martial arts, tai chi, expressive arts therapies,
				hypnotherapy).
			</Paragraph>
			<CollapsibleSection
				prompt="As part of our partnership with Rewire, you can access one of the following programs for FREE using our
         exclusive coupon code (CALLISTO):"
			>
				<ul>
					<li>
						<strong>Trauma-Informed Yoga Program ($240 value):</strong>
						<br />
						<Hyperlink
							to="https://www.rewiretraumatherapy.com/trauma-informed-yoga"
							onClick={() => {
								handleClickLink("Rewire Trauma-Informed Yoga Program");
							}}
							target="_blank"
						/>
					</li>

					<li>
						<strong>
							Healing From Sexual Abuse And Reproductive Trauma ($240 value):
						</strong>
						<br />
						<Hyperlink
							to="https://www.rewiretraumatherapy.com/healing-sexual-abuse-reproductive-trauma"
							onClick={() => {
								handleClickLink(
									"Rewire Healing From Sexual Abuse And Reproductive Trauma",
								);
							}}
							target="_blank"
						/>
					</li>

					<li>
						<strong>
							7-Day Rewire Therapy Program ($120 value): 7-day option only
						</strong>
						<br />
						<Hyperlink
							to="https://www.rewiretraumatherapy.com/rewire-therapy-for-trauma-healing"
							onClick={() => {
								handleClickLink("Rewire 7-Day Rewire Therapy Program");
							}}
							target="_blank"
						/>
					</li>

					<li>
						<strong>
							Introduction To Trauma Therapy Program ($160 value):
						</strong>
						<br />
						<Hyperlink
							to="https://www.rewiretraumatherapy.com/ttp"
							onClick={() => {
								handleClickLink(
									"Rewire Introduction To Trauma Therapy Program",
								);
							}}
							target="_blank"
						/>
					</li>
				</ul>
			</CollapsibleSection>

			<Paragraph mb="0">
				<strong>
					We offer some additional free resources to support you on your
					journey:
				</strong>
			</Paragraph>

			<CollapsibleSectionWithLink
				to="https://www.crisistextline.org/"
				text="Crisis Text Line"
				onClick={() => {
					handleClickLink("Crisis Text Line");
				}}
				target="_blank"
			>
				<Paragraph mb="0" mt="0">
					Crisis Text Line serves anyone, in any type of crisis, providing
					access to free, 24/7 support via mediums people already use and trust
					including text, online chat, and WhatsApp. To get started, Text{" "}
					<strong>HOME</strong> to <strong>741741</strong> to connect with a
					volunteer crisis Counselor.
				</Paragraph>
			</CollapsibleSectionWithLink>

			<CollapsibleSectionWithLink
				to="https://hotline.rainn.org/online"
				target="_blank"
				onClick={() => {
					handleClickLink("National Sexual Assault Hotline");
				}}
				text="National Sexual Assault Hotline"
			>
				<Paragraph mt="0">
					The National Sexual Assault Hotline, offered by RAINN, is an online
					resource available 24/7 to help those affected by sexual violence. A
					RAINN support specialist can provide a range of free services
					including:
				</Paragraph>
				<ul>
					<li>Confidential support from a trained support specialist</li>
					<li>Someone to help you talk through what happened</li>
					<li>
						Resources that can assist with your next steps toward healing and
						recovery
					</li>
					<li>Referrals for long term support in your area</li>
					<li>Information about the laws and resources in your community</li>
				</ul>
			</CollapsibleSectionWithLink>

			<CollapsibleSectionWithLink
				to="https://www.thehotline.org/"
				target="_blank"
				text="National Domestic Violence Hotline"
				onClick={() => {
					handleClickLink("National Domestic Violence Hotline");
				}}
			>
				<Paragraph mt="0">
					The National Domestic Violence Hotline provides highly-trained, expert
					advocates who offer free, confidential, and compassionate support,
					crisis intervention information, education, and referral services in
					over 200 languages, 24 hours a day, 7 days a week in the United
					States.
				</Paragraph>
			</CollapsibleSectionWithLink>

			<CollapsibleSectionWithLink
				to="https://988lifeline.org/"
				target="_blank"
				text="National Suicide Hotline"
				onClick={() => {
					handleClickLink("National Suicide Hotline");
				}}
			>
				<Paragraph mt="0">
					The 988 Suicide & Crisis Lifeline is a national network of local
					crisis centers that provides free and confidential emotional support
					to people in suicidal crisis or emotional distress 24 hours a day, 7
					days a week in the United States.
				</Paragraph>
			</CollapsibleSectionWithLink>

			<CollapsibleSectionWithLink
				to="https://sanctuary.metoomvmt.org//"
				target="_blank"
				text="Survivor's Sanctuary"
				onClick={() => {
					handleClickLink("Survivor's Sanctuary");
				}}
			>
				<Paragraph mt="0">
					me too. International’s Survivor’s Sanctuary is a self-guided healing
					platform for survivors who are 18+ and offers 36 healing lessons
					designed to meet your needs whether you are just beginning or looking
					to deepen your healing journey. We invite you to begin exploring the
					multiple pathways toward healing by creating a free account.
				</Paragraph>
			</CollapsibleSectionWithLink>

			<Box textAlign="center">
				<Button
					buttonColor="brand.primary"
					onClick={() => {
						navigate(target);
					}}
				>
					Back to {buttonText}
				</Button>
			</Box>
		</Flex>
	);
};
