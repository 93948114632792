import React, {
	ChangeEvent,
	SyntheticEvent,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useForm } from "react-hook-form";
import { Box } from "@chakra-ui/layout";
import { HStack, useRadioGroup } from "@chakra-ui/react";

import AnimatedBox from "../../components/layout/AnimatedBox";
import ElectronicEvidence from "./ElectronicEvidence";
import PhysicalEvidence from "./PhysicalEvidence";
import Textarea from "../../components/Textarea";
import { OrfContext } from "../../contexts/OrfContext";
import OrfActionButtons from "../../components/OrfActionButtons";
import { PrivateRoutes } from "../../config/routes";
import MainCard from "../../components/MainCard";
import RadioCard from "../../components/RadioButton";
import { wait } from "../../service/backend";
import { IFormProps, usePersistData } from "../../service/hooks/usePersistData";
import { handleORFSubmit } from "../../components/layout/sidebar/CreateEditSidebar";
import { Evidence } from "./Evidence";
import { useNavigate } from "react-router-dom";

export interface IEvidenceFormProps {
	physicalInfo: string;
	electronicInfo: string;
}

const ELECTRONIC_EVIDENCE = "Electronic Evidence";
const PHYSICAL_EVIDENCE = "Physical Evidence";

const OrfEvidence: React.FC = () => {
	const navigate = useNavigate();
	const {
		state: { steps, mode, currentRecordId, changesMade },
		completeAction,
		setSavingScreenVisibility,
		updateORF,
		setModified,
	} = useContext(OrfContext);
	const [selectedRadio, setRadio] = useState<string>(ELECTRONIC_EVIDENCE);
	const { evidence } = steps;
	const formRef = useRef<HTMLFormElement>(null);

	useEffect(() => {
		const keypressListener = (e: KeyboardEvent) => {
			if (
				document.activeElement &&
				Array.from(document.getElementsByTagName("textarea")).includes(
					document.activeElement as HTMLTextAreaElement,
				)
			) {
				return;
			}

			if (e.key === "Enter") {
				e.preventDefault();
			}
		};

		if (formRef.current) {
			formRef.current.addEventListener("keypress", keypressListener);
		}

		return () => {
			if (formRef.current) {
				formRef.current.removeEventListener("keypress", keypressListener);
			}
		};
	}, []);

	const { handleSubmit, register, watch } = useForm<IEvidenceFormProps>({
		defaultValues: {
			physicalInfo: evidence.physicalInfo,
			electronicInfo: evidence.electronicInfo,
		},
	});
	const { onChange: onChangeElectronic } = register("electronicInfo");
	const { onChange: onChangePhysical } = register("physicalInfo");

	const options = [ELECTRONIC_EVIDENCE, PHYSICAL_EVIDENCE];

	const { getRadioProps } = useRadioGroup({
		name: "evidence",
		defaultValue: ELECTRONIC_EVIDENCE,
		onChange: (nextV: string) => {
			setRadio(nextV);
		},
	});

	const generatePayload = ({
		physicalInfo,
		electronicInfo,
	}: IEvidenceFormProps) => ({
		physicalInfo,
		electronicInfo,
	});

	const saveForm = (formProps: IEvidenceFormProps) => {
		if (changesMade) {
			completeAction("evidence", generatePayload(formProps));
		}
	};

	const setWatchValuesRef = usePersistData(saveForm);

	useEffect(() => {
		setWatchValuesRef(watch() as IFormProps);
	});

	const onContinue = () => {
		navigate(evidence.nextStepRoute);
	};

	const handleSaveAndExit = async (e: SyntheticEvent) => {
		e.preventDefault();

		setSavingScreenVisibility(true);
		await wait(1000);
		const updatedEvidence = generatePayload(watch());
		const updatedSteps = {
			...steps,
			evidence: {
				...evidence,
				...updatedEvidence,
				completed:
					!!updatedEvidence.physicalInfo || !!updatedEvidence.electronicInfo,
			},
		};

		await handleORFSubmit(mode, updatedSteps, updateORF, currentRecordId);
		setSavingScreenVisibility(false);
	};

	return (
		<AnimatedBox>
			<MainCard>
				<Box as="form" onSubmit={handleSaveAndExit} id="orf_form" ref={formRef}>
					<Evidence />
					<HStack>
						{options.map((value) => {
							const radio = getRadioProps({ value });
							return (
								<RadioCard key={value} {...radio}>
									{value}
								</RadioCard>
							);
						})}
					</HStack>
					{selectedRadio === ELECTRONIC_EVIDENCE && <ElectronicEvidence />}
					{selectedRadio === PHYSICAL_EVIDENCE && <PhysicalEvidence />}
					<Box
						display={selectedRadio === ELECTRONIC_EVIDENCE ? "block" : "none"}
					>
						<Textarea
							rows={7}
							height="auto"
							{...register("electronicInfo")}
							onChange={async (e: ChangeEvent<HTMLTextAreaElement>) => {
								await onChangeElectronic(e);
								setModified();
							}}
						/>
					</Box>
					<Box display={selectedRadio === PHYSICAL_EVIDENCE ? "block" : "none"}>
						<Textarea
							rows={7}
							height="auto"
							{...register("physicalInfo")}
							onChange={async (e: ChangeEvent<HTMLTextAreaElement>) => {
								await onChangePhysical(e);
								setModified();
							}}
						/>
					</Box>
					<OrfActionButtons
						backRoute={PrivateRoutes.ORF_OFFENDERS}
						handleClick={handleSubmit(onContinue)}
					/>
				</Box>
			</MainCard>
		</AnimatedBox>
	);
};

export default OrfEvidence;
