import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import theme from "../styles/theme";

type PhoneNumberInputProps = {
	value: string | undefined;
	isDisabled: boolean;
	onChange: (value?: string | undefined) => void;
};

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
	onChange,
	isDisabled,
	value,
}) => {
	const [phoneNumber, setPhoneNumber] = useState<string | undefined>(value);
	const [focused, setFocused] = useState(false);
	const cssProperties = {
		width: "100%",
		border: "1px solid",
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
		borderColor: focused
			? theme.colors.brand.primary
			: theme.colors.input.border,
		padding: "10px 15px",
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,max-len
		backgroundColor: theme.colors.brand.brightWhite,
		fontSize: "sm",
		height: "50px",
		borderRadius: "0px",
		outline: "none",
	};

	useEffect(() => {
		if (value) {
			setPhoneNumber(value);
		}
	}, [value]);

	const onChangeFunction = onChange as (value?: string | undefined) => void;
	const handleChange = (newValue?: string | undefined) => {
		setPhoneNumber(newValue);
		onChangeFunction(newValue);
	};

	return (
		<PhoneInput
			defaultCountry="US"
			disabled={isDisabled}
			value={phoneNumber}
			onChange={handleChange}
			style={cssProperties}
			onFocus={() => setFocused(true)}
			onBlur={() => setFocused(false)}
			countrySelectProps={{
				style: cssProperties,
				unicodeFlags: true,
			}}
			numberInputProps={{
				style: {
					...cssProperties,
					marginRight: "-16px",
				},
			}}
		/>
	);
};

export default PhoneNumberInput;
