import React, { useContext, useEffect, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Paragraph } from "../../components/Paragraph";
import { IncidentLogItem } from "./IncidentLogItem";
import { survivorClient } from "../../service/backend";
import { LegacyEncryptedRecordFormItem } from "./LegacyEncryptedRecordFormItem";
import { PrivateRoutes } from "../../config/routes";
import { IncidentLogContext } from "../../contexts/IncidentLogContext";
import Card from "../../components/Card";
import Button from "../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import WhiteButton from "../../components/layout/WhiteButton";
import { userIncidentLog, userRecord } from "../../../../../../lib/data";
import EncryptedDoc from "../../../assets/icons/encrypted-form.svg";

export const IncidentLogDashboard: React.FC = () => {
	const incidentLogs = survivorClient.incidentLogs || [];
	const erfs = survivorClient.records || [];
	const navigate = useNavigate();
	const { resetIncidentLogState } = useContext(IncidentLogContext);
	const [displayLogs, setDisplayLogs] = useState<userIncidentLog[]>([]);
	const [displayErfs, setDisplayErfs] = useState<userRecord[]>([]);

	useEffect(() => {
		setDisplayLogs(
			incidentLogs.sort((a, b) => a.created.getTime() - b.created.getTime()),
		);
	}, [incidentLogs]);

	useEffect(() => {
		setDisplayErfs(
			erfs.sort((a, b) => a.created.getTime() - b.created.getTime()),
		);
	}, [erfs]);

	const handleCreateClick = () => {
		resetIncidentLogState();
		survivorClient
			.submitEvent("Button Click", {
				dashboard: "Incident Log",
				button: "Create Incident Log",
			})
			.then(() => survivorClient.incrementCount("Incident Log Creation"))
			.finally(() => navigate(PrivateRoutes.ILOG_WHO_WHAT));
	};

	return (
		<Flex overflowY="visible" flexDir="column">
			<Text as="h1" fontSize="xl" my="16px">
				<Image src={EncryptedDoc as string} width="100px" />
				Incident Log
			</Text>

			<Paragraph>
				Survivors have expressed that having to repeatedly share the details of
				an incident each time they engage with a new support service or
				individual can be re-traumatizing, so Callisto created this tool as an
				option for any survivor who wants to create a timestamped log of their
				incident.
			</Paragraph>

			<Paragraph>
				We offer the Incident Log as a space and opportunity to process and
				capture the details of your experience at your own pace, in a place
				where you feel comfortable and at a time convenient to you. You might
				want to document what happened as a way to process it, or because it may
				feel less difficult to share a written document than recount your story
				verbally. You can always update, edit, refer back to, or delete the
				information you have captured here, now or in the future.
			</Paragraph>

			<Paragraph>
				How you use this log is up to you. It may be helpful should you choose
				to: report the incident; seek secondary services, such as medical,
				therapeutic, or legal; or simply save it for yourself.
			</Paragraph>

			{incidentLogs.length + erfs.length === 0 && (
				<Card
					padding={{ base: "20px", md: "40px" }}
					mt="30px"
					flexDir="column"
					alignItems="center"
				>
					<Text as="h2" my="16px" fontWeight="800">
						You currently have no Incident Logs
					</Text>
					<Button
						buttonColor="brand.primary"
						my="20px"
						fontSize="sm"
						width={{ base: "100%", md: "auto" }}
						onClick={handleCreateClick}
					>
						Create an Incident Log
					</Button>
				</Card>
			)}

			{incidentLogs.length + erfs.length > 0 && (
				<Box>
					<Box>
						<Link to={PrivateRoutes.ILOG_WHO_WHAT}>
							<WhiteButton onClick={handleCreateClick} mb="10px">
								Add another Incident Log
							</WhiteButton>
						</Link>
					</Box>

					{displayErfs.map((erf) => (
						<LegacyEncryptedRecordFormItem mb="20px" {...erf} key={erf.id} />
					))}
					{displayLogs.map((incidentLog) => (
						<IncidentLogItem mb="20px" {...incidentLog} key={incidentLog.id} />
					))}
				</Box>
			)}
		</Flex>
	);
};
