import React, { Context, createContext, Dispatch, useReducer } from "react";
import { PrivateRoutes } from "../../config/routes";
import { ActionMap, Step } from "../type";
import stepContactReducer, {
	STEP_CONTACT_TEXT,
	StepContactActions,
	StepContactState,
} from "./stepContact";
import stepWhereReducer, {
	STEP_WHERE_TEXT,
	StepWhereActions,
	StepWhereState,
} from "./stepWhere";
import stepWhoReducer, {
	IIdentifiers,
	STEP_WHO_TEXT,
	StepWhoActions,
	StepWhoState,
} from "./stepWho";
import { SUBMIT_FORM_TEXT } from "../../containers/SubmitForm";

export enum EntryGlobalActionTypes {
	INITIALIZE_ENTRY = "INITIALIZE_ENTRY",
	SET_ALL_STEP_COMPLETED = "SET_ALL_STEP_COMPLETED",
	SET_CACHED_ENTRY = "SET_CACHED_ENTRY",
	SET_EDIT_ENTRY_ID = "SET_EDIT_ENTRY_ID",
	SET_CONTINUING_DRAFT = "SET_CONTINUING_DRAFT",
}

export interface ICachedEntry {
	incidentState: string;
	perpIDs: IIdentifiers | undefined;
}

type EntryGlobalPayload = {
	[EntryGlobalActionTypes.INITIALIZE_ENTRY]: undefined;
	[EntryGlobalActionTypes.SET_ALL_STEP_COMPLETED]: undefined;
	[EntryGlobalActionTypes.SET_CACHED_ENTRY]: ICachedEntry;
	[EntryGlobalActionTypes.SET_EDIT_ENTRY_ID]: string;
	[EntryGlobalActionTypes.SET_CONTINUING_DRAFT]: undefined;
};

type EntryGlobalActions =
	ActionMap<EntryGlobalPayload>[keyof ActionMap<EntryGlobalPayload>];

export interface State {
	steps: {
		stepWhere: StepWhereState;
		stepWho: StepWhoState;
		stepContact: StepContactState;
		stepSubmit: Step;
	};
	editEntryID: string;
	cachedEditEntry: ICachedEntry;
	continuingDraft: boolean;
}

const initialState: State = {
	steps: {
		stepWhere: {
			completed: false,
			selectedState: "",
			route: PrivateRoutes.CREATE_WHERE,
			nextStepRoute: PrivateRoutes.CREATE_WHO,
			text: STEP_WHERE_TEXT,
			changesMade: false,
		},
		stepWho: {
			completed: false,
			identifiers: {
				twitter: [],
				facebook: [],
				linkedin: [],
				instagram: [],
				snapchat: [],
				tiktok: [],
				whatsapp: [],
				email: [],
				phone: [],
			},
			originalIdentifiers: {
				twitter: [],
				facebook: [],
				linkedin: [],
				instagram: [],
				snapchat: [],
				tiktok: [],
				whatsapp: [],
				email: [],
				phone: [],
			},
			route: PrivateRoutes.CREATE_WHO,
			nextStepRoute: PrivateRoutes.CREATE_CONTACT,
			text: STEP_WHO_TEXT,
			changesMade: false,
		},
		stepContact: {
			completed: false,
			name: "",
			phone: "",
			confirmPhone: "",
			email: "",
			confirmEmail: "",
			checked: {
				phone: false,
				email: false,
			},
			route: PrivateRoutes.CREATE_CONTACT,
			nextStepRoute: PrivateRoutes.CREATE_SUBMIT,
			text: STEP_CONTACT_TEXT,
		},
		stepSubmit: {
			completed: false,
			route: PrivateRoutes.CREATE_SUBMIT,
			nextStepRoute: PrivateRoutes.RESOURCES,
			text: SUBMIT_FORM_TEXT,
		},
	},
	editEntryID: "",
	cachedEditEntry: {
		incidentState: "",
		perpIDs: undefined,
	},
	continuingDraft: false,
};

export type EntryActions =
	| StepWhereActions
	| StepContactActions
	| StepWhoActions
	| EntryGlobalActions;

const EntryContext: Context<{
	state: State;
	dispatch: Dispatch<EntryActions>;
}> = createContext<{
	state: State;
	dispatch: Dispatch<EntryActions>;
}>({
	state: initialState,
	dispatch: () => null,
});

const mainReducer = (state: State, action: EntryActions) => {
	const {
		steps: { stepWhere, stepContact, stepWho, stepSubmit },
	} = state;

	switch (action.type) {
		case EntryGlobalActionTypes.INITIALIZE_ENTRY:
			return initialState;
		case EntryGlobalActionTypes.SET_EDIT_ENTRY_ID:
			return {
				...state,
				steps: {
					...state.steps,
					stepWhere: {
						...stepWhere,
						changesMade: false,
					},
					stepWho: {
						...stepWho,
						changesMade: false,
					},
					stepContact: {
						...stepContact,
					},
				},
				editEntryID: action.payload,
			};
		case EntryGlobalActionTypes.SET_CACHED_ENTRY:
			return {
				...state,
				cachedEditEntry: action.payload,
			};
		case EntryGlobalActionTypes.SET_ALL_STEP_COMPLETED:
			return {
				...state,
				steps: {
					...state.steps,
					stepWhere: {
						...stepWhere,
						completed: true,
					},
					stepWho: {
						...stepWho,
						completed: true,
					},
					stepContact: {
						...stepContact,
						completed: true,
					},
					stepSubmit: {
						...stepSubmit,
						completed: true,
					},
				},
			};
		case EntryGlobalActionTypes.SET_CONTINUING_DRAFT:
			return {
				...state,
				steps: {
					...state.steps,
					stepWhere: {
						...stepWhere,
						changesMade: false,
					},
					stepWho: {
						...stepWho,
						changesMade: false,
					},
					stepContact: {
						...stepContact,
					},
				},
				continuingDraft: true,
			};
		default:
			return {
				...state,
				steps: {
					stepWhere: stepWhereReducer(stepWhere, action as StepWhereActions),
					stepWho: stepWhoReducer(stepWho, action as StepWhoActions),
					stepContact: stepContactReducer(
						stepContact,
						action as unknown as StepContactActions,
					),
					stepSubmit,
				},
			};
	}
};

const EntryContextProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(mainReducer, initialState);

	return (
		<EntryContext.Provider value={{ state, dispatch }}>
			{children}
		</EntryContext.Provider>
	);
};

export { EntryContext, EntryContextProvider };
