import * as React from "react";
import { createContext, useState } from "react";

interface MainSidebarState {
	mainSidebarVisible: boolean;
	setMainSidebarVisible: (showSidebar: boolean) => void;
}

const initialState: MainSidebarState = {
	mainSidebarVisible: false,
	setMainSidebarVisible: () => {},
};

export const MainSidebarContext = createContext<MainSidebarState>(initialState);

interface Props {
	children?: React.ReactNode | React.ReactNode[];
}

export const MainSidebarContextProvider: React.FC<Props> = ({ children }) => {
	const [mainSidebarVisible, setMainSidebarVisible] = useState<boolean>(false);

	const showMainSidebarFunction = (showSidebar: boolean) => {
		setMainSidebarVisible(showSidebar);
	};

	return (
		<MainSidebarContext.Provider
			value={{
				mainSidebarVisible,
				setMainSidebarVisible: showMainSidebarFunction,
			}}
		>
			{children}
		</MainSidebarContext.Provider>
	);
};
