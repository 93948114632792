import React from "react";
import { TextProps, Text, Box } from "@chakra-ui/react";

const CampusRecord: React.FC<TextProps> = ({ ...props }) => (
	<Box fontSize="sm" mt="16px">
		<Text as="p" display="inline" {...props}>
			Trying to access your Callisto Campus record?{" "}
		</Text>
		<Text
			as="a"
			href="https://www.callistocampus.org/"
			target="_blank"
			color="brand.link"
			textDecor="underline"
			fontFamily="Avenir"
			fontWeight="bold"
		>
			Log in here
		</Text>
	</Box>
);

export default CampusRecord;
