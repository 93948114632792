import React from "react";

const ShieldIcon: React.FC = () => {
	const path =
		"M9.47206 0.246094C10.1606 0.246094 10.8445 0.246094 11.5331 0.246094C11.6877 0.259868 11.8423 0.282826 " +
		"11.9968 0.292009C13.8752 0.397613 15.7161 0.69147 17.496 1.30673C18.6858 1.71997 19.8147 2.24799 20.8077 " +
		"3.02395C20.9389 3.12497 20.9998 3.23516 20.9998 3.40964C20.9904 5.17737 20.9904 6.9451 20.9951 8.71283C20.9998 " +
		"9.98008 20.9155 11.2336 20.6438 12.4733C20.0114 15.3797 18.5219 17.804 16.3437 19.8426C14.709 21.3716 12.8447 " +
		"22.57 10.8445 23.5801C10.7415 23.6306 10.6478 23.6995 10.5541 23.7546C10.5213 23.7546 10.4932 23.7546 10.4604 " +
		"23.7546C10.3855 23.7087 10.3105 23.6536 10.2309 23.6123C8.63828 22.8087 7.1253 21.8859 5.73879 20.7701C4.32417 " +
		"19.6314 3.09692 18.3274 2.13198 16.7939C0.693943 14.5257 0.0475286 12.0417 0.0147394 9.39696C-0.00399723 7.39507 " +
		"0.0147394 5.38858 0.00537109 3.38668C0.00537109 3.23057 0.0522127 3.12038 0.178685 3.04232C0.675206 2.72551 " +
		"1.14831 2.36737 1.66825 2.09647C3.74333 1.01747 5.98705 0.53995 8.30571 0.337924C8.69449 0.305783 9.08328 " +
		"0.278234 9.47206 0.246094ZM9.42522 13.2584C8.6055 12.4503 7.83261 11.6881 7.05504 10.9213C6.60536 10.4806 " +
		"6.19315 10.4806 5.73879 10.9259C5.42026 11.2382 5.09706 11.5504 4.77853 11.8672C4.4038 12.2391 4.4038 12.6753 " +
		"4.77385 13.038C6.1182 14.3604 7.46724 15.6781 8.8116 16.9959C9.18633 17.3632 9.64069 17.3678 10.0061 " +
		"17.0005C12.3154 14.6772 14.62 12.3493 16.9199 10.0214C17.2759 9.66327 17.2618 9.21789 16.9011 8.85976C16.5826 " +
		"8.54294 16.2641 8.23072 15.9409 7.9185C15.4818 7.46853 15.065 7.46853 14.6153 7.92768C12.9665 9.60817 11.3129 " +
		"11.2887 9.66412 12.9692C9.59385 13.0518 9.52359 13.1436 9.42522 13.2584Z";

	return (
		<svg
			width="21"
			height="24"
			viewBox="0 0 21 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d={path} fill="white" />
		</svg>
	);
};

export default ShieldIcon;
