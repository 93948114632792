import React from "react";
import { Text, TextProps } from "@chakra-ui/react";

type ParagraphProps = {
	props?: TextProps;
	mb?: string;
	mt?: string;
	children: React.ReactNode;
};

export const Paragraph: React.FC<ParagraphProps> = ({
	props,
	mb,
	mt,
	children,
}: ParagraphProps) => (
	<Text mt={mt ? mt : "30px"} mb={mb ? mb : ""} as="p" {...props}>
		{children}
	</Text>
);
