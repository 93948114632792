import * as React from "react";
import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react";

interface Props extends ButtonProps {
	buttonColor: string;
}

const Button: React.FC<Props> = ({
	buttonColor,
	children,
	...restProps
}: Props) => (
	<ChakraButton
		backgroundColor={buttonColor}
		color="brand.white"
		_hover={{
			background: buttonColor,
		}}
		_active={{
			background: buttonColor,
		}}
		padding="15px 30px"
		height="auto"
		fontSize="sm"
		minWidth="200px"
		borderRadius="100px"
		fontFamily="Avenir"
		fontWeight="bold"
		{...restProps}
	>
		{children}
	</ChakraButton>
);

export default Button;
