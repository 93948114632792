import * as React from "react";
import { useContext } from "react";
import { Box, Flex, Image } from "@chakra-ui/react";

import MobileIcon from "../../../../assets/CallistoVault_logo_stacked.png";
import BarIcon from "../../../../assets/bars-solid.svg";
import { Link } from "react-router-dom";
import { PrivateRoutes } from "../../../config/routes";
import Button from "../../Button";
import { MainSidebarContext } from "../../../contexts/MainSidebarContext";

const MainHeader: React.FC = () => {
	const { setMainSidebarVisible, mainSidebarVisible } =
		useContext(MainSidebarContext);
	const handleBarClick = () => {
		setMainSidebarVisible(!mainSidebarVisible);
	};

	return (
		<Box
			height="50px"
			display={{ base: "block", md: "none" }}
			as="header"
			position="fixed"
			left="0px"
			top="0px"
			width="100%"
			backgroundColor="brand.white"
			zIndex={10}
		>
			<Flex float="left" height="50px" alignItems="center" px="24px" py="20px">
				<Link to={PrivateRoutes.DASHBOARD}>
					<Image
						src={MobileIcon as string}
						float="left"
						width="87px"
						alt="Callisto Vault logo"
					/>
				</Link>
			</Flex>
			{mainSidebarVisible ? (
				<Button
					buttonColor="brand.primary"
					float="right"
					minWidth="auto"
					onClick={handleBarClick}
					padding="0 !important"
					marginRight="11px"
					marginTop="11px"
					height="28px"
					width="28px"
					borderRadius="10px"
				>
					&lt;
				</Button>
			) : (
				<Image
					src={BarIcon as string}
					height="35px"
					float="right"
					marginRight="10px"
					marginTop="7px"
					cursor="pointer"
					onClick={handleBarClick}
					alt="menu icon"
				/>
			)}
		</Box>
	);
};

export default MainHeader;
