/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return */
import React, { forwardRef } from "react";
import { Checkbox as ChakraCheckbox, CheckboxProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import theme from "../styles/theme";

const Checkbox = forwardRef<
	HTMLInputElement,
	CheckboxProps & { children: string }
>(({ children, ...props }, ref) => (
	<CheckboxWrapper ref={ref} {...props}>
		{children}
	</CheckboxWrapper>
));

Checkbox.displayName = "Checkbox";

export default Checkbox;

const CheckboxWrapper = styled(ChakraCheckbox)`
  align-items: flex-start;
  .chakra-checkbox__control {
    width: 27px;
    height: 27px;
    border: 1px solid ${theme.colors.brand.primary};
    border-radius: 5px;
    background-color: ${(props) =>
			props.backgroundColor ?? theme.colors.brand.white};
  }
  .chakra-checkbox__control[data-focus] {
    box-shadow: none !important;
  }
  .chakra-checkbox__control[data-checked] {
    background-color: ${theme.colors.brand.primary};
    &:hover {
      background-color: ${theme.colors.brand.primary};
    }
  }
  .chakra-checkbox__label {
    font-size: ${theme.fontSizes.sm};
  }
`;
