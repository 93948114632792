import React from "react";
import { Box, ListItem, UnorderedList } from "@chakra-ui/layout";

import SubHeader from "../../components/SubHeader";
import Textarea from "../../components/Textarea";
import { PdfTextarea } from "../../components/PdfTextarea";
import { OrfQuestion } from "../../components/OrfQuestion";
import HeaderText from "../../components/HeaderText";

interface ElectronicEvidenceProps {
	electronicInfo?: string;
	pdf?: boolean;
	textWidth?: string;
}

const ElectronicEvidence: React.FC<ElectronicEvidenceProps> = ({
	electronicInfo = undefined,
	pdf = false,
	textWidth,
}: ElectronicEvidenceProps) => (
	<Box width={textWidth}>
		{pdf && (
			<HeaderText fontSize="md" pdf={pdf}>
				Electronic Evidence
			</HeaderText>
		)}
		<SubHeader
			mb="0px"
			style={{ fontWeight: "bold", lineHeight: 1, letterSpacing: "0.05px" }}
		>
			Electronic Evidence Includes:
		</SubHeader>
		<UnorderedList mt={pdf ? "5px" : ""}>
			<ListItem fontSize="sm">Emails, photos, videos</ListItem>
			<ListItem fontSize="sm">Text messages</ListItem>
			<ListItem fontSize="sm">Screenshots</ListItem>
			<ListItem fontSize="sm">
				Social media interactions (Twitter, Facebook, Snapchat, Instagram, etc.)
			</ListItem>
		</UnorderedList>
		<SubHeader
			mb="0px"
			style={{ fontWeight: "bold", lineHeight: 1, letterSpacing: "0.05px" }}
		>
			Preserving Electronic Evidence:
		</SubHeader>
		<UnorderedList mt={pdf ? "5px" : ""}>
			<ListItem fontSize="sm">
				Save copies of electronic evidence on a device that only you have access
				to, including screenshots of any relevant electronic communication or
				interaction.
			</ListItem>
			<ListItem fontSize="sm">
				Don’t delete any messages, even if you have a screenshot.
			</ListItem>
			<ListItem fontSize="sm">
				Take screenshots in a way that they show the flow of the conversation
				(repeating the last line on the top of the next screen shot).
			</ListItem>
		</UnorderedList>
		<SubHeader pdf={pdf}>
			<OrfQuestion pdf={pdf}>
				What types of electronic evidence do you have now (or think might
				exist)?
			</OrfQuestion>
		</SubHeader>
		{pdf && !electronicInfo && <Textarea rows={7} height="auto" />}
		{pdf && electronicInfo && <PdfTextarea>{electronicInfo}</PdfTextarea>}
	</Box>
);

export default ElectronicEvidence;
