import { useState } from "react";

export interface INotification {
	type: "error" | "success";
	message: string;
}

export const useNotification = (): {
	notification: INotification;
	setNotification: (notification: INotification) => void;
} => {
	const [notification, setNotification] = useState<INotification>({
		type: "error",
		message: "",
	});

	return {
		notification,
		setNotification,
	};
};
