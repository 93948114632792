import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useVerifyRecoveryEmailToken } from "../../service/hooks/auth";
import { PublicRoutes } from "../../config/routes";

export const VerifyRecoveryEmailToken: React.FC = () => {
	const { token: input } = useParams<{ token: string }>();
	const toast = useToast();
	const navigate = useNavigate();
	const { mutateAsync: verifyRecoveryEmailToken } =
		useVerifyRecoveryEmailToken();

	useEffect(() => {
		const verify = async () => {
			try {
				// The input might have had dummy query parameters added by the survivor's email system. We strip them off here.
				const inputParts = input.split("&");
				const token = inputParts[0];
				const { encryptedData, legacySetup } = await verifyRecoveryEmailToken({
					token,
				});
				navigate(PublicRoutes.VERIFY_RECOVERY_EMAIL, {
					state: {
						encryptedData,
						legacySetup,
					},
				});
			} catch (e) {
				toast({
					title: (e as Error).message,
					status: "error",
					isClosable: true,
					position: "top",
					duration: 10000,
				});
				navigate(PublicRoutes.LOGIN);
			}
		};
		void verify();
	}, []);

	return <LoadingIndicator />;
};
