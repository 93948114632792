import * as React from "react";

const PersonIcon: React.FC = () => {
	// eslint-disable-next-line max-len
	const bodyPath =
		"M10.9973 24.0001C8.66736 23.9896 6.36375 23.7761 4.11274 23.1509C3.05034 22.854 2.00899 22.4841 1.10437 21.8329C0.410133 21.338 -0.0947677 20.7337 0.0156794 19.8064C0.0682732 19.3741 0.0893107 18.9365 0.194498 18.5197C1.02022 15.2846 3.0819 13.2946 6.36375 12.5549C6.47946 12.5288 6.64776 12.5601 6.74769 12.6278C7.95735 13.4613 9.29323 13.8624 10.7659 13.8833C12.0386 13.9041 13.2641 13.727 14.4001 13.1331C14.6473 13.0029 14.8997 12.8726 15.1154 12.7059C15.331 12.534 15.5361 12.5184 15.7938 12.5705C18.9337 13.2165 21.4161 15.816 21.8895 18.9781C21.9315 19.2542 21.9473 19.5303 21.9841 19.8064C22.0683 20.5306 21.7685 21.088 21.2425 21.5516C20.4799 22.2184 19.5595 22.6091 18.6129 22.9217C16.7668 23.5364 14.8577 23.8229 12.9169 23.9271C12.2806 23.9584 11.6389 23.9323 10.9973 23.9323C10.9973 23.9584 10.9973 23.9792 10.9973 24.0001Z";
	// eslint-disable-next-line max-len
	const headPath =
		"M11.0131 2.62488e-05C14.3423 0.031283 17.0036 2.71937 16.8668 6.00654C16.7721 8.30912 15.736 10.1793 13.8427 11.5234C11.9914 12.8361 9.78768 12.784 7.95741 11.3827C6.14818 10.0022 5.16993 8.14242 5.1226 5.87109C5.05423 2.65164 7.77333 -0.0312305 11.0131 2.62488e-05Z";
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 22 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
				<path fill="#00375B" d={bodyPath} />
				<path fill="#00375B" d={headPath} />
			</g>
		</svg>
	);
};

export default PersonIcon;
