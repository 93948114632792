import * as React from "react";
import { createContext, useState } from "react";

interface IState {
	isEntrySidebarVisible: boolean;
	showEntrySidebar: (showSidebar: boolean) => null;
}

const initialState: IState = {
	isEntrySidebarVisible: false,
	showEntrySidebar: () => null,
};

export const SidebarContext = createContext<IState>(initialState);

interface Props {
	children?: React.ReactNode | React.ReactNode[];
}

export const SidebarContextProvider: React.FC<Props> = ({ children }) => {
	const [isEntrySidebarVisible, showEntrySidebar] = useState<boolean>(false);

	const showEntrySidebarFunction = showEntrySidebar as (
		showSidebar: boolean,
	) => null;

	return (
		<SidebarContext.Provider
			value={{
				isEntrySidebarVisible,
				showEntrySidebar: showEntrySidebarFunction,
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
};
