import * as React from "react";

const HomeIcon: React.FC = () => {
	const path =
		"M16.125 14.2496V9.65717C16.125 8.58975 16.125 8.05603 15.9884 7.56386C15.8674 7.12786 15.6684 6.71736 " +
		"15.4012 6.35221C15.0996 5.94002 14.6807 5.60931 13.8429 4.94789L13.8429 4.94788L12.7179 4.05972C11.3896 3.01112 " +
		"10.7255 2.48683 9.99054 2.28553C9.34212 2.10795 8.65788 2.10795 8.00946 2.28553C7.27446 2.48683 6.61035 3.01112 " +
		"5.28214 4.05972L4.15713 4.94788C3.31933 5.6093 2.90043 5.94001 2.59878 6.35221C2.33156 6.71736 2.13263 7.12786 " +
		"2.01161 7.56386C1.875 8.05603 1.875 8.58975 1.875 9.65717V14.2496C1.875 14.9485 1.875 15.2979 1.98918 " +
		"15.5736C2.14142 15.9411 2.43343 16.2331 2.80097 16.3854C3.07663 16.4996 3.42609 16.4996 4.125 " +
		"16.4996H4.78125C4.8683 16.4996 4.91182 16.4996 4.9486 16.4978C5.71916 16.4599 6.33534 15.8437 6.37319 " +
		"15.0732C6.375 15.0364 6.375 14.9929 6.375 14.9058V12.7496C6.375 11.2998 7.55025 10.1246 9 10.1246C10.4497 10.1246 " +
		"11.625 11.2998 11.625 12.7496V14.9058C11.625 14.9929 11.625 15.0364 11.6268 15.0732C11.6647 15.8437 12.2808 " +
		"16.4599 13.0514 16.4978C13.0882 16.4996 13.1317 16.4996 13.2188 16.4996H13.875C14.5739 16.4996 14.9234 16.4996 " +
		"15.199 16.3854C15.5666 16.2331 15.8586 15.9411 16.0108 15.5736C16.125 15.2979 16.125 14.9485 16.125 14.2496Z";

	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d={path} fill="currentColor" />
		</svg>
	);
};

export default HomeIcon;
