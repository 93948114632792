import React from "react";
import { Box, Text, useToast } from "@chakra-ui/react";

import Button from "../../components/Button";
import { useVerifyEmail } from "../../service/hooks/auth";
import Notification from "../../components/Notification";

interface Props {
	email: string;
}

const ResendVerify: React.FC<Props> = ({ email }: Props) => {
	const toast = useToast();
	const { mutateAsync: verifyEmail, isLoading } = useVerifyEmail();

	const handleResend = async () => {
		try {
			await verifyEmail({ email });
			toast({
				position: "top",
				status: "success",
				title: `We've resent your email.`,
				isClosable: true,
			});
		} catch (e) {
			toast({
				position: "top",
				status: "error",
				title:
					"There was a problem re-sending your invite email. Please try again in a bit.",
			});
		}
	};

	return (
		<Box textAlign="center" as="form">
			<Notification
				notification={{
					type: "success",
					message: "Email has been sent",
				}}
			/>
			<Box
				maxWidth="850px"
				backgroundColor="brand.white"
				padding={["20px 20px", "30px 30px", "50px 60px"]}
			>
				<Text as="h1" fontSize="xl" fontFamily="Avenir" lineHeight="60px">
					Almost done! Let&apos;s verify your school email
				</Text>
				<Box>
					<Text mt="40px" lineHeight="28px">
						We sent a verification link to your school email address.
					</Text>
					<Text my="17px">
						<b>Did not receive the email?</b>
					</Text>
					<Text lineHeight="28px">
						Your verification email may take up to 5 minutes to arrive in your
						inbox. Be sure to also check your spam folder.
					</Text>
					<Button
						buttonColor="brand.primary"
						onClick={handleResend}
						mt="25px"
						isLoading={isLoading}
					>
						Resend verification email
					</Button>
				</Box>
			</Box>
			<Text my="16px">
				We&apos;re here to help however we can. Email&nbsp;
				<Text
					as="a"
					href="mailto:support@projectcallisto.org"
					color="brand.link"
					textDecor="underline"
					fontWeight="600"
				>
					support@projectcallisto.org
				</Text>
				&nbsp; with any questions.
			</Text>
		</Box>
	);
};

export default ResendVerify;
