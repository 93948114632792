import React, { ChangeEvent, FormEvent, useContext, useState } from "react";
import { Box, Select, Text, useToast } from "@chakra-ui/react";

import HeaderText from "../../components/HeaderText";
import Map from "./Map";
import { usStates } from "./constant";
import SubHeader from "../../components/SubHeader";
import AnimatedBox from "../../components/layout/AnimatedBox";
import {
	EntryContext,
	EntryGlobalActionTypes,
} from "../../contexts/EntryContext";
import Button from "../../components/Button";
import ButtonLink from "../../components/ButtonLink";
import { StepWhereActionTypes } from "../../contexts/EntryContext/stepWhere";
import MainCard from "../../components/MainCard";
import WhiteButton from "../../components/layout/WhiteButton";
import { survivorClient } from "../../service/backend";
import { entryData } from "../../../../../../lib/data";
import { PrivateRoutes } from "../../config/routes";
import { useNavigate } from "react-router-dom";

const CreateWhere: React.FC = () => {
	const navigate = useNavigate();
	const {
		state: {
			steps: {
				stepWhere: { selectedState: initialValue, nextStepRoute },
				stepWho,
			},
			editEntryID,
		},
		dispatch,
	} = useContext(EntryContext);
	const [toggle, setToggle] = useState<boolean>(true);
	const [selectedState, setSelectedState] = useState<string>(initialValue);
	const toast = useToast();

	const handleToggleClick = () => {
		setToggle(!toggle);
	};

	const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
		submitAction(e.currentTarget.value);
	};

	const onSubmit = async (event: FormEvent<any>) => {
		event.preventDefault();
		await handleSaveDraft(event);
		navigate(PrivateRoutes.MATCHING_SYSTEM);
	};

	const handleSaveDraft = async (event: FormEvent<any>) => {
		event.preventDefault();
		const draftEntry: entryData = {
			id: editEntryID ?? undefined,
			userID: survivorClient.userID,
			incidentState: usStates[selectedState],
			perpIDs: stepWho.identifiers,
		};

		const draftId = await survivorClient.saveDraftEntry(draftEntry);
		dispatch({
			type: EntryGlobalActionTypes.SET_EDIT_ENTRY_ID,
			payload: draftId,
		});

		toast({
			title: "Your entry was saved as a draft.",
			status: "success",
			position: "top",
		});
	};

	const handleClickNext = (event: FormEvent<any>) => {
		event.preventDefault();
		navigate(nextStepRoute);
	};

	const handleAbroadClick = () => {
		submitAction("Outside of the U.S.");
		navigate(nextStepRoute);
	};

	const submitAction = (_state: string) => {
		setSelectedState(_state);
		dispatch({
			type: StepWhereActionTypes.SET_SELECTED_STATE,
			payload: _state,
		});
	};

	return (
		<AnimatedBox>
			<MainCard>
				<Box as="form" onSubmit={onSubmit} id="entry_form">
					<HeaderText>In what state did the incident occur?</HeaderText>
					<SubHeader>
						In the event that we find another survivor with the same
						perpetrator, this will help us pair you with a Legal Options
						Counselor who knows the laws of the state where the incident
						occurred.
					</SubHeader>
					<Text as="div" my="16px" fontSize="sm">
						Select a state or{" "}
						<ButtonLink onClick={handleToggleClick} textDecor="underline">
							{toggle
								? "enter a state or U.S. territory manually"
								: "enter a state from the map"}
						</ButtonLink>
					</Text>
					{toggle && (
						<Map
							selectedState={selectedState}
							setSelectedState={submitAction}
						/>
					)}
					{!toggle && (
						<Select
							placeholder="Select state"
							bgColor="brand.white"
							fontSize="sm"
							textTransform="capitalize"
							value={selectedState}
							onChange={handleSelectChange}
							mb="60px"
							borderColor="brand.primary"
							borderRadius="0px"
							_focus={{ borderColor: "brand.primary" }}
						>
							{Object.keys(usStates).map((state) => (
								<option value={state} key={state}>
									{usStates[state]}
								</option>
							))}
						</Select>
					)}
					{selectedState && (
						<Button
							buttonColor="brand.primary"
							width="140px"
							margin="0px 20px 20px 0px"
							type="button"
							onClick={handleClickNext}
						>
							Next
						</Button>
					)}
					<br />
					<ButtonLink onClick={handleAbroadClick} textDecor="underline">
						This happened outside of the U.S. and its territories
					</ButtonLink>
					<WhiteButton
						width="100%"
						margin="20px 20px 20px 0px"
						type="button"
						onClick={handleSaveDraft}
					>
						Save My Entry as a Draft
					</WhiteButton>
				</Box>
			</MainCard>
		</AnimatedBox>
	);
};

export default CreateWhere;
