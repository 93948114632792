import { FieldData } from "../../../../../../lib/questionnaire/formFunctions";
import { PrivateRoutes } from "../../config/routes";
import { Step } from "../../contexts/type";

export const PRESTEPS = ["whoWhat"];
export const POSTSTEPS = ["summary"];

export interface IncidentLogQuestionFieldData extends FieldData {
	additionalInfo?: string;
	newQuestion?: boolean;
}

export interface IncidentLogStep extends Step {
	intro?: string;
	questions: IncidentLogQuestionFieldData[];
	previousStepRoute?: string;
}

const whenQuestions: IncidentLogQuestionFieldData[] = [
	{
		key: "whenOccurred",
		label: "When did the event(s) occur?",
		additionalInfo:
			"Examples: exact or approximate date and time of all occurrences, season, time of day, etc.",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
	{
		key: "otherDetailsDate",
		label: "What other details do you remember about the date(s)?",
		additionalInfo:
			"Examples: holidays, day of the week, games, school or social events around that time, etc.",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
];

const whereQuestions: IncidentLogQuestionFieldData[] = [
	{
		key: "whereHappened",
		label: "Where did it happen?",
		additionalInfo:
			"Examples: exact address, in a on-campus dorm, at a bar, in an alley, " +
			"while studying abroad, in a bedroom of a house, at a hotel/airbnb, vehicle, etc",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
	{
		key: "locationDetails",
		label: "What details do you remember about the location?",
		additionalInfo:
			"Examples: If you don’t remember the exact location, other details can" +
			" be helpful to document. area of campus, intersection, building number, neighborhood," +
			" buildings or trees nearby, colors you remember, temperature of the location, noise level, etc",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
];

const peopleQuestions: IncidentLogQuestionFieldData[] = [
	{
		key: "whoWitnessed",
		label:
			"Who might have witnessed events before, during, or after your sexual assault? If known, it can " +
			"be helpful to describe who they are, and what they might have seen or heard.",
		additionalInfo:
			"Examples: how many people, what they may have seen or heard, their relationship to " +
			"you, where they may have been standing or sitting, how physically close to you they were. Who you " +
			"interacted with before or after the incident.",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
	{
		key: "whoYouInteractedWith",
		label: "Who did you interact with right before or after the incident?",
		additionalInfo:
			"This could be people who were at the scene of the incident or nearby, who saw you " +
			"or the offender leave, or who helped you call for help. Even if you don’t remember names, it " +
			"can be helpful to give descriptions of the person(s) or their role (ie, store clerk).",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
	{
		key: "whoYouTold",
		label: "If you told someone about the incident, describe your interaction.",
		additionalInfo:
			"For example, who you told, what you told them, when you told them, how you told " +
			"them (on the phone, in person, over text, etc), their relationship to you or the offender, etc.",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
	{
		key: "whatTheOffenderSaid",
		label:
			"What information, if any, do you have about what the offender may have said about the incident?",
		additionalInfo:
			"For example, who do you think the offender told, when did they disclose the incident, " +
			"how did they disclose (text, email, social media, in person, etc), what the offender’s relationship " +
			"was with the person.",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
];

const consentQuestions: IncidentLogQuestionFieldData[] = [
	{
		key: "notOkayBecause",
		label: "What happened to me was not okay because:",
		additionalInfo:
			"Check any that apply. This list is not exhaustive and your experience may have been different.",
		type: "multiselect",
		options: [
			{
				value: "notAsked",
				label: "I was not asked whether I wanted to do it",
			},
			{
				value: "didNotSayYesToSexualInteraction",
				label:
					'I did not say "yes" (or a word that means "yes") verbally to the sexual interaction at all',
			},
			{
				value: "didNotSayYesToParticularSexualContact",
				label:
					'I did not say "yes" (or a word that means "yes") verbally to the particular type of sexual contact',
			},
			{
				value: "notAbleToReact",
				label:
					"I was not able to react when it started happening (because it happened quickly, or I was in shock, " +
					"or I was confused about what was going on)",
			},
			{
				value: "gaveNonVerbalCues",
				label:
					"I gave non verbal cues that I didn't want it. (This could include a distressed look, a push, " +
					"a shaking of the head, etc)",
			},
			{
				value: "saidNo",
				label: 'I said no (or a word that means "no") to a part of it.',
			},
			{
				value: "didNotWantIt",
				label: "I didn't want it (or at least some part of it)",
			},
			{
				value: "notAbleToConsent",
				label:
					"I was not able to consent (examples: under 18, asleep, intoxicated)",
			},
			{
				value: "doNotReallyRemember",
				label:
					"I don't really remember what happened, potentially because I was intoxicated or experienced " +
					"head trauma, which is a sign that I couldn't or didn't consent",
			},
			{
				value: "wasThreatenedCoercedOrPressured",
				label:
					"I was threatened, coerced, or pressured (examples: repeatedly pestered over and over/or, " +
					"asked by someone who is in a position of power over me)",
			},
			{
				value: "wasAfraidForMySafety",
				label: "I was afraid for my safety",
			},
			{
				value: "wasAfraidOfConsequences",
				label:
					"I was afraid of other consequences if I didn't say yes (getting a bad grade, being shunned, " +
					"being shamed, being taunted, etc.)",
			},
			{
				value: "other",
				question: {
					key: "other",
					value: "other",
					label: "Other",
					type: "text",
				},
			},
		],
		newQuestion: true,
	},
	{
		key: "otherDetailsAboutNotConsenting",
		label: "What other details do you have about not consenting?",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
];

const incidentQuestions: IncidentLogQuestionFieldData[] = [
	{
		key: "whatHappened",
		label: "What happened?",
		additionalInfo:
			"Include anything you are able to remember around what you felt, saw, heard, smelled, " +
			"tasted, or anything you can’t forget about your experience or experiences with the offender(s).",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
];

const offenderQuestions: IncidentLogQuestionFieldData[] = [
	{
		key: "howManyOffenders",
		label: "How many offenders were there?",
		additionalInfo:
			"It's okay if you are not sure, just put what you can remember",
		type: "text",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
	{
		key: "relationshipToYou",
		label:
			"At the time of this incident, what was the person's/were the persons' relationship to you?",
		additionalInfo: "Mark all that apply",
		type: "multiselect",
		options: [
			{
				value: "involvedWithAtTheTime",
				label: "Someone I was involved or intimate with at the time",
			},
			{
				value: "involvedWithPreviously",
				label: "Someone I previously had been involved or intimate with",
			},
			{
				value: "teacher",
				label: "Teacher",
			},
			{
				value: "advisor",
				label: "Advisor",
			},
			{
				value: "someoneIWasTeachingOrAdvising",
				label: "Someone I was teaching or advising",
			},
			{
				value: "residentialStaff",
				label: "Live-in residential staff",
			},
			{
				value: "coachOrTrainer",
				label: "Coach or Trainer",
			},
			{
				value: "bossOrSupervisor",
				label: "Boss or Supervisor",
			},
			{
				value: "coworker",
				label: "Co-worker",
			},
			{
				value: "friend",
				label: "Friend",
			},
			{
				value: "classmate",
				label: "Classmate",
			},
			{
				value: "acquaintance",
				label: "Someone I know or recognize, but not a friend",
			},
			{
				value: "unknownPerson",
				label: "Did not know or recognize this person",
			},
		],
		newQuestion: true,
	},
	{
		key: "offenderName",
		label:
			"What is/are the name(s) of the offender(s)? (If known. It also can be helpful to add nicknames.)",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
	{
		key: "offenderDescription",
		label: "Please describe the offender.",
		additionalInfo:
			"Examples: race, gender, nationality, any physical characteristics (hair color, age, " +
			"identifiable marks, tattoos, clothing, moles or birthmarks)",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
	{
		key: "otherOffenderInformation",
		label: "What other information do you know about the offender(s)?",
		additionalInfo:
			"Examples: where they live, cell phone number, social media handles, if they were " +
			"intoxicated and on what and to what level, what job they have, if you’ve seen them before, " +
			"how you know them",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
	{
		key: "offenderBehavior",
		label:
			"Was there anything particular about the way the offender acted or behaved that you are able to describe?",
		additionalInfo:
			"This could be either before, during, or after the assault. Describe anything you are able to remember.",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
	{
		key: "peopleTheOffenderWasWith",
		label:
			"If the offender was with anyone else either at the time of the incident or directly before or after, " +
			"describe what you know about these people.",
		additionalInfo:
			"Examples: the relationship with the offender, description, etc.",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
];

const evidenceQuestions: IncidentLogQuestionFieldData[] = [
	{
		key: "typesOfEvidence",
		label: "What types of evidence do you have now (or think might exist)?",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
	{
		key: "evidenceLocation",
		label: "Where did you store this evidence?",
		type: "textarea",
		layout: {
			insertBreak: true,
		},
		newQuestion: true,
	},
];

const notesQuestions: IncidentLogQuestionFieldData[] = [
	{
		key: "notes",
		label: "",
		type: "textarea",
		layout: {
			insertBreak: true,
			className: "tall",
		},
		newQuestion: true,
	},
];

export const incidentLogSections: Record<string, IncidentLogStep> = {
	whoWhat: {
		intro: `<p>This Incident Log is encrypted – as such, no one can see what you write until you share
      the Incident Log with them or they gain access to your password. Callisto has no way of accessing
       your Incident Log.</p>

      <p>However, if there is legal action related to your assault, the prosecutor, the Title IX
       investigator, the offender, their attorneys, and others involved in an investigation might
        be able to read what you write here by legally compelling you, the people you shared this
        form with, or anyone else with access to this form to provide it. Simply stated, a judge
        may order that you share the Incident Log in a criminal or civil hearing. If that happens,
        any inconsistencies between what you write down here and later say is true might be used against 
        you. If you think you will report to authorities, only write down what you are certain of and 
        okay with sharing if they are able to read this later. Leave as many questions as you’d like blank.</p>

      <p>The safest way to document what happened is by talking to an attorney. Nothing in this form 
      is legal advice. Contact an attorney for legal advice and to learn more about your options.</p>`,
		questions: [],
		text: "Who Can See This/What To Write",
		route: PrivateRoutes.ILOG_WHO_WHAT,
		nextStepRoute: PrivateRoutes.ILOG_WHEN,
		completed: false,
	},
	when: {
		questions: whenQuestions,
		text: "When",
		route: PrivateRoutes.ILOG_WHEN,
		previousStepRoute: PrivateRoutes.ILOG_WHO_WHAT,
		nextStepRoute: PrivateRoutes.ILOG_WHERE,
		completed: false,
	},
	where: {
		questions: whereQuestions,
		text: "Where",
		route: PrivateRoutes.ILOG_WHERE,
		previousStepRoute: PrivateRoutes.ILOG_WHEN,
		nextStepRoute: PrivateRoutes.ILOG_PEOPLE,
		completed: false,
	},
	people: {
		questions: peopleQuestions,
		text: "People Who Know or Might Know",
		route: PrivateRoutes.ILOG_PEOPLE,
		previousStepRoute: PrivateRoutes.ILOG_WHERE,
		nextStepRoute: PrivateRoutes.ILOG_CONSENT,
		completed: false,
	},
	consent: {
		questions: consentQuestions,
		text: "Consent",
		intro: `<p class="heavy">Sexual contact without your consent is never okay.</p>
      <p>Just because you didn't say no, doesn&apos;t 
      mean you gave consent. You can consent to one thing without consenting to another thing, and you can 
      withdraw consent at any time. Some people are unsure if their experience was sexual assault so we have
      provided a list of common ways that survivors have reported that they did not give consent.</p>`,
		route: PrivateRoutes.ILOG_CONSENT,
		previousStepRoute: PrivateRoutes.ILOG_PEOPLE,
		nextStepRoute: PrivateRoutes.ILOG_INCIDENT,
		completed: false,
	},
	incident: {
		questions: incidentQuestions,
		text: "Incident(s)",
		route: PrivateRoutes.ILOG_INCIDENT,
		previousStepRoute: PrivateRoutes.ILOG_CONSENT,
		nextStepRoute: PrivateRoutes.ILOG_OFFENDERS,
		completed: false,
	},
	offenders: {
		questions: offenderQuestions,
		text: "Offender(s)",
		route: PrivateRoutes.ILOG_OFFENDERS,
		previousStepRoute: PrivateRoutes.ILOG_INCIDENT,
		nextStepRoute: PrivateRoutes.ILOG_EVIDENCE,
		completed: false,
	},
	evidence: {
		questions: evidenceQuestions,
		text: "Evidence",
		intro: `<p>If you choose to report what happened to your school, the police, the press, or in a lawsuit,
      they may ask you for electronic and/or physical evidence of what happened. If you don't have these
      things, it doesn't mean that what happened is any less real.</p>

      <p class='titlep'>Electronic Evidence Includes:
      <ul class='ul'>
        <li>Emails, Photos, Videos</li>
        <li>Text messages</li>
        <li>Screenshots</li>
        <li>Social media interactions (Twitter/X, Facebook, Snapchat, Instagram, etc.)</li>
        <li>Documents on the computer</li>
        <li>Internet search history</li>
      </ul>
      </p>

      <p class='titlep'>Preserving Electronic Evidence:
      <ul class='ul'>
        <li>Do not alter messages and/or documents in any way.</li>
        <li>Save copies of electronic evidence on a USB drive that only you have access to, including
        screenshots of any relevant electronic communication or interaction.</li>
        <li>Don't delete any messages, even if you have a screenshot.</li>
        <li>Take screenshots in a way that they show the flow of the conversation (repeating the
        last line on the top of the next screenshot).</li>
      </ul>
      </p>

      <p class='titlep'>Physical Evidence Includes:
      <ul class='ul'>
        <li>Clothing</li>
        <li>Bedsheets</li>
        <li>Condoms</li>
        <li>Documentation of injuries (photos, medical reports)</li>
        <li>Any evidence collected as part of forensic exam ("rape kit")</li>
      </ul>
      </p>

      <p class='titlep'>Preserving Physical Evidence
      <ul class='ul'>
        <li>Place each item of potential evidence in its own paper bag (plastic bags can eliminate DNA)
        and store them someplace only you can access (i.e. out of common spaces, away from roommates)</li>
      </ul>
      </p>`,
		route: PrivateRoutes.ILOG_EVIDENCE,
		previousStepRoute: PrivateRoutes.ILOG_OFFENDERS,
		nextStepRoute: PrivateRoutes.ILOG_NOTES,
		completed: false,
	},
	notes: {
		questions: notesQuestions,
		text: "Notes",
		intro:
			"<p>We invite you to use this space for any notes you would like to keep about your incident, the " +
			"reporting process (Title IX & law enforcement), medical services, mental health services, and/or " +
			"legal processes (LOC, criminal and/or civil case, etc.).</p>",
		route: PrivateRoutes.ILOG_NOTES,
		previousStepRoute: PrivateRoutes.ILOG_EVIDENCE,
		nextStepRoute: PrivateRoutes.ILOG_SUMMARY,
		completed: false,
	},
	summary: {
		questions: [],
		text: "Summary",
		route: PrivateRoutes.ILOG_SUMMARY,
		previousStepRoute: PrivateRoutes.ILOG_NOTES,
		nextStepRoute: "",
		completed: false,
	},
};
