import React, { ChangeEvent, useContext } from "react";
import { IIncidentsFormProps } from "./index";
import HeaderText from "../../components/HeaderText";
import SubHeader from "../../components/SubHeader";
import Textarea from "../../components/Textarea";
import { PdfTextarea } from "../../components/PdfTextarea";
import { OrfQuestion } from "../../components/OrfQuestion";
import { OrfContext } from "../../contexts/OrfContext";
import { UseFormRegister } from "react-hook-form";

interface IncidentsPageContentProps {
	values: IIncidentsFormProps;
	pdf?: boolean;
	register: UseFormRegister<IIncidentsFormProps>;
	textWidth?: string;
}

export const OrfIncidentsPageContent: React.FC<IncidentsPageContentProps> = ({
	values,
	register,
	pdf = false,
	textWidth,
}: IncidentsPageContentProps) => {
	const { setModified } = useContext(OrfContext);
	const { onChange } = register("whatHappened");

	return (
		<div style={{ width: textWidth }}>
			<HeaderText pdf={pdf}>What happened?</HeaderText>

			<SubHeader pdf={pdf}>
				<OrfQuestion pdf={pdf}>
					Include anything you are able to remember around what you felt, saw,
					heard, smelled, tasted, or anything you can’t forget about your
					experience or experiences with the offender(s).
				</OrfQuestion>
			</SubHeader>

			{(!pdf || !values.whatHappened) && (
				<Textarea
					height="400px"
					{...register("whatHappened")}
					onChange={async (e: ChangeEvent<HTMLTextAreaElement>) => {
						await onChange(e);
						setModified();
					}}
				/>
			)}
			{pdf && values.whatHappened && (
				<PdfTextarea>{values.whatHappened}</PdfTextarea>
			)}
		</div>
	);
};
