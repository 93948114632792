import React from "react";
import { Box } from "@chakra-ui/react";

export const IncidentLogBanner: React.FC = () => (
	<Box
		as="p"
		backgroundColor="brand.primary"
		p="15px"
		color="brand.white"
		textAlign="left"
		width="100%"
		maxWidth="900px"
		mb="28px"
	>
		Some survivors remember specific details while others have difficulty
		recalling exact details. Adding details can be helpful so that you have
		proof of when it occurred, particularly if you choose to report to
		authorities.
	</Box>
);
