import { validators } from "../../../../../../lib/crypto";

export const validateSocialAccount = (
	id: string,
	identifiers: string[],
): boolean =>
	identifiers.every((identifier) => {
		if (!identifier) {
			return false;
		} else {
			try {
				validators[id](identifier);
				return true;
			} catch {
				return false;
			}
		}
	});

export const checkIdentifiersEmpty = (identifiers: {
	[key: string]: string[];
}): boolean => {
	const inValid = Object.keys(identifiers).every(
		(key) => identifiers[key].length === 0,
	);
	return !inValid;
};
