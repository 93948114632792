import React from "react";
import {
	forwardRef,
	Image,
	InputGroup,
	InputGroupProps,
	InputLeftElement,
	InputProps,
} from "@chakra-ui/react";

import Input from "./Input";

type Props = InputProps & {
	inputGroupProps: InputGroupProps;
	icon: string | React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const IconInput = forwardRef<Input, Props>(
	({ inputGroupProps, icon, ...props }: Props, ref) => (
		<InputGroup {...inputGroupProps}>
			<InputLeftElement pointerEvents="none" height="100%" paddingLeft="18px">
				{typeof icon === "string" ? <Image src={icon} /> : <>{icon}</>}
			</InputLeftElement>
			<Input
				padding="10px 15px 10px 60px"
				ref={ref}
				backgroundColor="brand.brightWhite"
				{...props}
			/>
		</InputGroup>
	),
);

export default IconInput;
