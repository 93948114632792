import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

import HeaderText from "../../components/HeaderText";
import ContainerWrapper from "../../components/ContainerWrapper";
import GiveFeedbackLink from "../../components/GiveFeedbackLink";

const SignUpForeignEdu: React.FC = () => (
	<ContainerWrapper>
		<Flex
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			width="100%"
			height="100%"
			maxW="850px"
			textAlign="center"
			margin="0 auto"
		>
			<Box
				backgroundColor="brand.white"
				padding={["15px 20px", "25px 40px", "45px 80px"]}
			>
				<HeaderText
					fontFamily="Avenir"
					fontWeight="400"
					lineHeight={["30px", "40px", "60px"]}
				>
					Thank you for your interest.
				</HeaderText>
				<Text textAlign="left" as="p" fontSize="sm">
					Unfortunately, only users with a .edu address from a U.S. based
					college/university have access to our services.
				</Text>
				<Text textAlign="left" as="p" fontSize="sm">
					It appears that the email address you provided is not from a U.S.
					based college or university. If you feel that you have reached this
					page in error then please feel free to use the link below to reach out
					to our technical team.
				</Text>
			</Box>
			<GiveFeedbackLink />
		</Flex>
	</ContainerWrapper>
);

export default SignUpForeignEdu;
