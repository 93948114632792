/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FormEvent } from "react";
import USAMap from "@project-callisto/react-usa-map";
import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

import MapCursor from "../../../assets/map.svg";
import theme from "../../styles/theme";
import HeaderText from "../../components/HeaderText";
import { usStates } from "./constant";

interface Props {
	selectedState: string;
	setSelectedState: (state: string) => void;
}

const Map: React.FC<Props> = ({ selectedState, setSelectedState }) => {
	const handleMapClick = (
		event: FormEvent & { target: { dataset: { name: string } } },
	) => {
		event.stopPropagation();
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		setSelectedState(event.target.dataset.name);
	};

	const handleMapWrapperClick = () => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		setSelectedState("");
	};

	const statesCustomConfig = () => {
		if (selectedState) {
			return {
				[selectedState]: {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					fill: theme.colors.brand.mapColor,
				},
			};
		}
	};

	return (
		<Box>
			<Box height="60px">
				<HeaderText as="p" color="brand.primary" textAlign="center" mb="0px">
					{selectedState && usStates[selectedState]}
				</HeaderText>
			</Box>
			<MapWrapper onClick={handleMapWrapperClick}>
				<USAMap
					onClick={handleMapClick}
					width="100%"
					height="auto"
					customize={statesCustomConfig()}
				/>
			</MapWrapper>
		</Box>
	);
};

export default Map;

const MapWrapper = styled(Box)`
  width: 100%;
  svg {
    g {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  path {
    transition: 0.5s;
  }
  path:hover {
    fill: ${theme.colors.brand.mapColor};
  }
  &:hover {
    cursor:
      url(${MapCursor}) 10 60,
      auto;
  }
`;
