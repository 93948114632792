import * as React from "react";
import { Fragment, useContext, useEffect, useMemo } from "react";
import {
	Link,
	NavLink,
	NavLinkProps,
	useLocation,
	useNavigate,
} from "react-router-dom";
import styled from "@emotion/styled";
import {
	Box,
	BoxProps,
	Divider,
	Flex,
	Image,
	useToast,
} from "@chakra-ui/react";

import {
	CallistoRoutes,
	PrivateRoutes,
	PublicRoutes,
} from "../../../config/routes";
import Button from "../../Button";
import theme from "../../../styles/theme";
import CallistoVaultLogo from "../../../../assets/CallistoVault_logo_stacked.svg";
import { survivorClient } from "../../../service/backend";
import { AppContext } from "../../../contexts/AppContext";
import HomeIcon from "../../icons/HomeIcon";
import ConsentIcon from "../../icons/ConsentIcon";
import { MainSidebarContext } from "../../../contexts/MainSidebarContext";
import PersonIcon from "../../icons/PersonIcon";
import FingerprintIcon from "../../icons/FingerprintIcon";
import GearsIcon from "../../icons/GearsIcon";
import EncryptedDocumentIcon from "../../icons/EncryptedDocumentIcon";
import ResourcesIcon from "../../../../assets/icons/resources.svg";
import CallistoLogo from "../../../../assets/icons/callisto-logo-icon.svg";
import { useReopened } from "../../../service/hooks/reopened";

interface SidebarProps {
	isSidebarCollapsed: boolean;
	setSidebarCollapsed: (collapsed: boolean) => void;
}

const MainSidebar: React.FC<SidebarProps> = ({
	isSidebarCollapsed,
	setSidebarCollapsed,
}: SidebarProps) => {
	const reopened = useReopened();
	const navigate = useNavigate();
	const location = useLocation();
	const toast = useToast();
	const { setLoggedIn } = useContext(AppContext);
	const { mainSidebarVisible, setMainSidebarVisible } =
		useContext(MainSidebarContext);

	useEffect(() => {
		// The number 768 is not random--it is the divider between sm and md size in the bootstrap grid
		setMainSidebarVisible(window.innerWidth > 768);
	}, [location]);

	const handleClickLink = async (action: string) => {
		await survivorClient.submitEvent(action, {});
	};

	const handleLogout = async () => {
		await survivorClient.logout();
		setLoggedIn(false);
		navigate(PublicRoutes.LOGIN);
		toast({
			title: "You have been logged out.",
			position: "top",
			status: "success",
		});
	};

	const handleCollapseClick = () => {
		setSidebarCollapsed(!isSidebarCollapsed);
	};

	const linkWrapperProps = useMemo(
		() =>
			({
				as: "span",
				marginLeft: "35px",
				display: ["block", "block", !isSidebarCollapsed ? "block" : "none"],
				maxHeight: "24px",
				overflow: "hidden",
			}) as BoxProps,
		[isSidebarCollapsed],
	);

	return (
		<Flex
			as="aside"
			width={{
				base: "100%",
				sm: "300px",
				md: isSidebarCollapsed ? "84px" : "300px",
			}}
			left="0px"
			top="0px"
			transform={{
				base: mainSidebarVisible ? "none" : "translateX(-100%)",
				md: "none",
			}}
			position="fixed"
			height="100%"
			transition="all 0.5s"
			backgroundColor="brand.white"
			padding="0px 20px 20px 20px"
			flexDir="column"
			zIndex={9}
			overflowY="auto"
			overflowX="hidden"
		>
			<Box flexGrow={1}>
				<Flex
					textAlign="center"
					my={["30px", "30px", "75px"]}
					justifyContent="center"
					minHeight="31px"
				>
					<Box display={["none", "none", "block"]}>
						<Link to={PrivateRoutes.DASHBOARD}>
							{isSidebarCollapsed ? (
								<Image />
							) : (
								<Image src={CallistoVaultLogo as string} width="200px" />
							)}
						</Link>
					</Box>
				</Flex>
				<NavLinkWrapper
					to={PrivateRoutes.DASHBOARD}
					className={({ isActive }) => (isActive ? "active" : "")}
					isCollapsed={isSidebarCollapsed}
				>
					<HomeIcon />
					<Box {...linkWrapperProps}>Dashboard</Box>
				</NavLinkWrapper>
				{reopened && (
					<NavLinkWrapper
						to={PrivateRoutes.MATCHING_SYSTEM}
						className={({ isActive }) => (isActive ? "active" : "")}
						isCollapsed={isSidebarCollapsed}
					>
						<GearsIcon />
						<Box {...linkWrapperProps}>Matching System</Box>
					</NavLinkWrapper>
				)}
				<HyperlinkWrapper
					href={CallistoRoutes.DETECT_REPEAT_OFFENDERS}
					target="_blank"
					isCollapsed={isSidebarCollapsed}
					onClick={() => handleClickLink("Go to How Matching Works")}
				>
					<FingerprintIcon />
					<Box {...linkWrapperProps}>How Matching Works</Box>
				</HyperlinkWrapper>
				<NavLinkWrapper
					to={PrivateRoutes.ILOG}
					className={({ isActive }) => (isActive ? "active" : "")}
					isCollapsed={isSidebarCollapsed}
				>
					<EncryptedDocumentIcon />
					<Box {...linkWrapperProps}>Incident Log</Box>
				</NavLinkWrapper>
				<NavLinkWrapper
					to={PrivateRoutes.CONSENT_SETTINGS}
					className={({ isActive }) => (isActive ? "active" : "")}
					isCollapsed={isSidebarCollapsed}
				>
					<ConsentIcon />
					<Box {...linkWrapperProps}>Consent Settings</Box>
				</NavLinkWrapper>
				<NavLinkWrapper
					to={PrivateRoutes.ACCOUNT}
					className={({ isActive }) => (isActive ? "active" : "")}
					isCollapsed={isSidebarCollapsed}
				>
					<PersonIcon />
					<Box {...linkWrapperProps}>Account Settings</Box>
				</NavLinkWrapper>
				<NavLinkWrapper
					to={PrivateRoutes.RESOURCES}
					className={({ isActive }) => (isActive ? "active" : "")}
					isCollapsed={isSidebarCollapsed}
				>
					<Image src={ResourcesIcon as string} width="18px" />
					<Box {...linkWrapperProps}>Resources</Box>
				</NavLinkWrapper>
				<HyperlinkWrapper
					href={CallistoRoutes.CALLISTO_HOME}
					target="_blank"
					isCollapsed={isSidebarCollapsed}
					onClick={() => handleClickLink("Go to Callisto Website")}
				>
					<Image src={CallistoLogo as string} width="18px" />
					<Box {...linkWrapperProps}>Callisto Website</Box>
				</HyperlinkWrapper>
				<Box
					display={["block", "block", !isSidebarCollapsed ? "block" : "none"]}
				>
					<Divider margin="10px 0px 10px 0px" />
					<HyperlinkWrapper href={CallistoRoutes.ABOUT_US} target="_blank">
						About
					</HyperlinkWrapper>
					<HyperlinkWrapper
						href={CallistoRoutes.TERMS_OF_SERVICE}
						target="_blank"
					>
						Terms of Service
					</HyperlinkWrapper>
					<HyperlinkWrapper
						href={CallistoRoutes.PRIVACY_POLICY}
						target="_blank"
					>
						Privacy Policy
					</HyperlinkWrapper>
					<NavLinkWrapper
						to={PrivateRoutes.CONTACT_US}
						isCollapsed={isSidebarCollapsed}
					>
						Contact Us
					</NavLinkWrapper>
				</Box>
			</Box>
			<Box display={["block", "block", !isSidebarCollapsed ? "block" : "none"]}>
				<Button buttonColor="brand.primary" onClick={handleLogout} width="100%">
					Logout
				</Button>
			</Box>
			<Box textAlign="center" display={["none", "none", "block"]}>
				<Button
					buttonColor="brand.primary"
					minWidth="auto"
					width="50px"
					padding="16px 16px"
					borderRadius="15px"
					mt="30px"
					onClick={handleCollapseClick}
				>
					{isSidebarCollapsed ? (
						<Fragment>&gt;</Fragment>
					) : (
						<Fragment>&lt;</Fragment>
					)}
				</Button>
			</Box>
		</Flex>
	);
};

export default MainSidebar;

const styles = `
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: center;
  width: 120%;
  margin-bottom: 3px;
  font-family: 'Avenir'; 
  font-size: 16px;
  min-height: 44px;
  padding: 0px 0px 0px 15px;
  overflow: hidden;
  max-height: 44px;
  white-space: nowrap;
  `;

/* eslint-disable @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access */
export const NavLinkWrapper =
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	styled(
		({
			isCollapsed,
			hideHover = false,
			...rest
		}: { isCollapsed: boolean; hideHover?: boolean } & NavLinkProps) => (
			<NavLink {...rest} />
		),
	)`
    border-radius: ${(props) => (props.isCollapsed ? "15px" : "100px")};
    ${styles}
    &:hover {
      background-color: ${(props) =>
				props.hideHover ? "" : theme.colors.brand.appBackground};
    }
    &.active {
      background-color: ${theme.colors.brand.appBackground};
    }
    @media only screen and (min-width: 768px) {
      grid-template-columns: ${(props) =>
				props.isCollapsed ? "auto" : "auto 1fr"};
    }
  `;

export const FormSectionsNavLinkWrapper = styled(NavLink)`
  border-radius: 100px;
  ${styles}
  &:hover {
    background-color: ${theme.colors.brand.appBackground};
  }
  &.active {
    background-color: ${theme.colors.brand.appBackground};
  }
`;

const HyperlinkWrapper = styled.a<{
	hideHover?: boolean;
	isCollapsed?: boolean;
}>`
  border-radius: ${(props) => (props.isCollapsed ? "15px" : "100px")};
  @media only screen and (min-width: 768px) {
    grid-template-columns: ${(props) =>
			props.isCollapsed ? "auto" : "auto 1fr"};
  }
  ${styles}
  &:hover {
    background-color: ${(props) =>
			props.hideHover ? "" : theme.colors.brand.appBackground};
  }
  &.active {
    background-color: ${theme.colors.brand.appBackground};
  }
`;
