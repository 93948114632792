import React from "react";
import ConfirmModal from "./ConfirmModal";

interface ContactInfoChangeConfirmProps {
	isOpen: boolean;
	onClose(): void;
	onSubmit(): void | Promise<void>;
	onCancel(): void;
}

const ContactInfoChangeConfirmModal: React.FC<
	ContactInfoChangeConfirmProps
> = ({
	isOpen,
	onClose,
	onSubmit,
	onCancel,
}: ContactInfoChangeConfirmProps) => (
	<ConfirmModal
		headerText={
			"<b>Are you sure you want to change your contact info?</b>" +
			'<p style="text-align: left">Changing your contact info will change it for your account and every entry you have created.' +
			" Do you want to proceed?</p>"
		}
		submitText="Yes, change my contact info"
		cancelText="No, don't change my contact info"
		onSubmit={onSubmit}
		isConfirming={false}
		isOpen={isOpen}
		onClose={onClose}
		onCancel={onCancel}
	/>
);

export default ContactInfoChangeConfirmModal;
