import React, { SyntheticEvent, useContext } from "react";
import { Box } from "@chakra-ui/react";

import AnimatedBox from "../../components/layout/AnimatedBox";
import Button from "../../components/Button";
import { OrfContext } from "../../contexts/OrfContext";
import MainCard from "../../components/MainCard";
import { OrfIntroPageContent } from "./OrfIntroPageContent";
import { useNavigate } from "react-router-dom";

const OrfIntro: React.FC = () => {
	const {
		state: { steps },
		completeAction,
	} = useContext(OrfContext);
	const { intro } = steps;
	const navigate = useNavigate();

	const handleSubmit = (e: SyntheticEvent) => {
		e.preventDefault();
		completeAction("intro");
		navigate(intro.nextStepRoute);
	};

	return (
		<AnimatedBox>
			<MainCard>
				<Box as="form" onSubmit={handleSubmit}>
					<OrfIntroPageContent />
					<Box textAlign="right">
						<Button buttonColor="brand.primary" type="submit">
							Continue
						</Button>
					</Box>
				</Box>
			</MainCard>
		</AnimatedBox>
	);
};

export default OrfIntro;
