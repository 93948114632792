import React from "react";
import {
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalProps,
} from "@chakra-ui/modal";
import { Flex, ModalOverlay, Text } from "@chakra-ui/react";

import Button from "./Button";
import WhiteButton from "./layout/WhiteButton";

interface Props extends ModalProps {
	sec: number;
	stayLoggedIn: () => void;
	logout: () => void;
}

const LogoutTimerModal: React.FC<Props> = ({
	sec,
	stayLoggedIn,
	logout,
	...restProps
}: Props) => (
	<Modal
		isCentered
		closeOnOverlayClick={false}
		closeOnEsc={false}
		{...restProps}
	>
		<ModalOverlay />
		<ModalContent p="40px" maxW="550px">
			<ModalCloseButton />
			<Text mb="18px" as="h3" fontSize="md">
				<b>Your session is about to expire</b>
			</Text>
			<Text fontSize="sm">
				You will be logged out in {sec} seconds. Do you want to stay logged in?
			</Text>
			<Flex alignItems="center" justifyContent="center" mt="20px">
				<WhiteButton
					fontSize="xs"
					padding="15px 15px"
					minWidth="auto"
					marginRight="15px"
					onClick={logout}
				>
					No, Log Out
				</WhiteButton>
				<Button
					buttonColor="brand.primary"
					fontSize="xs"
					padding="15px 15px"
					minWidth="auto"
					onClick={stayLoggedIn}
				>
					Yes, Stay Logged In
				</Button>
			</Flex>
		</ModalContent>
	</Modal>
);

export default LogoutTimerModal;
