import React from "react";
import { Box } from "@chakra-ui/react";

const OrfBanner: React.FC = () => (
	<Box
		backgroundColor="brand.primary"
		p="15px"
		color="brand.white"
		textAlign="center"
		width="100%"
		maxWidth="900px"
		mb="28px"
	>
		<h2>
			<b>
				All fields are optional. You can revisit your record and make changes at
				any time. <br />
				Nothing on this form is legal advice.
			</b>
		</h2>
	</Box>
);

export default OrfBanner;
