import React from "react";
import { useForm } from "react-hook-form";
import {
	Box,
	Flex,
	Image,
	InputGroup,
	InputLeftElement,
	Text,
} from "@chakra-ui/react";

import ContainerWrapper from "../../components/ContainerWrapper";
import CampusRecord from "../../components/CampusRecord";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { useBackupCode } from "../../service/hooks/auth";
import { PublicRoutes } from "../../config/routes";
import { useNotification } from "../../components/Notification/useNotification";
import Notification from "../../components/Notification";

import LockIcon from "../../../assets/icons/lock_new.svg";
import WhiteButton from "../../components/layout/WhiteButton";
import { useNavigate } from "react-router-dom";

interface IResetPasswordForm {
	code: string;
}

const ResetPassword: React.FC = () => {
	const { register, handleSubmit } = useForm<IResetPasswordForm>();
	const { notification, setNotification } = useNotification();
	const navigate = useNavigate();
	const { mutateAsync: requestResetPwd, isLoading: pwdResetting } =
		useBackupCode();

	const onSubmit = async ({ code }: IResetPasswordForm) => {
		try {
			await requestResetPwd({ code });
			navigate(PublicRoutes.RESET_PASSWORD_NEW, {
				state: {
					code,
				},
			});
		} catch (e) {
			setNotification({
				type: "error",
				message: (e as Error).message,
			});
		}
	};

	return (
		<ContainerWrapper
			textAlign="center"
			display="flex"
			justifyContent="center"
			alignItems="center"
		>
			<Box maxWidth="850px" as="form" onSubmit={handleSubmit(onSubmit)}>
				<Notification notification={notification} />
				<Flex
					backgroundColor="brand.white"
					py="40px"
					px={["20px", "40px", "100px"]}
					flexDir="column"
					alignItems="center"
				>
					<Text as="h1" fontSize="xl" fontFamily="Avenir">
						Enter one of your backup codes
					</Text>
					<Box fontSize="sm" py="15px">
						<Text as="p" textAlign="left">
							Entering a backup code is the easiest and most secure way to reset
							your password. Your backup codes were given to you when you
							created your Callisto Vault account. If you do not have these
							codes, you can still recover your account provided that you have
							provided us with an account recovery email address and phone
							number.
						</Text>
					</Box>
					<InputGroup mt="16px" maxW="600px">
						<InputLeftElement
							pointerEvents="none"
							height="100%"
							paddingLeft="18px"
						>
							<Image src={LockIcon as string} />
						</InputLeftElement>
						<Input
							placeholder="Enter one of your backup codes*"
							{...register("code")}
							type="password"
							padding="10px 15px 10px 60px"
							isRequired
						/>
					</InputGroup>

					<Button
						buttonColor="brand.primary"
						mt="28px"
						type="submit"
						isLoading={pwdResetting}
					>
						Reset Password
					</Button>
					<WhiteButton
						mt="16px"
						onClick={() => {
							navigate(PublicRoutes.REQUEST_ACCOUNT_RECOVERY);
						}}
					>
						Use email address and phone number
					</WhiteButton>
				</Flex>
				<CampusRecord />
			</Box>
		</ContainerWrapper>
	);
};

export default ResetPassword;
