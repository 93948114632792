import React, { SyntheticEvent, useContext, useState } from "react";
import { Box } from "@chakra-ui/layout";
import { Flex, Image } from "@chakra-ui/react";

import HeaderText from "../../components/HeaderText";
import AnimatedBox from "../../components/layout/AnimatedBox";
import ReviewSubHeader from "./ReviewSubHeader";
import ReviewSubText from "./ReviewSubText";
import { MONTH_VALUES, OrfContext } from "../../contexts/OrfContext";
import ButtonLink from "../../components/ButtonLink";
import Button from "../../components/Button";
import ReviewSubValue from "./ReviewSubValue";
import { PrivateRoutes } from "../../config/routes";
import PencilIcon from "../../../assets/icons/pencil.svg";
import MainCard from "../../components/MainCard";
import { survivorClient, wait } from "../../service/backend";
import WhiteButton from "../../components/layout/WhiteButton";
import IncidentLogPdfDisclaimerModal from "../IncidentLogPdf/IncidentLogPdfDisclaimerModal";
import IncidentLogPdfReasonModal from "../IncidentLogPdf/IncidentLogPdfReasonModal";
import { IncidentLogPdfContext } from "../IncidentLogPdf/IncidentLogPdfContext";
import { Link, useNavigate } from "react-router-dom";

const OrfReview: React.FC = () => {
	const navigate = useNavigate();
	const {
		state: { steps, mode, currentRecordId },
		setSavingScreenVisibility,
		updateORF,
	} = useContext(OrfContext);
	const {
		state: { reason: purpose },
	} = useContext(IncidentLogPdfContext);

	const { when, where, know, consent, incidents, offenders, evidence } = steps;

	const [showExportPdfDisclaimerModal, setShowExportPdfDisclaimerModal] =
		useState<boolean>(false);
	const [showExportPdfReasonModal, setShowExportPdfReasonModal] =
		useState<boolean>(false);

	const handleSubmit = async (e: SyntheticEvent) => {
		e.preventDefault();

		setSavingScreenVisibility(true);
		await wait(1000);
		const completedSteps = survivorClient.extractCompletedSteps(steps);

		try {
			await survivorClient.submitEvent("Filled ORF Data", {
				submitted: true,
				completedSteps,
			});
		} finally {
			if (mode === "edit" && currentRecordId) {
				await updateORF();
			}
			navigate(PrivateRoutes.ILOG);
		}
		setSavingScreenVisibility(false);
	};

	const handleBackClick = () => {
		navigate(PrivateRoutes.ORF_EVIDENCE);
	};

	const isEditMode = mode === "edit";

	return (
		<AnimatedBox>
			<MainCard>
				<Box as="form" onSubmit={handleSubmit} id="orf_form">
					<WhiteButton
						m="3px"
						className="rightAligned"
						onClick={() => {
							survivorClient
								.submitEvent("Button Click", {
									feature: `Incident Log PDF Download`,
									button: "Download as PDF",
								})
								.finally(() => {
									setShowExportPdfDisclaimerModal(true);
								});
						}}
					>
						Download as PDF
					</WhiteButton>
					<HeaderText>Review</HeaderText>
					<Box>
						<ReviewSubHeader>
							{when.text}
							{isEditMode && (
								<Link to={when.route}>
									<ButtonLink color="brand.link">
										<Image src={PencilIcon as string} />
									</ButtonLink>
								</Link>
							)}
						</ReviewSubHeader>
						{when.completed && (
							<>
								<ReviewSubText>
									Do you know any details about the date?
								</ReviewSubText>
								<ReviewSubValue>
									{when.hasExactDate && when.hasExactDate === "Yes" && (
										<>
											{when.year && `${when.year} `}
											{when.month &&
												`${
													MONTH_VALUES.find(
														(v) => v.value.toString() === when.month,
													)?.key
												} `}
											{when.day && `${when.day}`}
										</>
									)}
								</ReviewSubValue>
								<ReviewSubText>What season was it?</ReviewSubText>
								<ReviewSubValue textTransform="capitalize">
									{when.season}
								</ReviewSubValue>
								<ReviewSubText>What time of day was it?</ReviewSubText>
								<ReviewSubValue>{when.time.value}</ReviewSubValue>
								<ReviewSubText>
									Is there anything else you remember about the date?
								</ReviewSubText>
								{when.memory && <ReviewSubValue>{when.memory}</ReviewSubValue>}
							</>
						)}
					</Box>
					<Box>
						<ReviewSubHeader>
							{where.text}
							{isEditMode && (
								<Link to={where.route}>
									<ButtonLink color="brand.link">
										<Image src={PencilIcon as string} />
									</ButtonLink>
								</Link>
							)}
						</ReviewSubHeader>
						{where.completed && (
							<>
								<ReviewSubText>Where did it happen?</ReviewSubText>
								<ReviewSubValue>{where.place.value}</ReviewSubValue>
								<ReviewSubText>
									Are there any other details you remember about the location?
								</ReviewSubText>
								{where.memory && (
									<ReviewSubValue>{where.memory}</ReviewSubValue>
								)}
							</>
						)}
					</Box>
					<Box>
						<ReviewSubHeader>
							{know.text}
							{isEditMode && (
								<Link to={know.route}>
									<ButtonLink color="brand.link">
										<Image src={PencilIcon as string} />
									</ButtonLink>
								</Link>
							)}
						</ReviewSubHeader>
						{know.completed && (
							<>
								<ReviewSubText>
									Did anyone else see or hear either all or any part of what
									happened?
								</ReviewSubText>
								<ReviewSubValue>{know.anyoneSaw}</ReviewSubValue>
								<ReviewSubText>
									Did you interact with anyone right before or after the
									incident? This could be people who were at the scene of the
									incident or nearby, who saw you or the offender leave, or who
									helped you call for help.
								</ReviewSubText>
								<ReviewSubValue>{know.anyoneInteract}</ReviewSubValue>
								<ReviewSubText>
									What information do you know about any of the people described
									above?
								</ReviewSubText>
								{know.informationYouDescribed && (
									<ReviewSubValue>
										{know.informationYouDescribed}
									</ReviewSubValue>
								)}
								<ReviewSubText>
									Did you tell anyone about the incident?
								</ReviewSubText>
								<ReviewSubValue>{know.toldAnyone}</ReviewSubValue>
								<ReviewSubText>
									What information do you know about the people you told?
								</ReviewSubText>
								{know.informationYouTold && (
									<ReviewSubValue>{know.informationYouTold}</ReviewSubValue>
								)}
								<ReviewSubText>
									Do you know if the offender told anyone about the incident?
								</ReviewSubText>
								<ReviewSubValue>{know.offenderTold}</ReviewSubValue>
							</>
						)}
					</Box>
					<Box>
						<ReviewSubHeader>
							{consent.text}
							{isEditMode && (
								<Link to={consent.route}>
									<ButtonLink color="brand.link">
										<Image src={PencilIcon as string} />
									</ButtonLink>
								</Link>
							)}
						</ReviewSubHeader>
						{consent.completed && (
							<>
								<ReviewSubText>
									What happened to me was not okay because:
								</ReviewSubText>
								{consent.reasons.map((reason) => (
									<ReviewSubValue key={reason}>{reason}</ReviewSubValue>
								))}
							</>
						)}
					</Box>
					<Box>
						<ReviewSubHeader>
							{incidents.text}
							{isEditMode && (
								<Link to={incidents.route}>
									<ButtonLink color="brand.link">
										<Image src={PencilIcon as string} />
									</ButtonLink>
								</Link>
							)}
						</ReviewSubHeader>
						{incidents.completed && (
							<>
								<ReviewSubText>What happened?</ReviewSubText>
								<ReviewSubValue>{incidents.whatHappened}</ReviewSubValue>
							</>
						)}
					</Box>
					<Box>
						<ReviewSubHeader>
							{offenders.text}
							{isEditMode && (
								<Link to={offenders.route}>
									<ButtonLink color="brand.link">
										<Image src={PencilIcon as string} />
									</ButtonLink>
								</Link>
							)}
						</ReviewSubHeader>
						{offenders.completed && (
							<>
								<ReviewSubText>How many offenders were there?</ReviewSubText>
								<ReviewSubValue>{offenders.numberOfOffenders}</ReviewSubValue>
								<ReviewSubText>
									What is their name?/What are their names? (if known)
								</ReviewSubText>
								<ReviewSubValue>{offenders.name}</ReviewSubValue>
								<ReviewSubText>
									Do you know any other information about the offender(s)?
								</ReviewSubText>
								{offenders.offenderDetails && (
									<ReviewSubValue>{offenders.offenderDetails}</ReviewSubValue>
								)}
								<ReviewSubText>
									Was the offender with anyone else either at the time of the
									incident, or directly before or after?
								</ReviewSubText>
								<ReviewSubValue>{offenders.anyoneElse}</ReviewSubValue>
								<ReviewSubText>
									What information do you know about these people?
								</ReviewSubText>
								{offenders.companionDetails && (
									<ReviewSubValue>{offenders.companionDetails}</ReviewSubValue>
								)}
							</>
						)}
					</Box>
					<Box>
						<ReviewSubHeader>
							{evidence.text}
							{isEditMode && (
								<Link to={evidence.route}>
									<ButtonLink color="brand.link">
										<Image src={PencilIcon as string} />
									</ButtonLink>
								</Link>
							)}
						</ReviewSubHeader>
						{evidence.completed && (
							<>
								<ReviewSubText>
									What types of electronic evidence do you have now (or think
									might exist)?
								</ReviewSubText>
								{evidence.electronicInfo && (
									<ReviewSubValue>{evidence.electronicInfo}</ReviewSubValue>
								)}
								<ReviewSubText>
									What types of physical evidence do you have now (or think
									might exist)?
								</ReviewSubText>
								{evidence.physicalInfo && (
									<ReviewSubValue>{evidence.physicalInfo}</ReviewSubValue>
								)}
							</>
						)}
					</Box>
					<Flex flexDir="column" mt="60px">
						<Button
							buttonColor="brand.primary"
							type="submit"
							marginBottom="20px"
						>
							Save & Exit
						</Button>
						<ButtonLink onClick={handleBackClick}>Back</ButtonLink>
					</Flex>
				</Box>
			</MainCard>
			{showExportPdfDisclaimerModal && (
				<IncidentLogPdfDisclaimerModal
					isOpen={true}
					onClose={() => {
						survivorClient
							.submitEvent("Button Click", {
								button: "Cancel (without accepting disclaimer)",
								feature: `Incident Log PDF Download`,
							})
							.finally(() => {
								setShowExportPdfDisclaimerModal(false);
							});
					}}
					onSubmit={() => {
						survivorClient
							.submitEvent("Button Click", {
								button: "Accept disclaimer",
								feature: `Incident Log PDF Download`,
							})
							.finally(() => {
								setShowExportPdfDisclaimerModal(false);
								setShowExportPdfReasonModal(true);
							});
					}}
				/>
			)}
			{showExportPdfReasonModal && (
				<IncidentLogPdfReasonModal
					isOpen={true}
					onClose={() => {
						survivorClient
							.submitEvent("Button Click", {
								button: "Cancel (after accepting disclaimer)",
								feature: `Incident Log PDF Download`,
							})
							.finally(() => {
								setShowExportPdfReasonModal(false);
							});
					}}
					onSubmit={() => {
						survivorClient
							.submitEvent("Button Click", {
								button: "Download PDF",
								purpose,
								feature: `Incident Log PDF Download`,
							})
							.finally(() => {
								navigate(PrivateRoutes.ORF_PDF_DOWNLOAD);
							});
					}}
				/>
			)}
		</AnimatedBox>
	);
};

export default OrfReview;
