import React from "react";
import {
	Box,
	Flex,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	ModalProps,
	Text,
} from "@chakra-ui/react";
import { useVerifyRecoveryEmailUpdate } from "../service/hooks/auth";
import { useForm } from "react-hook-form";
import IconInput from "./IconInput";
import EmailIcon from "../../assets/email_navy.svg";
import Button from "./Button";
import Notification from "./Notification";
import WhiteButton from "./layout/WhiteButton";
import { survivorClient } from "../service/backend";
import { PublicRoutes } from "../config/routes";
import { useNavigate } from "react-router-dom";

interface RecoveryEmailInputForm {
	email: string;
	confirmEmail: string;
}

const RecoveryEmailModal: React.FC<ModalProps> = ({
	onClose,
	...props
}: ModalProps) => {
	const navigate = useNavigate();
	const {
		mutateAsync: verifyRecoveryEmail,
		isLoading,
		data: verificationSent,
	} = useVerifyRecoveryEmailUpdate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm<RecoveryEmailInputForm>();

	const onSubmit = async ({ email }: RecoveryEmailInputForm) => {
		await verifyRecoveryEmail({
			email: email?.toLowerCase().trim(),
			phone: "",
			legacySetup: true,
		});
	};

	const handleResend = async () => {
		await verifyRecoveryEmail({
			email: getValues().email.toLowerCase().trim(),
			phone: "",
			legacySetup: true,
		});
	};

	return (
		<Modal
			onClose={onClose}
			closeOnOverlayClick={true}
			closeOnEsc={false}
			{...props}
		>
			<ModalOverlay />
			<ModalCloseButton />
			<ModalContent
				p="100px"
				maxW="850px"
				borderRadius="5px"
				borderColor="brand.primary"
				borderWidth="2px"
				color="brand.primary"
			>
				<Flex flexDir="column">
					{!isLoading && verificationSent?.success && (
						<Box textAlign="center" as="form">
							<Notification
								notification={{
									type: "success",
									message: "Email has been sent",
								}}
							/>
							<Box>
								<Text
									mt="40px"
									lineHeight="28px"
									textAlign="left"
									fontSize="sm"
								>
									We sent a verification link to {getValues().email}. After
									clicking that link, you will be asked to enter your username
									and password. Once you have logged in that way, you will be
									asked to complete account recovery setup.
								</Text>
								<Text my="17px" fontSize="sm">
									<b>Did not receive the email?</b>
								</Text>
								<Text lineHeight="28px" textAlign="left" fontSize="sm">
									Your verification email may take up to 5 minutes to arrive in
									your inbox. Be sure to also check your spam folder.
								</Text>
								<Flex
									flexDir="row"
									alignItems="center"
									textAlign="center"
									justifyContent="center"
								>
									<WhiteButton
										onClick={async () => {
											await survivorClient.logout();
											navigate(PublicRoutes.LOGIN);
										}}
										height="auto"
										mr="1em"
									>
										Log Out
									</WhiteButton>
									<Button
										buttonColor="brand.primary"
										onClick={handleResend}
										isLoading={isLoading}
									>
										Resend verification email
									</Button>
								</Flex>
							</Box>
						</Box>
					)}
					<Box
						as="form"
						onSubmit={handleSubmit(onSubmit)}
						display={verificationSent?.success ? "none" : "flex"}
						flexDir="column"
					>
						<Text as="p" fontSize="sm">
							Since you last logged in, we have added an emergency account
							recovery feature. You must set up account recovery before you can
							continue to use Callisto Vault. If you do not set up account
							recovery, you will be automatically logged out.
						</Text>
						<Text as="p" fontSize="sm" py="15px" textAlign="left">
							No one plans on losing access to their account, but if it does
							happen to you, Callisto is committed to making the process of
							recovering your account as secure as possible. As many people lose
							access to their .edu account when they graduate or are no longer
							employed by the university, we ask that you validate a personal
							email address with us to be used for broadcast communications and
							account recovery. (Broadcast emails are sent through the Callisto
							Vault system and Callisto employees never have access to your
							email address.)
						</Text>
						<Text width="100%" marginBottom="0" fontSize="xs" textAlign="left">
							Recovery Email* (We encourage you to use a personal email address
							that is not your .edu email.)
						</Text>
						<IconInput
							icon={EmailIcon as string}
							type="email"
							placeholder="Enter your recovery email address"
							{...register("email")}
							isRequired
							minWidth="100%"
						/>
						<Text
							width="100%"
							marginBottom="0"
							fontSize="xs"
							textAlign="left"
							mt="15px"
						>
							Confirm Recovery Email*
						</Text>
						<IconInput
							icon={EmailIcon as string}
							type="email"
							placeholder="Reenter your recovery email address"
							{...register("confirmEmail", {
								validate: () => getValues().email === getValues().confirmEmail,
								required: {
									value: true,
									message: "Please confirm your recovery email address",
								},
							})}
							isRequired
							minWidth="100%"
						/>
						<Text color="red" textAlign="left" width="100%" mt="10px">
							{errors.confirmEmail &&
								errors.confirmEmail.type === "validate" &&
								"Email addresses do not match."}
						</Text>
						<Flex
							flexDir="row"
							alignItems="center"
							textAlign="center"
							justifyContent="center"
						>
							<WhiteButton
								onClick={async () => {
									await survivorClient.logout();
									navigate(PublicRoutes.LOGIN);
								}}
								height="auto"
								mr="1em"
							>
								Log Out
							</WhiteButton>
							<Button
								type="submit"
								buttonColor="brand.primary"
								isLoading={isLoading}
							>
								Submit
							</Button>
						</Flex>
					</Box>
				</Flex>
			</ModalContent>
		</Modal>
	);
};

export default RecoveryEmailModal;
