import React from "react";

import { renderShell } from "./reactShell";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { survivorClient } from "./service/backend";

void renderShell(<App />, survivorClient);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
