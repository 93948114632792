import { ActionMap, Step } from "../type";

export const STEP_CONTACT_TEXT = "Your contact info";

export enum StepContactActionTypes {
	SET_CONTACT = "SET_CONTACT",
	UNSET_CONTACT = "UNSET_CONTACT",
}

export interface IContactInfoForm {
	name: string;
	checked: {
		phone: boolean;
		email: boolean;
	};
	phone: string;
	confirmPhone: string;
	email: string;
	confirmEmail: string;
	preferredLanguage?: string;
	accommodationsNeeded?: string;
}

type StepContactPayload = {
	[StepContactActionTypes.SET_CONTACT]: IContactInfoForm;
	[StepContactActionTypes.UNSET_CONTACT]: undefined;
};

export type StepContactActions =
	ActionMap<StepContactPayload>[keyof ActionMap<StepContactPayload>];

export type StepContactState = Step & IContactInfoForm;

const stepContactReducer = (
	state: StepContactState,
	action: StepContactActions,
): StepContactState => {
	switch (action.type) {
		case StepContactActionTypes.SET_CONTACT:
			return {
				...state,
				...action.payload,
				completed: true,
			};
		case StepContactActionTypes.UNSET_CONTACT:
			return {
				...state,
				phone: "",
				email: "",
				checked: {
					phone: false,
					email: false,
				},
				completed: false,
				preferredLanguage: undefined,
			};
		default:
			return state;
	}
};

export default stepContactReducer;
