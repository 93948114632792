import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PublicRoutes } from "../config/routes";

const RedirectToLogin: React.FC = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(PublicRoutes.LOGIN);
	});

	return <></>;
};

export default RedirectToLogin;
