import React from "react";
import { useForm } from "react-hook-form";
import { Box, Text, useToast } from "@chakra-ui/react";

import ContainerWrapper from "../../components/ContainerWrapper";
import CampusRecord from "../../components/CampusRecord";
import HaveAccount from "../../components/HaveAccount";
import Button from "../../components/Button";
import { useVerifyEmail } from "../../service/hooks/auth";
import { CallistoRoutes, PublicRoutes } from "../../config/routes";
import ResendVerify from "./ResendVerify";
import IconInput from "../../components/IconInput";
import EmailIcon from "../../../assets/email_navy.svg";
import GiveFeedbackLink from "../../components/GiveFeedbackLink";
import * as emailValidator from "email-validator";
import { useNavigate } from "react-router-dom";

interface ISignUpForm {
	email: string;
}

const SignUp: React.FC = () => {
	const { register, handleSubmit, getValues } = useForm<ISignUpForm>();
	const {
		mutateAsync: verifyEmail,
		isLoading,
		data: verificationSent,
	} = useVerifyEmail();
	const navigate = useNavigate();
	const toast = useToast();

	const onSubmit = async ({ email }: ISignUpForm) => {
		if (!emailValidator.validate(email)) {
			toast({
				position: "top",
				status: "error",
				title: "Please enter a valid email address",
				duration: 9000,
				isClosable: true,
			});
			return;
		}

		try {
			const { success, foreignEdu } = await verifyEmail({ email });

			if (foreignEdu) {
				navigate(PublicRoutes.SIGN_UP_FOREIGN_EDU);
				return;
			}

			if (!success) {
				toast({
					position: "top",
					status: "error",
					title:
						"Currently, only users with a .edu address from a U.S. based " +
						"college/university have access to our services. Please enter a valid .edu email address.",
					duration: 9000,
					isClosable: true,
				});
				return;
			}
		} catch {
			toast({
				position: "top",
				status: "error",
				title: "There was an error validating your email. Please try again.",
			});
		}
	};

	return (
		<ContainerWrapper
			textAlign="center"
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDir="column"
		>
			{!isLoading && verificationSent?.success && (
				<ResendVerify email={getValues().email} />
			)}
			<Box
				maxWidth="850px"
				as="form"
				backgroundColor="brand.white"
				padding={["20px 20px", "30px 40px", "60px 80px"]}
				onSubmit={handleSubmit(onSubmit)}
				display={verificationSent?.success ? "none" : "flex"}
				flexDir="column"
				alignItems="center"
			>
				<Text as="h1" fontSize="xl" fontFamily="Avenir">
					Enter your school email address
				</Text>
				<Text as="p" fontSize="sm" py="15px">
					To utilize the encrypted tools within Callisto Vault (the Matching
					System or Incident Log), you first need to create your account.
					Getting started is quick and easy. Simply enter your .edu email
					address below and we will send you a verification link. (We do not
					share or store this email address in our system.) Follow that
					verification link to set up your secure account.
				</Text>
				<IconInput
					icon={EmailIcon as string}
					inputGroupProps={{ mt: "16px", maxWidth: "600px" }}
					type="email"
					placeholder="Enter your .edu email"
					{...register("email")}
					isRequired
				/>
				<Button
					mt="28px"
					type="submit"
					buttonColor="brand.primary"
					isLoading={isLoading}
				>
					Create an account
				</Button>
			</Box>
			{!verificationSent && (
				<span>
					<CampusRecord />
					<HaveAccount />
				</span>
			)}
			<a
				href={CallistoRoutes.TERMS_OF_SERVICE}
				target="_blank"
				rel="noreferrer"
				style={hyperLinkStyles}
			>
				<Box>Terms of Service</Box>
			</a>
			<a
				href={CallistoRoutes.PRIVACY_POLICY}
				target="_blank"
				rel="noreferrer"
				style={hyperLinkStyles}
			>
				<Box>Privacy Policy</Box>
			</a>
			<GiveFeedbackLink />
		</ContainerWrapper>
	);
};

export default SignUp;

const hyperLinkStyles = {
	display: "grid",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	marginBottom: "3px",
	fontFamily: "Avenir",
	fontWeight: "bold" as const,
	fontSize: "16px",
	textDecoration: "underline",
	padding: "0px 15px",
	overflow: "hidden",
};
