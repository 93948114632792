import React from "react";
import { Text, TextProps } from "@chakra-ui/react";

const subheaderTextProps: TextProps = {
	as: "p",
	marginTop: "16px",
	fontSize: "sm",
	lineHeight: "30px",
	marginBottom: "30px",
};

const pdfSubheaderTextProps: TextProps = {
	...subheaderTextProps,
	lineHeight: 1,
};

const SubHeader: React.FC<TextProps & { pdf?: boolean }> = ({
	children = "",
	pdf,
	...restProps
}: TextProps & { pdf?: boolean }) => {
	const props: TextProps = pdf ? pdfSubheaderTextProps : subheaderTextProps;
	return (
		<Text {...props} {...restProps}>
			{children}
		</Text>
	);
};

export default SubHeader;
