import React from "react";
import { SlideFade } from "@chakra-ui/react";

interface Props {
	children: React.ReactNode | React.ReactNode[];
}

const AnimatedBox: React.FC<Props> = ({ children }: Props) => (
	<SlideFade offsetY="200px" in unmountOnExit style={{ width: "100%" }}>
		{children}
	</SlideFade>
);

export default AnimatedBox;
