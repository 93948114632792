import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Text, useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

import ContainerWrapper from "../../components/ContainerWrapper";
import Button from "../../components/Button";
import IconInput from "../../components/IconInput";
import { PrivateRoutes, PublicRoutes } from "../../config/routes";
import {
	useBurnBackupCode,
	useCheckPasswordStrength,
} from "../../service/hooks/auth";
import LockIcon from "../../../assets/icons/lock_new.svg";
import Notification from "../../components/Notification";
import { useNotification } from "../../components/Notification/useNotification";
import { survivorClient, wait } from "../../service/backend";
import sodium from "libsodium-wrappers-sumo";

interface IResetPasswordForm {
	password: string;
	confirmPassword: string;
}

const ResetNew: React.FC = () => {
	const navigate = useNavigate();
	const toast = useToast();
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<IResetPasswordForm>();
	const {
		state,
	}: {
		state: {
			code: string;
			noCode?: boolean;
		};
	} = useLocation() as unknown as {
		state: {
			code: string;
			noCode?: boolean;
		};
	};
	const [loading, setLoading] = useState<boolean>(false);
	const [pwdError, setPwdError] = useState<boolean>(false);
	const { mutateAsync: requestCheckPasswordStrength } =
		useCheckPasswordStrength();
	const { mutateAsync: requestBurnBackupCode } = useBurnBackupCode();
	const { notification, setNotification } = useNotification();

	useEffect(() => {
		if (!state || !(state.noCode || state.code)) {
			navigate(PublicRoutes.RESET_PASSWORD);
			toast({
				title: "Please enter your backup code again.",
				status: "error",
				position: "top",
			});
		}
	}, []);

	const onSubmit = async ({ password }: IResetPasswordForm) => {
		setLoading(true);
		const index = state.noCode
			? undefined
			: sodium.to_hex(sodium.crypto_hash_sha256(state.code));
		if (!pwdError) {
			await wait(500);
			try {
				await requestCheckPasswordStrength({ password });
			} catch (e) {
				if (index) {
					await survivorClient.incrementCountUnencrypted(
						"Weak Password Detected: Password Reset",
						index,
					);
				}
				setPwdError(true);
				setLoading(false);
				return;
			}
		} else if (index) {
			await survivorClient.incrementCountUnencrypted(
				"Weak Password Warning Bypassed: Password Reset",
				index,
			);
		}
		try {
			await wait(500);
			await requestBurnBackupCode({
				code: state.noCode ? "" : state.code,
				password,
			});
			toast({
				title: "Your password has been reset",
				status: "success",
				position: "top",
			});
			await survivorClient.submitEventUnencrypted("Reset password", {
				path: state.noCode ? "account recovery" : "recovery code",
			});
			navigate(PrivateRoutes.DASHBOARD);
		} catch (e) {
			setNotification({
				type: "error",
				message: "There was a problem resetting your password",
			});
		}
		setLoading(false);
	};

	const handleChangePassword = async () => {
		if (pwdError) {
			setPwdError(false);
			const index = sodium.to_hex(sodium.crypto_hash_sha256(state.code));
			await survivorClient.incrementCountUnencrypted(
				"Weak Password Overridden: Password Reset",
				index,
			);
		}
	};

	return (
		<ContainerWrapper
			textAlign="center"
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
		>
			<Notification notification={notification} width="850px" />
			<Box
				width="100%"
				maxWidth="850px"
				as="form"
				onSubmit={handleSubmit(onSubmit)}
				backgroundColor="brand.white"
				padding={["16px 20px", "32px 60px", "64px 120px"]}
			>
				<Text as="h1" fontSize="xl" fontFamily="Avenir" fontWeight="400">
					Reset Password
				</Text>
				<IconInput
					icon={LockIcon as string}
					placeholder="New password*"
					inputGroupProps={{
						mb: "40px",
					}}
					{...register("password", {
						minLength: {
							value: 8,
							message:
								"This password is too short, please use at least 8 characters",
						},
						required: {
							value: true,
							message: "Please enter a password.",
						},
					})}
					onChange={handleChangePassword}
					type="password"
					isRequired
				/>
				<IconInput
					inputGroupProps={{
						mb: "16px",
					}}
					icon={LockIcon as string}
					{...register("confirmPassword", {
						validate: () =>
							getValues().password === getValues().confirmPassword,
						required: {
							value: true,
							message: "Please confirm your password.",
						},
					})}
					type="password"
					placeholder="Confirm password*"
					isRequired
				/>
				<Text color="red" textAlign="left" width="100%">
					{errors.password && errors.password.message}
				</Text>
				<Text color="red" textAlign="left" width="100%">
					{!errors.password &&
						errors.confirmPassword &&
						errors.confirmPassword.message}
				</Text>
				<Text color="red" textAlign="left" width="100%">
					{errors.confirmPassword &&
						errors.confirmPassword.type === "validate" &&
						"Passwords do not match."}
				</Text>
				{pwdError && (
					<Text color="red" textAlign="left" width="100%">
						The password you chose has been compromised on another website (
						<Text
							as="a"
							href="https://haveibeenpwned.com/Passwords"
							target="_blank"
							textDecor="underline"
							color="brand.link"
						>
							click here for more details
						</Text>
						). If you want to proceed with this password, click Proceed Anyway
						below. If not, please enter a new password.
					</Text>
				)}
				<Button
					buttonColor="brand.primary"
					width="100%"
					mt="40px"
					type="submit"
					isLoading={loading}
				>
					{pwdError ? "Proceed Anyway" : "Reset my password"}
				</Button>
			</Box>
		</ContainerWrapper>
	);
};

export default ResetNew;
