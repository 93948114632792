import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
	Box,
	Flex,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	ModalProps,
	Spacer,
	Text,
} from "@chakra-ui/react";
import { survivorClient } from "../service/backend";
import Input from "./Input";
import WhiteButton from "./layout/WhiteButton";
import Button from "./Button";
import { useNotification } from "./Notification/useNotification";
import Notification from "./Notification";

interface ChangeCurrentCampusModalProps extends ModalProps {
	isOpen: boolean;
	onClose: () => void;
	setEmailDomain: (domain: string) => void;
	setCampusNotFound: (notFound: boolean) => void;
}

interface ChangeCurrentCampusInputForm {
	domain: string;
	confirmDomain: string;
}

const ChangeCurrentCampusModel: React.FC<ChangeCurrentCampusModalProps> = ({
	isOpen,
	onClose,
	setEmailDomain,
	setCampusNotFound,
	...props
}: ChangeCurrentCampusModalProps) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm<ChangeCurrentCampusInputForm>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { notification, setNotification } = useNotification();

	const closeAction = () => {
		setNotification({
			type: "success",
			message: "",
		});
		onClose();
	};

	const onSubmit = async ({ domain }: ChangeCurrentCampusInputForm) => {
		setIsLoading(true);
		try {
			setEmailDomain(domain);
			const { success, campusId } = await survivorClient.lookUpCampus(domain);
			if (!success) {
				setCampusNotFound(true);
				onClose();
				return;
			}

			await survivorClient.setCurrentCampus(campusId, undefined, undefined);
			onClose();
		} catch {
			setNotification({
				type: "error",
				message:
					"There was an error looking up the campus. Please try again later.",
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeAction}
			closeOnOverlayClick={false}
			closeOnEsc={false}
			{...props}
		>
			<ModalOverlay />
			<ModalCloseButton />
			<ModalContent
				p="100px"
				maxW="850px"
				borderRadius="5px"
				borderColor="brand.primary"
				borderWidth="2px"
				color="brand.primary"
			>
				<Flex flexDir="column">
					<Box as="form" onSubmit={handleSubmit(onSubmit)} flexDir="column">
						<Notification notification={notification} />
						<Text as="p" fontSize="sm">
							To look up your current campus, we need to know the email domain
							for your email address issued by your school. We do NOT need the
							entire email address--we just need the part after the @ symbol.
						</Text>

						<Text mt="16px" mb="0">
							Email Domain
						</Text>
						<Input type="text" {...register("domain")} isRequired />

						<Text mt="16px" mb="0">
							Confirm Email Domain
						</Text>
						<Input
							type="text"
							{...register("confirmDomain", {
								validate: () =>
									getValues().domain === getValues().confirmDomain,
								required: {
									value: true,
									message: "Please confirm the email domain",
								},
							})}
							isRequired
						/>
						<Text color="red" textAlign="left" width="100%" mt="10px">
							{errors.confirmDomain &&
								errors.confirmDomain.type === "validate" &&
								"Email domains do not match."}
						</Text>

						<Flex flexDir="row" mt="3em">
							<Spacer />
							<WhiteButton onClick={closeAction}>Cancel</WhiteButton>
							<Spacer />
							<Button
								buttonColor="brand.primary"
								type="submit"
								isLoading={isLoading}
							>
								Submit
							</Button>
							<Spacer />
						</Flex>
					</Box>
				</Flex>
			</ModalContent>
		</Modal>
	);
};

export default ChangeCurrentCampusModel;
