import React from "react";
import { forwardRef, Input as ChakraInput, InputProps } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const LineInput = forwardRef<ChakraInput, InputProps>(
	(props: InputProps, ref) => {
		const { isDisabled } = props;

		const stylingProps = isDisabled
			? {
					borderBottom: "1px solid",
					borderTop: "none",
					borderLeft: "none",
					borderRight: "none",
					borderColor: "input.border",
					borderRadius: "0px",
					padding: "10px 15px",
					backgroundColor: "transparent",
					_focus: {
						borderColor: "none",
					},
				}
			: {
					border: "1px solid",
					borderColor: "input.border",
					borderRadius: "0px",
					padding: "10px 15px",
					backgroundColor: "brand.white",
					width: "150px",
					_focus: { borderColor: "brand.primary" },
				};

		return (
			<ChakraInput
				fontSize="sm"
				width="100px"
				isDisabled={isDisabled}
				ref={ref}
				{...stylingProps}
				{...props}
			/>
		);
	},
);

export default LineInput;
