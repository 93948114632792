/* eslint-disable @typescript-eslint/restrict-template-expressions,@typescript-eslint/no-unsafe-member-access */
import React, { useContext, useState } from "react";
import {
	Box,
	Button as ChakraButton,
	Flex,
	Image,
	ModalOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";

import { EntryContext } from "../contexts/EntryContext";
import { StepWhoActionTypes } from "../contexts/EntryContext/stepWho";
import SocialAccountModal from "./SocialAccountModal";

import twitterIcon from "../../assets/x-twitter.svg";
import emailIcon from "../../assets/email.svg";
import linkedinIcon from "../../assets/linkedin.svg";
import instagramIcon from "../../assets/instagram.svg";
import facebookIcon from "../../assets/facebook.svg";
import snapchatIcon from "../../assets/snapchat.svg";
import tiktokIcon from "../../assets/tiktok.svg";
import whatsappIcon from "../../assets/whatsapp.svg";
import checkIcon from "../../assets/check.svg";
import phoneIcon from "../../assets/circle-phone-thin.svg";
import discordIcon from "../../assets/discord.svg";
import redditIcon from "../../assets/reddit.svg";
import twitchIcon from "../../assets/twitch.svg";
import youtubeIcon from "../../assets/youtube.svg";
import orcidIcon from "../../assets/orcid.svg";
import googleScholarIcon from "../../assets/googlescholar.svg";
import theme from "../styles/theme";

interface ICategory {
	id: string;
	placeholder: string;
	description: string;
	label: string;
	icon: string;
	account: boolean;
}

export const socialCategories: ICategory[] = [
	{
		id: "twitter",
		placeholder: "@xHandle",
		description: "X handle",
		label: "X (Twitter)",
		icon: twitterIcon as string,
		account: true,
	},
	{
		id: "instagram",
		placeholder: "@instagramHandle",
		description: "Instagram handle",
		label: "Instagram",
		icon: instagramIcon as string,
		account: true,
	},
	{
		id: "facebook",
		placeholder: "facebook.com/URL",
		description: "Facebook URL",
		label: "Facebook",
		icon: facebookIcon as string,
		account: true,
	},
	{
		id: "linkedin",
		placeholder: "www.linkedin.com/in/URL",
		description: "LinkedIn URL",
		label: "LinkedIn",
		icon: linkedinIcon as string,
		account: true,
	},
	{
		id: "snapchat",
		placeholder: "@snapchat",
		description: "SnapChat",
		label: "SnapChat",
		icon: snapchatIcon as string,
		account: true,
	},
	{
		id: "tiktok",
		placeholder: "@TikTok",
		description: "TikTok",
		label: "TikTok",
		icon: tiktokIcon as string,
		account: true,
	},
	{
		id: "discord",
		placeholder: "@discord",
		description: "Discord Username",
		label: "Discord",
		icon: discordIcon as string,
		account: true,
	},
	{
		id: "reddit",
		placeholder: "redditUsername",
		description: "Reddit Username",
		label: "Reddit",
		icon: redditIcon as string,
		account: true,
	},
	{
		id: "twitch",
		placeholder: "twitchHandle",
		description: "Twitch Handle",
		label: "Twitch",
		icon: twitchIcon as string,
		account: true,
	},
	{
		id: "youtube",
		placeholder: "@youtubeUsername",
		description: "YouTube Username",
		label: "YouTube",
		icon: youtubeIcon as string,
		account: true,
	},
	{
		id: "whatsapp",
		placeholder: "@whatsAppHandle",
		description: "WhatsApp",
		label: "WhatsApp",
		icon: whatsappIcon as string,
		account: true,
	},
	{
		id: "phone",
		placeholder: "phone number",
		description: "Phone Number",
		label: "Phone Number",
		icon: phoneIcon as string,
		account: false,
	},
	{
		id: "email",
		placeholder: "example@example.com",
		description: "Email address",
		label: "Email",
		icon: emailIcon as string,
		account: true,
	},
	{
		id: "orcid",
		placeholder: "orcid.org/ORCID-ID",
		description: "ORCID iD",
		label: "ORCID iD",
		icon: orcidIcon as string,
		account: false,
	},
	{
		id: "googlescholar",
		placeholder: "scholar.google.com/citations?user=USERID",
		description: "Google Scholar ID",
		label: "Google Scholar",
		icon: googleScholarIcon as string,
		account: false,
	},
];

const SocialCategories: React.FC = () => {
	const {
		state: {
			steps: {
				stepWho: { identifiers },
			},
			cachedEditEntry: { perpIDs: prevIdentifiers },
			continuingDraft,
		},
		dispatch,
	} = useContext(EntryContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [currentModalInfo, setCurrentModalInfo] = useState<ICategory>(
		socialCategories[0],
	);
	const [isPhoneModalOpen, setIsPhoneModalOpen] = useState<boolean>(false);

	const handleClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		info: ICategory,
	) => {
		setCurrentModalInfo(info);
		if (info.id === "phone") {
			// This workaround is necessary due to a bug that causes the default country of the phone input to be ignored
			// when we reuse the same SocialAccountModal component
			setIsPhoneModalOpen(true);
		} else {
			onOpen();
		}
	};

	const updateIdentifiers = (id: string, _identifiers: string[]) => {
		dispatch({
			type: StepWhoActionTypes.SET_IDENTIFIERS,
			payload: {
				...identifiers,
				[id as keyof typeof identifiers]: _identifiers,
			},
		});
	};

	return (
		<>
			<Flex flexWrap="wrap" marginBottom="30px">
				{socialCategories.map((info) => {
					if (!identifiers[info.id as keyof typeof identifiers]) {
						identifiers[info.id] = [];
					}

					return (
						info.id !== "whatsapp" && (
							<SocialButton
								width={{
									base: "calc(50% - 10px)",
									md: "calc(25% - 10px)",
								}}
								onClick={(e) => handleClick(e, info)}
								key={info.id}
							>
								<Image
									src={info.icon}
									maxWidth="50px"
									height="50px"
									mt="-20px"
								/>
								<Box
									as="figcaption"
									padding="20px"
									position="absolute"
									bottom="0px"
									fontSize="sm"
								>
									<b>{info.label}</b>
								</Box>
								{identifiers[info.id as keyof typeof identifiers].length >
									0 && (
									<Flex
										position="absolute"
										width="100%"
										height="100%"
										left="0"
										top="0"
										backgroundColor="rgba(209, 231, 231, 0.33)"
										justifyContent="flex-end"
										alignItems="flex-start"
										borderRadius="8px"
										border={`3px solid ${theme.colors.brand.primary}`}
									>
										<Image
											src={checkIcon as string}
											justifySelf="flex-end"
											width="38px"
											p="7px"
										/>
									</Flex>
								)}
							</SocialButton>
						)
					);
				})}
			</Flex>

			<SocialAccountModal
				isOpen={isOpen}
				onClose={onClose}
				data={identifiers[currentModalInfo.id as keyof typeof identifiers]}
				prevValues={
					(prevIdentifiers &&
						!continuingDraft &&
						prevIdentifiers[
							currentModalInfo.id as keyof typeof prevIdentifiers
						]) ||
					[]
				}
				update={updateIdentifiers}
				{...currentModalInfo}
			>
				<ModalOverlay />
			</SocialAccountModal>

			<SocialAccountModal
				isOpen={isPhoneModalOpen}
				onClose={() => {
					setIsPhoneModalOpen(false);
				}}
				data={identifiers[currentModalInfo.id as keyof typeof identifiers]}
				prevValues={
					(prevIdentifiers &&
						!continuingDraft &&
						prevIdentifiers[
							currentModalInfo.id as keyof typeof prevIdentifiers
						]) ||
					[]
				}
				update={updateIdentifiers}
				{...currentModalInfo}
			>
				<ModalOverlay />
			</SocialAccountModal>
		</>
	);
};

export default SocialCategories;

const SocialButton = styled(ChakraButton)`
  height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0.5em;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
  border: none;
  font-size: 16px;
  box-shadow: 0 8px 20px rgb(0 0 0 / 5%);
  &:hover {
    transform: none !important;
    img {
      transform: scale(1.1);
    }
  }
  &:focus {
    outline: none;
  }
  img {
    transition: 0.5s;
  }
`;
