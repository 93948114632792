import React, { ChangeEvent, useContext } from "react";
import { IWhereFormProps } from "./index";
import HeaderText from "../../components/HeaderText";
import SubHeader from "../../components/SubHeader";
import CheckboxRadio from "../../components/CheckboxRadio";
import { OrfContext, PLACE_VALUES } from "../../contexts/OrfContext";
import Checkbox from "../../components/CheckBox";
import LineInput from "../../components/LineInput";
import Textarea from "../../components/Textarea";
import { Box, Flex, Input, VStack } from "@chakra-ui/react";
import { OrfQuestion } from "../../components/OrfQuestion";
import { PdfTextarea } from "../../components/PdfTextarea";
import { onlineCheckboxProps } from "../Orfs";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";

interface WherePageContentProps {
	values: IWhereFormProps;
	pdf?: boolean;
	setValue: UseFormSetValue<IWhereFormProps>;
	register: UseFormRegister<IWhereFormProps>;
	textWidth?: string;
}

export const OrfWherePageContent: React.FC<WherePageContentProps> = ({
	values,
	setValue,
	register,
	pdf = false,
	textWidth,
}: WherePageContentProps) => {
	const { setModified } = useContext(OrfContext);
	const checkboxProps = onlineCheckboxProps;
	const { onChange: onChangeMemory } = register("memory");

	const examples =
		"Examples: exact address, area of campus, intersection, building number, " +
		"neighborhood, buildings or trees nearby, colors you remember, etc.";

	return (
		<div style={{ width: textWidth }}>
			<HeaderText pdf={pdf}>Where</HeaderText>

			<SubHeader mb="5px" pdf={pdf}>
				<OrfQuestion pdf={pdf}>Where did it happen?</OrfQuestion>
			</SubHeader>
			<CheckboxRadio
				value={values.place}
				setValue={(v) => setValue("place", v)}
			>
				<VStack alignItems="flex-start">
					{!pdf && (
						<>
							{PLACE_VALUES.map((v) => (
								<Checkbox
									value={v}
									key={v}
									onChange={setModified}
									{...checkboxProps}
								>
									{v}
								</Checkbox>
							))}
						</>
					)}
					{pdf && (
						<>
							{PLACE_VALUES.map((v) => (
								<Box as="div" key={`${v}-div`} fontSize="sm">
									<label key={`${v}-label`}>
										<input
											key={v}
											type="checkbox"
											checked={values.place === v}
											readOnly={true}
											className="pdf"
										/>{" "}
										{v}
									</label>
								</Box>
							))}
						</>
					)}
					<Flex>
						{!pdf && (
							<>
								<Checkbox
									value="Other"
									onChange={setModified}
									{...checkboxProps}
									mr="10px"
								>
									Other
								</Checkbox>
								<LineInput
									isDisabled={values.place !== "Other"}
									{...register("exactPlace")}
									name="exactPlace"
									isRequired={values.place === "Other"}
									onChange={setModified}
								/>
							</>
						)}
						{pdf && (
							<>
								<Box as="div" fontSize="sm">
									<label>
										<input
											type="checkbox"
											checked={values.place === "Other"}
											readOnly={true}
											className="pdf"
										/>{" "}
										Other
									</label>
								</Box>
								<Input
									{...register("exactPlace")}
									defaultValue={values.exactPlace}
									readOnly
									size="sm"
									width="auto"
									borderColor="black"
									ml="5px"
									mt="10px"
								/>
							</>
						)}
					</Flex>
				</VStack>
			</CheckboxRadio>

			<SubHeader mb="5px" mt={pdf ? "15px" : "30px"}>
				<OrfQuestion pdf={pdf}>
					Are there any other details you remember about the location?
				</OrfQuestion>
			</SubHeader>
			{(!pdf || !values.memory) && (
				<Textarea
					placeholder={examples}
					{...register("memory")}
					onChange={async (e: ChangeEvent<HTMLTextAreaElement>) => {
						await onChangeMemory(e);
						setModified();
					}}
				/>
			)}
			{pdf && values.memory && <PdfTextarea>{values.memory}</PdfTextarea>}
		</div>
	);
};
