import React, { useState } from "react";
import {
	Flex,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
	useToast,
} from "@chakra-ui/react";
import Button from "./Button";
import WhiteButton from "./layout/WhiteButton";
import { useVerifyRecoveryEmailUpdate } from "../service/hooks/auth";

interface VerifyRecoveryEmailModalProps {
	isOpen: boolean;
	onClose: () => void;
	emailAddress: string;
	phoneNumber: string;
}

const VerifyRecoveryEmailModal: React.FC<VerifyRecoveryEmailModalProps> = ({
	isOpen,
	onClose,
	emailAddress,
	phoneNumber,
}: VerifyRecoveryEmailModalProps) => {
	const toast = useToast();
	const [loading, setLoading] = useState<boolean>(false);
	const { mutateAsync: verifyRecoveryEmail } = useVerifyRecoveryEmailUpdate();

	const handleSend = async () => {
		setLoading(true);
		try {
			await verifyRecoveryEmail({
				email: emailAddress.toLowerCase().trim(),
				phone: phoneNumber,
			});
			toast({
				status: "success",
				title: "Verification email has been sent",
				isClosable: true,
				position: "top",
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
			<ModalOverlay />
			<ModalContent
				maxW="650px"
				padding="40px 40px 36px 40px"
				borderRadius="0px"
				color="brand.primary"
			>
				<ModalCloseButton />
				<Text as="h2" fontSize="md" textAlign="center">
					Email Verification Needed
				</Text>
				<Text as="p" fontSize="sm" textAlign="left">
					We have detected a change to your profile email address. It is
					important for your security that we verify the new email address is
					yours, so we will send a verification link to {emailAddress}. Please
					click the link in that email and then enter your username and password
					to verify the address.
					<i>
						Your profile information will not be updated until you verify the
						change in email address!
					</i>
				</Text>
				<Text as="p" fontSize="sm" textAlign="left">
					Your verification email may take up to 5 minutes to arrive in your
					inbox. Be sure to also check your spam folder.
				</Text>
				<Flex flexDir="row" alignItems="center" justifyContent="center">
					<WhiteButton onClick={onClose} height="auto">
						Cancel
					</WhiteButton>
					<Button
						buttonColor="brand.primary"
						onClick={handleSend}
						mx="15px"
						isLoading={loading}
					>
						Send verification email
					</Button>
				</Flex>
			</ModalContent>
		</Modal>
	);
};

export default VerifyRecoveryEmailModal;
