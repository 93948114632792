import React, { SyntheticEvent, useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import AnimatedBox from "../../components/layout/AnimatedBox";
import { OrfContext } from "../../contexts/OrfContext";
import OrfActionButtons from "../../components/OrfActionButtons";
import { PrivateRoutes } from "../../config/routes";
import MainCard from "../../components/MainCard";
import { wait } from "../../service/backend";
import { IFormProps, usePersistData } from "../../service/hooks/usePersistData";
import { handleORFSubmit } from "../../components/layout/sidebar/CreateEditSidebar";
import { OrfIncidentsPageContent } from "./OrfIncidentsPageContent";
import { useNavigate } from "react-router-dom";

export interface IIncidentsFormProps {
	whatHappened: string;
}

const OrfIncidents: React.FC = () => {
	const navigate = useNavigate();
	const {
		state: { steps, mode, currentRecordId, changesMade },
		completeAction,
		setSavingScreenVisibility,
		updateORF,
	} = useContext(OrfContext);
	const { incidents } = steps;
	const formRef = useRef<HTMLFormElement>(null);

	useEffect(() => {
		const keypressListener = (e: KeyboardEvent) => {
			if (
				document.activeElement &&
				Array.from(document.getElementsByTagName("textarea")).includes(
					document.activeElement as HTMLTextAreaElement,
				)
			) {
				return;
			}

			if (e.key === "Enter") {
				e.preventDefault();
			}
		};

		if (formRef.current) {
			formRef.current.addEventListener("keypress", keypressListener);
		}

		return () => {
			if (formRef.current) {
				formRef.current.removeEventListener("keypress", keypressListener);
			}
		};
	}, []);

	const { handleSubmit, register, watch } = useForm<IIncidentsFormProps>({
		defaultValues: {
			whatHappened: incidents.whatHappened,
		},
	});

	const generatePayload = ({ whatHappened }: IIncidentsFormProps) => ({
		whatHappened,
	});

	const saveForm = (formProps: IIncidentsFormProps) => {
		if (changesMade) {
			completeAction("incidents", generatePayload(formProps));
		}
	};

	const setWatchValuesRef = usePersistData(saveForm);

	useEffect(() => {
		setWatchValuesRef(watch() as IFormProps);
	});

	const onContinue = () => {
		navigate(incidents.nextStepRoute);
	};

	const handleSaveAndExit = async (e: SyntheticEvent) => {
		e.preventDefault();

		setSavingScreenVisibility(true);
		await wait(1000);
		const updatedIncidents = generatePayload(watch());
		const updatedSteps = {
			...steps,
			incidents: {
				...incidents,
				...updatedIncidents,
				completed: !!updatedIncidents.whatHappened,
			},
		};

		await handleORFSubmit(mode, updatedSteps, updateORF, currentRecordId);
		setSavingScreenVisibility(false);
	};

	return (
		<AnimatedBox>
			<MainCard>
				<Box as="form" onSubmit={handleSaveAndExit} id="orf_form" ref={formRef}>
					<OrfIncidentsPageContent values={watch()} register={register} />
					<OrfActionButtons
						backRoute={PrivateRoutes.ORF_CONSENT}
						handleClick={handleSubmit(onContinue)}
					/>
				</Box>
			</MainCard>
		</AnimatedBox>
	);
};

export default OrfIncidents;
