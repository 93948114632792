import React, { FormEvent, SyntheticEvent, useContext, useEffect } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import * as emailValidator from "email-validator";

import HeaderText from "../../components/HeaderText";
import SubHeader from "../../components/SubHeader";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import Checkbox from "../../components/CheckBox";
import Button from "../../components/Button";
import AnimatedBox from "../../components/layout/AnimatedBox";
import {
	EntryContext,
	EntryGlobalActionTypes,
} from "../../contexts/EntryContext";
import {
	IContactInfoForm,
	StepContactActionTypes,
} from "../../contexts/EntryContext/stepContact";
import { survivorClient } from "../../service/backend";
import MainCard from "../../components/MainCard";
import WhiteButton from "../../components/layout/WhiteButton";
import { entryData } from "../../../../../../lib/data";
import { usStates } from "../CreateWhere/constant";
import { PrivateRoutes } from "../../config/routes";
import { usePersistContactInfo } from "../../service/hooks/usePersistContactInfo";
import Textarea from "../../components/Textarea";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";

const ContactInfo: React.FC = () => {
	const {
		state: {
			steps: {
				stepContact: {
					name,
					preferredLanguage,
					accommodationsNeeded,
					email,
					phone,
					checked,
					nextStepRoute,
				},
				stepWhere,
				stepWho,
			},
			editEntryID,
		},
		dispatch,
	} = useContext(EntryContext);
	const navigate = useNavigate();
	const {
		handleSubmit,
		register,
		watch,
		formState: { errors },
		getValues,
		setValue,
	} = useForm<IContactInfoForm>({
		defaultValues: {
			name: name
				? name
				: survivorClient.contactInfo
					? survivorClient.contactInfo.name
					: undefined,
			checked,
			phone: phone
				? phone
				: survivorClient.contactInfo
					? survivorClient.contactInfo.phone
					: undefined,
			confirmPhone: phone
				? phone
				: survivorClient.contactInfo
					? survivorClient.contactInfo.phone
					: undefined,
			email: email
				? email
				: survivorClient.contactInfo
					? survivorClient.contactInfo.email
					: undefined,
			confirmEmail: email
				? email
				: survivorClient.contactInfo
					? survivorClient.contactInfo.email
					: undefined,
			preferredLanguage:
				preferredLanguage ?? survivorClient.contactInfo?.preferredLanguage,
			accommodationsNeeded:
				accommodationsNeeded ??
				survivorClient.contactInfo?.accommodationsNeeded,
		},
	});
	const formValues = watch();
	const toast = useToast();

	const onFormUnmounting = (data: IContactInfoForm) => {
		if (
			(data.checked.phone || data.checked.email) &&
			(!data.checked.phone ||
				(data.checked.phone &&
					isPossiblePhoneNumber(data.phone) &&
					data.phone === data.confirmPhone)) &&
			(!data.checked.email ||
				(data.checked.email &&
					emailValidator.validate(data.email) &&
					data.email === data.confirmEmail))
		) {
			saveData(data);
		} else {
			dispatch({
				type: StepContactActionTypes.UNSET_CONTACT,
			});
		}
	};

	const setValues = usePersistContactInfo(onFormUnmounting);

	useEffect(() => {
		setValues(watch());
	});

	useEffect(() => {
		setValue(
			"name",
			name
				? name
				: survivorClient.contactInfo?.name
					? survivorClient.contactInfo.name
					: "",
		);
		setValue(
			"phone",
			phone
				? phone
				: survivorClient.contactInfo?.phone
					? survivorClient.contactInfo.phone
					: "",
		);
		setValue(
			"confirmPhone",
			phone
				? phone
				: survivorClient.contactInfo?.phone
					? survivorClient.contactInfo.phone
					: "",
		);
		setValue(
			"email",
			email
				? email
				: survivorClient.contactInfo?.email
					? survivorClient.contactInfo.email
					: "",
		);
		setValue(
			"confirmEmail",
			email
				? email
				: survivorClient.contactInfo?.email
					? survivorClient.contactInfo.email
					: "",
		);
		setValue(
			"preferredLanguage",
			preferredLanguage ?? survivorClient.contactInfo?.preferredLanguage ?? "",
		);
		setValue(
			"accommodationsNeeded",
			accommodationsNeeded ??
				survivorClient.contactInfo?.accommodationsNeeded ??
				"",
		);
	}, []);

	const onClickNext = (data: IContactInfoForm) => {
		saveData(data);
		navigate(nextStepRoute);
	};

	const saveData = (data: IContactInfoForm) => {
		dispatch({
			type: StepContactActionTypes.SET_CONTACT,
			payload: data,
		});
	};

	const onSaveDraft = async (event: FormEvent<any>) => {
		event.preventDefault();
		const draftEntry: entryData = {
			id: editEntryID ?? undefined,
			userID: survivorClient.userID,
			incidentState: usStates[stepWhere.selectedState],
			perpIDs: stepWho.identifiers,
		};

		await survivorClient.saveDraftEntry(draftEntry);
		toast({
			title: "Your entry was saved as a draft.",
			status: "success",
			position: "top",
		});
		dispatch({
			type: EntryGlobalActionTypes.INITIALIZE_ENTRY,
		});
		navigate(PrivateRoutes.RESOURCES, {
			state: {
				target: PrivateRoutes.MATCHING_SYSTEM,
				text: "Matching System",
			},
		});
	};

	return (
		<AnimatedBox>
			<Box as="form" onSubmit={onSaveDraft} id="entry_form">
				<MainCard>
					<HeaderText>
						In case of a Match, please provide your contact information.
					</HeaderText>
					<SubHeader>
						If we find a survivor with the same offender, you will be contacted
						by a Legal Options Counselor (LOC) at no cost to you. The Legal
						Options Counselor is NOT employed by Callisto and will be the only
						person who sees this information. The LOC will reach out to you via
						the preferred contact you list below. They will reach out to you
						twice using this method. If you do not respond, they will close your
						case.
					</SubHeader>
					<SubHeader>Tips when choosing contact information:</SubHeader>
					<ul className="inlineList">
						<li>
							Use an email/phone number that will likely not change (you can
							update it if it does change)
						</li>
						<li>Use something that you check on a regular basis</li>
						<li>Choose a method that is private</li>
					</ul>

					<Input
						placeholder="Full Name*"
						width="100%"
						fontSize="xs"
						{...register("name", {
							required: { value: true, message: "Please enter full name" },
						})}
						mb="20px"
					/>
					<Text as="p" fontSize="sm" my="16px">
						<b>What is your preferred language?</b> (optional)
					</Text>
					<Input
						width="100%"
						fontSize="xs"
						{...register("preferredLanguage")}
						mb="20px"
					/>
					<Text as="p" fontSize="sm" my="16px">
						<b>
							Callisto is dedicated to ensuring inclusive services for survivors
							with disabilities. We are fully committed to providing
							accommodations tailored to individual needs. Kindly specify any
							accommodations you require in the space provided below and we will
							do our best to accommodate them.
						</b>{" "}
						(optional)
					</Text>
					<Textarea
						width="100%"
						fontSize="xs"
						borderColor="input.border"
						backgroundColor="brand.brightWhite"
						height="100px"
						{...register("accommodationsNeeded")}
						mb="20px"
					/>
					<Text as="p" fontSize="sm" my="16px">
						<b>What is your preferred method of contact?</b>
					</Text>
					<Checkbox
						py="10px"
						{...register("checked.phone", {
							required: !formValues.checked.email,
							onChange: (e: SyntheticEvent<HTMLInputElement>) => {
								setValue("checked.phone", e.currentTarget.checked);
								if (!e.currentTarget.checked) {
									setValue("phone", survivorClient.contactInfo?.phone ?? "");
									setValue(
										"confirmPhone",
										survivorClient.contactInfo?.phone ?? "",
									);
								}
							},
						})}
					>
						Phone
					</Checkbox>
					<Checkbox
						py="10px"
						{...register("checked.email", {
							required: !formValues.checked.phone,
							onChange: (e: SyntheticEvent<HTMLInputElement>) => {
								setValue("checked.email", e.currentTarget.checked);
								if (!e.currentTarget.checked) {
									setValue("email", survivorClient.contactInfo?.email ?? "");
									setValue(
										"confirmEmail",
										survivorClient.contactInfo?.email ?? "",
									);
								}
							},
						})}
					>
						Email
					</Checkbox>
					<Box
						display={
							!formValues.checked.phone && !formValues.checked.email
								? "none"
								: "block"
						}
					>
						<Text as="p" my="16px">
							<b>Enter your contact information</b>
						</Text>
						{formValues.checked.phone && (
							<Box as="div">
								<label htmlFor="phoneInput">Phone number</label>
								<PhoneNumberInput
									value={formValues.phone}
									isDisabled={!formValues.checked.phone}
									onChange={(value?: string) => {
										setValue("phone", value ?? "");
									}}
								/>
								<label htmlFor="confirmPhoneInput">Re-enter phone number</label>
								<PhoneNumberInput
									value={formValues.confirmPhone}
									isDisabled={!formValues.checked.phone}
									onChange={(value?: string) => {
										setValue("confirmPhone", value ?? "");
									}}
								/>
							</Box>
						)}
						<Box as="div" display={formValues.checked.email ? "block" : "none"}>
							<label htmlFor="emailInput">Email</label>
							<Input
								id="emailInput"
								display={formValues.checked.email ? "block" : "none"}
								placeholder="Contact email address*"
								type="email"
								{...register("email", {
									validate: () =>
										!formValues.checked.email ||
										emailValidator.validate(getValues().email),
								})}
							/>
							<label htmlFor="confirmEmailInput">Re-enter email</label>
							<Input
								id="confirmEmailInput"
								display={formValues.checked.email ? "block" : "none"}
								placeholder="Confirm email address*"
								type="email"
								{...register("confirmEmail", {
									validate: () =>
										!formValues.checked.email ||
										getValues().confirmEmail === getValues().email,
								})}
							/>
						</Box>
					</Box>
					<Text color="red" mt="5px">
						{errors?.name?.message}
					</Text>
					{formValues.checked.phone && errors?.phone?.type === "validate" && (
						<Text color="red">Please enter a valid U.S. phone number</Text>
					)}
					{formValues.checked.phone &&
						errors?.confirmPhone?.type === "validate" && (
							<Text color="red">Phone numbers do not match</Text>
						)}
					{formValues.checked.email && errors?.email?.type === "validate" && (
						<Text color="red">Please enter a valid email</Text>
					)}
					{formValues.checked.email &&
						errors?.confirmEmail?.type === "validate" && (
							<Text color="red">Email addresses do not match</Text>
						)}
					{errors?.checked?.email?.type === "required" &&
						errors?.checked?.phone?.type === "required" && (
							<Text color="red">Please choose a preferred contact method</Text>
						)}
					<Button
						buttonColor="brand.primary"
						width="100%"
						type="button"
						mt="16px"
						onClick={handleSubmit(onClickNext)}
					>
						Review my entry
					</Button>
					<WhiteButton
						width="100%"
						margin="20px 20px 20px 0px"
						type="button"
						onClick={onSaveDraft}
					>
						Save My Entry as a Draft
					</WhiteButton>
				</MainCard>
			</Box>
		</AnimatedBox>
	);
};

export default ContactInfo;
