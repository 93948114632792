import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Flex, ModalOverlay, Text, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import ContainerWrapper from "../../components/ContainerWrapper";
import Input from "../../components/Input";
import Button from "../../components/Button";
import {
	useCheckPasswordStrength,
	useCreateAccount,
	useGenerateBackupCodes,
} from "../../service/hooks/auth";
import DownloadCodeModal from "../../components/DownloadCodeModal";
import { CallistoRoutes, PrivateRoutes } from "../../config/routes";
import { survivorClient, wait } from "../../service/backend";
import CampusRecord from "../../components/CampusRecord";
import HaveAccount from "../../components/HaveAccount";
import CheckBox from "../../components/CheckBox";
import GiveFeedbackLink from "../../components/GiveFeedbackLink";

interface ICreateAccountForm {
	username: string;
	password: string;
	confirmPassword: string;
	confirmEmailAddress?: string;
	campusName?: string;
}

const SignUpCredentials: React.FC = () => {
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<ICreateAccountForm>();
	const navigate = useNavigate();
	const {
		state,
	}: {
		state: {
			token: string;
			campusIdentified: boolean;
			emailDomain?: string;
			email?: string;
		};
	} = useLocation() as unknown as {
		state: {
			token: string;
			campusIdentified: boolean;
			emailDomain?: string;
			email?: string;
		};
	};
	const [pwdError, setPwdError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [backupLoading, setBackupLoading] = useState<boolean>(false);
	const [backupCodes, setBackupCodes] = useState<string[]>([]);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [privacyPolicyAccepted, setPrivacyPolicyAccepted] =
		useState<boolean>(false);
	const [privacyPolicyError, setPrivacyPolicyError] = useState<boolean>(false);
	const [is18OrOlder, setIs18OrOlder] = useState<boolean>(false);
	const [ageConfirmationError, setAgeConfirmationError] =
		useState<boolean>(false);
	const { mutateAsync: requestCheckPasswordStrength } =
		useCheckPasswordStrength();
	const { mutateAsync: requestCreateAccount } = useCreateAccount();
	const { mutateAsync: requestGenerateBackupCodes } = useGenerateBackupCodes();

	const toast = useToast();

	useEffect(() => {
		if (!state || !state.token) {
			toast({
				title:
					"The page was reloaded. Please try clicking the link in your email again.",
				status: "error",
				isClosable: true,
				position: "top",
			});
		}
	}, []);

	const onSubmit = async ({
		password,
		username,
		campusName,
	}: ICreateAccountForm) => {
		setLoading(true);
		if (!(is18OrOlder && privacyPolicyAccepted)) {
			if (!is18OrOlder) {
				setAgeConfirmationError(true);
			}

			if (!privacyPolicyAccepted) {
				setPrivacyPolicyError(true);
			}

			setLoading(false);
			return;
		}

		if (!pwdError) {
			try {
				await requestCheckPasswordStrength({ password });
			} catch (e) {
				await survivorClient.incrementCountUnencrypted(
					"Weak Password Detected: Account Creation",
					state.token,
				);
				await survivorClient.writeWeakPasswordUnusedToken(state.token);
				setPwdError(true);
				setLoading(false);
				return;
			}
		} else {
			await survivorClient.incrementCountUnencrypted(
				"Weak Password Warning Bypassed: Account Creation",
				state.token,
			);
		}
		try {
			await wait(500);
			await requestCreateAccount({
				username,
				password,
				token: state.token,
				privacyPolicyAccepted,
				campusName,
				emailDomain: state.emailDomain,
			});
			setLoading(false);
			setBackupLoading(true);
			setShowModal(true);
			await wait(500);
			const newBackupCodes = await requestGenerateBackupCodes();
			setBackupCodes(newBackupCodes);
			setBackupLoading(false);
		} catch (e) {
			toast({
				title: (e as Error).message,
				position: "top",
				status: "error",
			});
			setLoading(false);
		}
	};

	const handleCloseModal = () => {
		setShowModal(false);
		toast({
			title: "Account created. Welcome to Callisto!",
			position: "top",
			status: "success",
		});
		navigate(PrivateRoutes.RECOVERY_SETUP, {
			state: {
				email: state.email,
			},
		});
	};

	const handleChangePassword = async () => {
		if (pwdError) {
			setPwdError(false);
			await survivorClient.incrementCountUnencrypted(
				"Weak Password Overridden: Account Creation",
				state.token,
			);
		}
	};

	return (
		<ContainerWrapper>
			<Flex
				alignItems="center"
				justifyContent="center"
				margin="0 auto"
				padding="0 0 25px 0"
			>
				<Box
					width="850px"
					backgroundColor="brand.white"
					padding={["20px 20px", "40px 20px", "60px 120px"]}
				>
					<Flex
						maxWidth="650px"
						flexDir="column"
						textAlign="left"
						justifyContent="center"
						alignItems="center"
						height="100%"
						margin="0 auto"
					>
						<Text as="h1" fontSize="xl">
							<b>Create Your Account</b>
						</Text>
						<Text mt="16px">
							Our encryption technology is designed to prioritize survivor
							privacy every step of the way. That means we will never, ever have
							access to any of your personal identifiable information.
						</Text>
						<Text my="16px">
							<b>
								Please keep this information in a safe place as Callisto is
								unable to recover forgotten usernames and passwords.
							</b>
						</Text>
						<Box as="form" my="20px" onSubmit={handleSubmit(onSubmit)}>
							<Text mb="16px">
								For security and safety reasons, we suggest that you choose a
								username you have not used before and that is not identifiable,
								but that you will remember. For example, if your name is Sara
								Smith, avoid choosing the usernames SaraSmith, SSmith, SaraS,
								SaraSara, orSmithSmith.
							</Text>
							<Text mb="16px">
								<b>
									Once the account is created, your username cannot be changed.
								</b>
							</Text>
							<Input
								placeholder="Username*"
								{...register("username", {
									required: {
										value: true,
										message: "Please enter username",
									},
								})}
								mb="30px"
							/>
							<Input
								placeholder="Create password 8+ characters"
								{...register("password", {
									minLength: {
										value: 8,
										message:
											"This password is too short, please use at least 8 characters",
									},
									required: {
										value: true,
										message: "Please enter a password.",
									},
								})}
								onChange={handleChangePassword}
								type="password"
								mb="30px"
							/>
							<Input
								placeholder="Confirm password*"
								type="password"
								{...register("confirmPassword", {
									validate: () =>
										getValues().password === getValues().confirmPassword,
									required: {
										value: true,
										message: "Please confirm your password.",
									},
								})}
								mb="30px"
							/>
							<Text color="red" textAlign="left" width="100%" mt="-20px">
								{errors.username && errors.username.message}
							</Text>
							<Text color="red" textAlign="left" width="100%">
								{errors.password && errors.password.message}
							</Text>
							<Text color="red" textAlign="left" width="100%">
								{errors.confirmPassword &&
									errors.confirmPassword.type === "validate" &&
									"Passwords do not match."}
							</Text>
							{!state.campusIdentified && (
								<>
									<Text textAlign="left" width="100%">
										Please enter the full name of your US based university or
										college (ex: University of Southern California rather than
										USC).
									</Text>
									<Input
										placeholder="College or University*"
										{...register("campusName", {
											required: {
												value: true,
												message:
													"Please enter the name of your US based college or university",
											},
										})}
										mb="30px"
									/>

									<Text color="red" textAlign="left" width="100%">
										{errors.campusName && errors.campusName.message}
									</Text>
								</>
							)}
							<div style={{ marginTop: "16px" }}>
								<CheckBox
									backgroundColor="brand.brightWhite"
									onChange={() => {
										setAgeConfirmationError(false);
										setIs18OrOlder(!is18OrOlder);
									}}
								>
									{" "}
								</CheckBox>
								I am at least 18 years of age.
							</div>
							{ageConfirmationError && (
								<Text color="red" textAlign="left" width="100%">
									Please confirm that you are 18 years of age or older.
								</Text>
							)}
							<div style={{ marginTop: "16px" }}>
								<CheckBox
									backgroundColor="brand.brightWhite"
									onChange={() => {
										setPrivacyPolicyError(false);
										setPrivacyPolicyAccepted(!privacyPolicyAccepted);
									}}
								>
									{" "}
								</CheckBox>
								I have read the{" "}
								<Text
									as="a"
									href={CallistoRoutes.PRIVACY_POLICY}
									target="_blank"
									color="brand.link"
									textDecor="underline"
									fontFamily="Avenir"
									fontWeight="bold"
								>
									Callisto Vault Privacy Policy
								</Text>{" "}
								and I accept its terms
							</div>
							{privacyPolicyError && (
								<Text color="red" textAlign="left" width="100%">
									Please accept the terms of the privacy policy
								</Text>
							)}
							{pwdError && (
								<Text color="red" textAlign="left" width="100%">
									The password you chose has been compromised on another website
									(
									<Text
										as="a"
										href="https://haveibeenpwned.com/Passwords"
										target="_blank"
										textDecor="underline"
										color="brand.link"
									>
										click here for more details
									</Text>
									). If you want to proceed with this password, click Proceed
									Anyway below. If not, please enter a new password.
								</Text>
							)}
							{pwdError ? (
								<Button
									buttonColor="brand.primary"
									width="100%"
									mt="30px"
									type="submit"
									isLoading={loading}
									isDisabled={!privacyPolicyAccepted || !is18OrOlder}
								>
									Proceed anyway
								</Button>
							) : (
								<Button
									buttonColor="brand.primary"
									width="100%"
									mt="30px"
									type="submit"
									isLoading={loading}
									isDisabled={!privacyPolicyAccepted || !is18OrOlder}
								>
									Create an account
								</Button>
							)}
							<CampusRecord />
							<HaveAccount />
							<a
								href={CallistoRoutes.TERMS_OF_SERVICE}
								target="_blank"
								rel="noreferrer"
								style={hyperLinkStyles}
							>
								<Box>Terms of Service</Box>
							</a>
							<GiveFeedbackLink />
						</Box>
					</Flex>
				</Box>
			</Flex>
			<DownloadCodeModal
				isOpen={showModal}
				onClose={handleCloseModal}
				backupCodes={backupCodes}
				loading={backupLoading}
			>
				<ModalOverlay />
			</DownloadCodeModal>
		</ContainerWrapper>
	);
};

export default SignUpCredentials;

const hyperLinkStyles = {
	display: "grid",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	marginBottom: "3px",
	fontFamily: "Avenir",
	fontWeight: "bold" as const,
	fontSize: "16px",
	textDecoration: "underline",
	padding: "0px 15px",
	overflow: "hidden",
};
