import React from "react";

const CloseIcon: React.FC = () => {
	const path =
		"M18.5502 15.3945C17.5849 14.4292 16.6196 13.4639 15.6543 12.5014C16.5965 11.5592 17.5329 10.6228 " +
		"18.5589 9.59682C19.1427 9.01301 19.1456 8.22688 18.5647 7.64306C18.215 7.29335 17.8681 6.94364 17.5184 " +
		"6.59682L17.3681 6.44653C16.7699 5.85116 16.0011 5.85116 15.4028 6.44653L14.0965 7.75289C13.5647 8.28468 13.0329 " +
		"8.81647 12.5011 9.34827C11.452 8.29624 10.4953 7.34249 9.59357 6.44075C9.01265 5.85983 8.22652 5.85983 7.6427 " +
		"6.44364C7.33923 6.74422 7.03866 7.0448 6.73808 7.34538L6.47507 7.60838C5.84212 8.24133 5.84212 8.99277 6.47218 " +
		"9.62572L9.34791 12.5014C8.33634 13.5101 7.32189 14.5275 6.43461 15.4147C5.85369 15.9957 5.85658 16.776 6.4375 " +
		"17.3569C6.83634 17.7558 7.23519 18.1546 7.63403 18.5535C7.93172 18.8512 8.27565 19.0014 8.61669 19.0014C8.95773 " +
		"19.0014 9.29877 18.854 9.59646 18.5564C10.134 18.0188 10.6716 17.4812 11.2092 16.9436C11.6398 16.513 12.0676 " +
		"16.0853 12.4982 15.6546L12.5415 15.698C13.4924 16.6488 14.4433 17.5997 15.3941 18.5535C15.7612 18.9205 16.1283 " +
		"18.9986 16.3739 18.9986C16.6196 19.0014 16.9924 18.9234 17.3595 18.5564C17.7901 18.1257 18.2207 17.698 18.5473 " +
		"17.3685C19.1514 16.7702 19.1514 15.9928 18.5502 15.3945Z";

	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="12.5" cy="12.5" r="12.5" fill="white" />
			<path d={path} fill="#00375B" />
		</svg>
	);
};

export default CloseIcon;
