import React, { SyntheticEvent, useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import AnimatedBox from "../../components/layout/AnimatedBox";
import OrfActionButtons from "../../components/OrfActionButtons";
import { PrivateRoutes } from "../../config/routes";
import { OrfContext } from "../../contexts/OrfContext";
import MainCard from "../../components/MainCard";
import { wait } from "../../service/backend";
import { IFormProps, usePersistData } from "../../service/hooks/usePersistData";
import { handleORFSubmit } from "../../components/layout/sidebar/CreateEditSidebar";
import { OrfConsentPageContent } from "./OrfConsentPageContent";
import { useNavigate } from "react-router-dom";

export interface IConsentFormProps {
	reasons: string[];
}

const OrfConsent: React.FC = () => {
	const navigate = useNavigate();
	const {
		state: { steps, mode, currentRecordId, changesMade },
		completeAction,
		updateORF,
		setSavingScreenVisibility,
	} = useContext(OrfContext);
	const { consent } = steps;
	const formRef = useRef<HTMLFormElement>(null);

	const { handleSubmit, register, setValue, watch } =
		useForm<IConsentFormProps>({
			defaultValues: {
				reasons: consent.reasons,
			},
		});

	useEffect(() => {
		register("reasons");

		const keypressListener = (e: KeyboardEvent) => {
			if (
				document.activeElement &&
				Array.from(document.getElementsByTagName("textarea")).includes(
					document.activeElement as HTMLTextAreaElement,
				)
			) {
				return;
			}

			if (e.key === "Enter") {
				e.preventDefault();
			}
		};

		if (formRef.current) {
			formRef.current.addEventListener("keypress", keypressListener);
		}

		return () => {
			if (formRef.current) {
				formRef.current.removeEventListener("keypress", keypressListener);
			}
		};
	}, []);

	const generatePayload = ({ reasons }: IConsentFormProps) => ({
		reasons,
	});

	const saveForm = (formProps: IConsentFormProps) => {
		if (changesMade) {
			completeAction("consent", generatePayload(formProps));
		}
	};

	const setWatchValuesRef = usePersistData(saveForm);

	useEffect(() => {
		setWatchValuesRef(watch() as IFormProps);
	});

	const onContinue = () => {
		navigate(consent.nextStepRoute);
	};

	const handleReasonChange = (values: string[]) => {
		setValue("reasons", values);
	};

	const handleSaveAndExit = async (e: SyntheticEvent) => {
		e.preventDefault();

		setSavingScreenVisibility(true);
		await wait(1000);
		const updatedConsent = generatePayload(watch());
		const updatedSteps = {
			...steps,
			consent: {
				...consent,
				...updatedConsent,
				completed: updatedConsent.reasons.length > 0,
			},
		};

		await handleORFSubmit(mode, updatedSteps, updateORF, currentRecordId);
		setSavingScreenVisibility(false);
	};

	return (
		<AnimatedBox>
			<MainCard>
				<Box as="form" onSubmit={handleSaveAndExit} id="orf_form" ref={formRef}>
					<OrfConsentPageContent
						values={watch()}
						handleReasonChange={handleReasonChange}
					/>
					<OrfActionButtons
						backRoute={PrivateRoutes.ORF_KNOW}
						handleClick={handleSubmit(onContinue)}
					/>
				</Box>
			</MainCard>
		</AnimatedBox>
	);
};

export default OrfConsent;
