/* eslint-disable @typescript-eslint/no-unsafe-assignment,
  @typescript-eslint/no-unsafe-member-access,
  @typescript-eslint/restrict-template-expressions
 */
import React from "react";
import { BoxProps, Flex } from "@chakra-ui/react";
import theme from "../styles/theme";

const Card: React.FC<BoxProps> = ({
	children = "",
	...restProps
}: BoxProps) => (
	<Flex
		backgroundColor={theme.colors.brand.white}
		border={`1px solid ${theme.colors.brand.primary}`}
		borderRadius="10px"
		{...restProps}
	>
		{children}
	</Flex>
);

export default Card;
