import React from "react";
import { Box, ListItem, UnorderedList } from "@chakra-ui/layout";

import SubHeader from "../../components/SubHeader";
import Textarea from "../../components/Textarea";
import { PdfTextarea } from "../../components/PdfTextarea";
import { OrfQuestion } from "../../components/OrfQuestion";
import HeaderText from "../../components/HeaderText";

interface PhysicalEvidenceProps {
	physicalInfo?: string;
	pdf?: boolean;
	textWidth?: string;
}

const PhysicalEvidence: React.FC<PhysicalEvidenceProps> = ({
	physicalInfo = undefined,
	pdf = false,
	textWidth,
}: PhysicalEvidenceProps) => (
	<Box width={textWidth}>
		{pdf && (
			<HeaderText fontSize="md" pdf={pdf}>
				Physical Evidence
			</HeaderText>
		)}
		<SubHeader
			mb="0px"
			style={{ fontWeight: "bold", lineHeight: 1, letterSpacing: "0.05px" }}
		>
			Physical Evidence Includes:
		</SubHeader>
		<UnorderedList mt={pdf ? "5px" : ""}>
			<ListItem fontSize="sm">Clothing</ListItem>
			<ListItem fontSize="sm">Bedsheets</ListItem>
			<ListItem fontSize="sm">Condoms</ListItem>
			<ListItem fontSize="sm">
				Any evidence collected as part of a forensic exam (“rape kit”)
			</ListItem>
		</UnorderedList>
		<SubHeader
			mb="0px"
			style={{ fontWeight: "bold", lineHeight: 1, letterSpacing: "0.05px" }}
		>
			Preserving Physical Evidence:
		</SubHeader>
		<UnorderedList mt={pdf ? "5px" : ""}>
			<ListItem fontSize="sm">
				Place each item of potential physical evidence in its own paper bag
				(plastic bags can eliminate DNA) and store someplace only you can access
				(i.e. out of common spaces, away from roommates){" "}
			</ListItem>
		</UnorderedList>
		<SubHeader pdf={pdf}>
			<OrfQuestion pdf={pdf}>
				What types of physical evidence do you have now (or think might exist)?
			</OrfQuestion>
		</SubHeader>
		{pdf && !physicalInfo && <Textarea rows={7} height="auto" />}
		{pdf && physicalInfo && <PdfTextarea>{physicalInfo}</PdfTextarea>}
	</Box>
);

export default PhysicalEvidence;
