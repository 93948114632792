import { useEffect, useRef } from "react";
import { IContactInfoForm } from "../../contexts/EntryContext/stepContact";

export const usePersistContactInfo = (
	onFormUnmounting: (props: IContactInfoForm) => void,
): ((values: IContactInfoForm) => void) => {
	const getValuesRef = useRef<IContactInfoForm | null>(null);

	useEffect(
		() => () => {
			const values = getValuesRef?.current;
			if (values) {
				onFormUnmounting(values);
			}
		},
		[],
	);

	return (values: IContactInfoForm) => {
		getValuesRef.current = values;
	};
};
