import React, { useContext } from "react";
import { IncidentLogContext } from "../../contexts/IncidentLogContext";
import CreateEditHeader from "./header/CreateEditHeader";
import CreateEditSidebar from "./sidebar/CreateEditSidebar";
import { Box, useDisclosure } from "@chakra-ui/react";
import { PrivateRoutes } from "../../config/routes";
import SubmitCancelReturnModal from "../SubmitCancelReturnModal";
import { survivorClient } from "../../service/backend";
import { Outlet, useNavigate } from "react-router-dom";

const CreateIncidentLogLayout: React.FC = () => {
	const {
		state: { steps, mode, changesMade },
	} = useContext(IncidentLogContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();

	const handleBackClick = () => {
		if (changesMade) {
			onOpen();
		} else {
			navigate(PrivateRoutes.ILOG);
		}
	};

	const handleCancelClick = () => {
		survivorClient
			.submitEvent("Discard changes in Incident Log", {})
			.finally(() => {
				onClose();
				navigate(PrivateRoutes.ILOG);
			});
	};

	return (
		<>
			<CreateEditHeader handleBackClick={handleBackClick} />
			<CreateEditSidebar
				steps={steps}
				title={mode === "create" ? "Create Incident Log" : "Edit Incident Log"}
				type={"incidentLog"}
				handleBackClick={handleBackClick}
			/>
			<Box
				width={["100%", "100%", "calc(100% - 300px)"]}
				marginLeft={["0px", "0px", "300px"]}
				minHeight="100%"
				backgroundColor="brand.appBackground"
				padding={{ base: "200px 0px 20px 0px", md: "100px 20px 20px 20px" }}
				as="main"
				display="flex"
				flexDir="column"
				alignItems="center"
				position="relative"
				backgroundRepeat="none"
				backgroundSize="cover"
			>
				<Box maxWidth="900px" width="100%">
					<Outlet />
				</Box>
			</Box>

			<SubmitCancelReturnModal
				submitText="Save and exit"
				formId="incident_log_form"
				cancelText="Discard all changes"
				headerText="You have unsaved changes. Would you like to return to the dashboard without saving?"
				isOpen={isOpen}
				onClose={onClose}
				onCancel={handleCancelClick}
				isConfirming={false}
				returnText="Return to Log"
				onReturn={onClose}
			/>
		</>
	);
};

export default CreateIncidentLogLayout;
