import { Box, useDisclosure } from "@chakra-ui/react";
import React, { useContext } from "react";
import { PrivateRoutes } from "../../config/routes";
import { OrfContext } from "../../contexts/OrfContext";
import CreateEditHeader from "./header/CreateEditHeader";
import CreateEditSidebar from "./sidebar/CreateEditSidebar";
import { survivorClient } from "../../service/backend";
import SubmitCancelReturnModal from "../SubmitCancelReturnModal";
import OrfBanner from "../OrfBanner";
import { Outlet, useNavigate } from "react-router-dom";

const OrfEditLayout: React.FC = () => {
	const {
		state: { steps, mode, changesMade },
		dispatch,
	} = useContext(OrfContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();

	const handleBackClick = () => {
		if (changesMade) {
			onOpen();
		} else {
			navigate(PrivateRoutes.ILOG);
		}
	};

	const handleCancelClick = () => {
		const completedSteps = survivorClient.extractCompletedSteps(steps);
		survivorClient
			.submitEvent("Filled ORF Data", {
				submitted: false,
				completedSteps,
			})
			.finally(() => {
				dispatch({
					type: "SET_DISCARDING",
					payload: { isDiscarding: true },
				});
				onClose();
				navigate(PrivateRoutes.ILOG);
			});
	};

	return (
		<>
			<CreateEditHeader handleBackClick={handleBackClick} />
			<CreateEditSidebar
				steps={steps}
				title="Edit a Legacy Incident Log"
				handleBackClick={handleBackClick}
				type="orf"
			/>
			<Box
				width={["100%", "100%", "calc(100% - 300px)"]}
				marginLeft={["0px", "0px", "300px"]}
				minHeight="100%"
				backgroundColor="brand.appBackground"
				padding={{ base: "200px 0px 20px 0px", md: "100px 20px 20px 20px" }}
				as="main"
				display="flex"
				flexDir="column"
				alignItems="center"
				position="relative"
				backgroundRepeat="none"
				backgroundSize="cover"
			>
				{location.pathname !== PrivateRoutes.ORF_INTRO && <OrfBanner />}
				<Box maxWidth="900px" width="100%">
					<Outlet />
				</Box>
			</Box>

			<SubmitCancelReturnModal
				submitText="Save and exit"
				formId="orf_form"
				cancelText={mode === "edit" ? "Discard all changes" : "Discard Form"}
				headerText="You have unsaved changes. Would you like to return to the dashboard without saving?"
				isOpen={isOpen}
				onClose={onClose}
				onCancel={handleCancelClick}
				isConfirming={false}
				returnText="Return to form"
				onReturn={onClose}
			/>
		</>
	);
};

export default OrfEditLayout;
