import { QResponse, resultType } from "../../../../../../lib/data";
import {
	FieldData,
	getResultsNode,
	replaceArrayValue,
	resultsDeepCopy,
	updateText,
} from "../../../../../../lib/questionnaire/formFunctions";
import { ChangeEvent } from "react";

export const updateRadio = (
	key: string,
	path: string[],
	results: Map<string, resultType>,
	e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	useOptionValue: boolean = false,
	setValue?: string,
): Map<string, resultType> => {
	const newResults = new Map<string, resultType>(results);
	const existing = newResults.get(path[0]);
	if (!existing) {
		return new Map<string, resultType>();
	}

	const label =
		e.currentTarget.labels?.item(0).innerText ?? path[path.length - 1];
	const value = setValue ?? e.currentTarget.value;

	const previous = getResultsNode(existing, path.slice(0, path.length - 1));

	if (useOptionValue) {
		(previous as QResponse).optionValue = {
			key,
			type: "option",
			label,
			value,
		};
	} else {
		(previous as QResponse).value = {
			key,
			type: "option",
			label,
			value,
		};
	}

	return newResults;
};

export const updateMultiselect = (
	path: string[],
	results: Map<string, resultType>,
	e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	useOptionValue: boolean = false,
	exclusiveKeys: string[] = [],
): Map<string, resultType> => {
	const newResults = resultsDeepCopy(results);
	const existing = newResults.get(path[0]);
	if (!existing) {
		return new Map<string, resultType>();
	}

	const current = getResultsNode(existing, path);
	const label =
		e.currentTarget.labels?.item(0).innerText ?? path[path.length - 1];
	let value = e.currentTarget.value;
	if (value === undefined || value === null) {
		value = path[path.length - 1];
	}
	let newList: QResponse[];
	if (exclusiveKeys.includes(path[path.length - 1])) {
		newList = [];
	} else if (Array.isArray(current)) {
		newList = current.filter(
			(resp) => resp.label !== label && !exclusiveKeys.includes(resp.key),
		);
	} else if (Array.isArray(current.value)) {
		newList = current.value.filter(
			(resp) => resp.label !== label && !exclusiveKeys.includes(resp.key),
		);
	} else if (Array.isArray(current.optionValue)) {
		newList = current.optionValue.filter(
			(resp) => resp.label !== label && !exclusiveKeys.includes(resp.key),
		);
	} else {
		newList = [];
	}

	if ((e.currentTarget as HTMLInputElement).checked) {
		newList.push({
			key: path[path.length - 1],
			type: "option",
			label,
			value,
		});

		replaceArrayValue(newResults, path, newList, useOptionValue);
	} else {
		const previous = getResultsNode(existing, path.slice(0, path.length - 1));
		if (Array.isArray(previous)) {
			const filtered = previous.filter((resp) => resp.label !== label);
			if (previous === existing) {
				newResults.set(path[0], filtered);
			} else {
				const preceding = getResultsNode(
					existing,
					path.slice(0, path.length - 2),
				);
				if (!Array.isArray(preceding)) {
					if (useOptionValue) {
						preceding.optionValue = filtered;
					} else {
						preceding.value = filtered;
					}
				}
			}
		} else {
			if (useOptionValue) {
				previous.optionValue = newList;
			} else {
				previous.value = newList;
			}
		}
	}

	return newResults;
};

export const updateTextValue = (
	data: FieldData,
	path: string[],
	e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	results: Map<string, resultType>,
	setResults: (newResults: Map<string, resultType>) => void,
) => {
	const updatedResults = updateText(
		data.key,
		data.label,
		e.currentTarget.value,
		path,
		results,
	);
	setResults(updatedResults);
};

export const updateRadioValue = (
	key: string,
	path: string[],
	e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	results: Map<string, resultType>,
	setResults: (newResults: Map<string, resultType>) => void,
	useOptionValue = false,
	setValue?: string,
) => {
	setResults(updateRadio(key, path, results, e, useOptionValue, setValue));
};

export const updateMultiselectValue = (
	path: string[],
	e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	results: Map<string, resultType>,
	setResults: (newResults: Map<string, resultType>) => void,
	useOptionValue = false,
	exclusiveKeys: string[] = [],
) => {
	setResults(
		updateMultiselect(path, results, e, useOptionValue, exclusiveKeys),
	);
};
