import "regenerator-runtime/runtime";

import React, { createContext, ReactElement } from "react";
import { createRoot } from "react-dom/client";
import sodium from "libsodium-wrappers-sumo";
import { LoginOut } from "../../../../lib/init/client";

export const AuthClientContext = createContext<null | LoginOut>(null);

// Boostrap the logged-in environment if we can.
const AUTH_STORAGE_KEY = "__callisto_auth";

export const renderShell = async (
	app: ReactElement,
	client: LoginOut,
): Promise<void> => {
	await sodium.ready;
	let resolve: any = null;
	const waitForCreds = new Promise((r) => {
		resolve = r;
	});

	const o = window.sessionStorage.getItem(AUTH_STORAGE_KEY);
	if (o !== null) {
		// TODO: catch exceptions here and notify the user that something went
		// wrong so they can attempt to reload.
		const e = Buffer.from(o, "base64").toString("utf-8");
		client.deserialize(e);
		await client.bootstrap();
		resolve();

		window.sessionStorage.removeItem(AUTH_STORAGE_KEY);
	} else {
		// Try to get from the window that opened us.
		const opener = window.opener;
		if (opener !== null) {
			const port = new window.MessageChannel();
			port.port1.onmessage = (ev) => {
				client.deserialize(ev.data);
				client.bootstrap().then(() => {
					resolve();
				});
			};
			opener.postMessage("CREDS", "*", [port.port2]);
		} else {
			resolve();
		}
	}

	window.addEventListener("beforeunload", () => {
		if (client.loggedIn) {
			const o = client.serialize();
			const e = sodium.to_base64(o);
			window.sessionStorage.setItem(AUTH_STORAGE_KEY, e);
		}
	});

	window.addEventListener("message", function (ev) {
		if (ev.origin !== window.origin) {
			return;
		}

		if (ev.data === "CREDS") {
			ev.ports[0].postMessage(client.serialize());
		}
	});

	// Wait until either some credential handler is executed, or we find nothing,
	// until we render the Preact application.
	await waitForCreds;
	const container = document.querySelector("#root");
	const root = createRoot(container!);
	root.render(<>{app}</>);
};
